import { UPDATE_MANY } from 'react-admin';

export const CLIENT_CONFIRM = 'CLIENT_CONFIRM';

export const clientConfirm = (ids, isSendSms, basePath) => ({
    type: CLIENT_CONFIRM,
    payload: { ids, data: { isSendSms } },
    meta: {
        resource: 'clients/confirm',
        fetch: UPDATE_MANY,
        onSuccess: {
            notification: {
                body: 'resources.clients.notification.confirm_success',
                level: 'info',
            },
            redirectTo: '/clients',
            basePath,
            refresh: true,
        },
        onFailure: {
            notification: {
                body: 'resources.clients.notification.confirm_error',
                level: 'warning',
            },
        },
    },
});
