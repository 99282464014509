import React, { Component, Fragment } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

export default class ObjectClients extends Component {
  render() {
    return (
      <Fragment>
        <DialogTitle>Клиенты</DialogTitle>
        <DialogContent>Нет данных</DialogContent>
      </Fragment>
    );
  }
}
