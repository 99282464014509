import React, { Component, Fragment } from "react";

import {
  DateField,
  TextField,
  List,
  FunctionField,
  Pagination,
  Link,
  ReferenceField,
  EditButton,
  BulkDeleteButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  Filter
} from "react-admin";

import withStyles from "@material-ui/core/styles/withStyles";

import { push } from "react-router-redux";
import { Route } from "react-router";
import { connect } from "react-redux";
import { parse, stringify } from "query-string";

import GroupDatagrid from "../components/ra-customizable-datagrid/GroupDatagrid";
import FullNameField from "../components/field/FullNameField";
import UserQuickPreview from "../users/UserQuickPreview";
import Checklist from "../components/list/Checklist";

import ChipInput from "../components/input/ChipInput";

import { getNumberWithLabel } from "../utils/formatter";
import { find } from "lodash";
import { sections } from "../consts";

const filterStyles = {
};

const renderFrom = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      from: record.id
    })}`;
    return (
      <Link
        to={`/tasks-templates${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderDeadline = record => {
  let deadline = '';

  if (record.deadline_d) {
    deadline += `${getNumberWithLabel(record.deadline_d, 'день', 'дня', 'дней')} `;
  }

  if (record.deadline_h) {
    deadline += `${getNumberWithLabel(record.deadline_h, 'час', 'часа', 'часов')} `;
  }

  if (record.deadline_m) {
    deadline += `${getNumberWithLabel(record.deadline_m, 'минута', 'минут', 'минут')} `;
  }

  return deadline;
}

const renderTo = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      to: record.id
    })}`;
    return (
      <Link
        to={`/tasks${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderSection = record => {
  let name = record.section;

  const section = find(sections, {
    id: name
  });
  if (section) {
    name = section.name;
  }

  return name;
}

export const TaskFilter = withStyles(filterStyles)(
  ({ classes, ...props }) => {
    return (
      <Filter {...props}>
        <ReferenceArrayInput
          source="cat_ids"
          label="resources.objects.input.categories"
          reference="categories"
          allowEmpty={false}
        >
          <ChipInput>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceArrayInput>
        <ChipInput
          source="section"
          label="resources.tasks.input.section"
          isTranslated={false}
        >
          <SelectInput
            choices={sections}
          />
        </ChipInput>
      </Filter>);
  }
);

const TaskPanel = ({ id, record, resource }) => {
  return (
    <Checklist
      record={record}
      label="resources.tasks.input.checklist"
      source="checklist"
      isRefresh
    />
  );
};

const TasksTable = props => (
  <GroupDatagrid expand={<TaskPanel />} {...props} rowClick="show" showGroup={true}>
    <TextField source="title" />
    <FunctionField source="section" render={renderSection} />
    <ReferenceField source="to_id" reference="users" linkType={false} allowEmpty>
      <FunctionField source="to_id" render={renderTo(props.location)} />
    </ReferenceField>
    <ReferenceField source="from_id" reference="users" linkType={false} allowEmpty>
      <FunctionField source="from_id" render={renderFrom(props.location)} />
    </ReferenceField>
    <DateField source="createdAt" />
    <FunctionField source="deadline" render={renderDeadline} />
    <EditButton label="" source="edit" />
  </GroupDatagrid>
);

const TasksPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10]} {...props} />
);

const TasksBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

class TasksTemplatesList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.from;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };

  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          filters={<TaskFilter />}
          pagination={<TasksPagination />}
          perPage={5}
          sort={{ field: "status", order: "ASC" }}
          bulkActionButtons={<TasksBulkActionButtons />}
        >
          <TasksTable location={props.location} />
        </List>
        <Route path="/tasks">
          {route => {
            const values = parse(route.location.search);
            let id = null;

            if(values.to){
              id = values.to;
            }else if(values.from){
              id = values.from;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(TasksTemplatesList);
