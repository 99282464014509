import grey from '@material-ui/core/colors/grey';
import moment from "moment";

const rowStyle = (record, index, defaultStyle = {}) => {
  let status = null;
  if (record.deletedAt) {
    let theDate = moment(record.deletedAt);
    const curDate = moment();

    // помечаем архивными, делаем сначала зачеркнутыми, с возможностью вернуть (пользователям со статусом агент; новичок)
    status = 1;

    if (curDate.diff(theDate, "weeks", true) >= 2) {
      // через 2 недели делаем серыми(восстанавливают только админы) висит еще 4 недели, потом удаляются с фронта.
      status = 2;
    }
  }

  if (status === 1) {
    return {
      ...defaultStyle,
      textDecoration: 'line-through',
    };
  }
  if (status === 2) {
    return {
      ...defaultStyle,
      backgroundColor: grey[100],
      borderLeftColor: grey[500],
      borderLeftStyle: 'solid',
      borderLeftWidth: 5,
      textDecoration: 'line-through',
    };
  }
  return defaultStyle;
};

export default rowStyle;