import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { compose } from "redux";
import { connect } from "react-redux";

import { contentBarOpen } from "./actions/contentBarActions";   
import { bpStart } from "./actions/bpActions";
import BpBar from "./components/bp/BpBar";
import Sticky from "./components/Sticky";
import { socket } from "./consts";

const styles = theme => ({
  content: {
    display: "flex",
    padding: 4,
    [theme.breakpoints.down("sm")]: {
      padding: 0
    },
    paddingTop: 72,
    position: "relative"
  },
  cardRoot: {
    width: "100%"
  },
  cardContent: {
    padding: 8
  }
});

class ContentBarBp extends Component {
  state = {
    play: false
  }; 

  link = null;
  icons = [
    "phone/favicon.ico",
    "favicon.ico"
  ];

  counter = 0;

  interval = null;

  constructor (props) {
    super(props)

    this.link = document.querySelector("link[rel~='icon']");
    if (!this.link) {
      this.link = document.createElement('link');
      this.link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(this.link);
    }
  }

  handlePbxEvent = event => data => {
    const { contentBarOpen, bpStart, contentBar, name } = this.props;
    if (event === "pbx_dialing" || event === "pbx_talk") {
      if (!contentBar[name]) {
        if (event === "pbx_dialing") {
          this.interval = setInterval(() => {
            if (this.counter === 0) {
              this.counter = 1;
            } else {
              this.counter = 0;
            }

            this.link.href = this.icons[this.counter];

          }, 100);
        }
        
        bpStart({
          ...data,
          event
        });
        contentBarOpen("bp");
      }

      if (event === "pbx_dialing" ) {
        this.setState({
          play: true
        });
      }

      if (event === "pbx_talk") {
        if (this.interval) {
          clearInterval(this.interval);
          this.link.href = this.icons[0];
        }

        this.setState({
          play: false
        });
      }
    } else {
      this.link.href = this.icons[1];
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  };

  componentWillMount() {
    socket.instance.on("pbx_dialing", this.handlePbxEvent("pbx_dialing"));
    socket.instance.on("pbx_talk", this.handlePbxEvent("pbx_talk"));
    socket.instance.on("pbx_end", this.handlePbxEvent("pbx_end"));
  }
  
  componentWillUnmount() {
    socket.instance.off("pbx_dialing", data => this.handlePbxEvent("pbx_dialing", data));
    socket.instance.off("pbx_talk", data => this.handlePbxEvent("pbx_talk", data));
    socket.instance.off("pbx_end", data => this.handlePbxEvent("pbx_end", data));
    this.link.href = this.icons[1];
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { classes, name, contentBar, style } = this.props;
    const isShow = contentBar[name];

    if (!isShow) {
      this.link.href = this.icons[1];
      if (this.interval) {
        clearInterval(this.interval);
      }
    }

    return isShow ? (
      <div className={classes.content} style={style}>
        {this.state.play && <audio src="sound/mobile-rington.mp3" controls={false} autoPlay />}
        <Card className={classes.cardRoot}>
          <CardContent className={classes.cardContent}>
            <Sticky>
              <BpBar />
            </Sticky>
          </CardContent>
        </Card>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, props) => ({
  contentBar: state.contentBar
});

export default compose(
  connect(
    mapStateToProps,
    { bpStart, contentBarOpen }
  ),
  withStyles(styles)
)(ContentBarBp);
