import React from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  translate,
  CardActions,
  ListButton
} from "react-admin";

import PhoneInput from "../../components/input/PhoneInput";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

const validate = translate => values => {
  const errors = {};
  if (!values.phonefrom) {
    errors.phonefrom = [
      translate("validation.required", {
        name: translate("resources.pbx/bl.input.phonefrom")
      })
    ];
  }

  if (!values.phoneto) {
    errors.phoneto = [
      translate("validation.required", {
        name: translate("resources.pbx/bl.input.phoneto")
      })
    ];
  }

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  }
});

const PBXBlActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const PBXBlCreate = ({ classes, ...props }) => {
  const { translate } = props;
  return (
    <Create
      {...props}
      classes={{ card: classes.card }}
      actions={<PBXBlActions />}
    >
      <SimpleForm validate={validate(translate)}>
        <PhoneInput
          source="phonefrom"
          label="resources.pbx/bl.input.phonefrom"
          placeholder="79998887766"
        />
        <PhoneInput
          source="phoneto"
          label="resources.pbx/bl.input.phoneto"
          placeholder="79998887766"
        />
        <SelectInput
          source="phonetype"
          label="resources.pbx/bl.input.phonetype"
          resettable
          choices={[
            { id: "0", value: "0", name: "Обычный телефон" },
            { id: "1", value: "1", name: "Черный список" },
            { id: "2", value: "2", name: "Партнер" }
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default compose(
  withStyles(styles),
  translate
)(PBXBlCreate);
