import React, { Fragment } from "react";
import {
  Edit,
  FormTab,
  TabbedForm,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SelectArrayInput,
  NumberInput,
  TextInput,
  ImageInput,
  ImageField,
  translate,
  ListButton,
  CardActions,
  CreateButton,
  FormDataConsumer
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import PhoneInput from "../components/input/PhoneInput";
import ObjectReferenceInput from "./ObjectReferenceInput";
import DynamicGroupInput from "../components/input/DynamicGroupInput";
import ObjectPhotos from "./ObjectPhotos";
import YandexMap from "../components/map/YandexMap";
import get from "lodash/get";
import ObjectStatuses from "./ObjectStatuses";

const validate = translate => (values, allValues) => {
  const errors = {};
  let status = allValues.record && ObjectStatuses(allValues.record);
  if (!values.street) {
    errors.street = [
      translate("validation.required", {
        name: translate("resources.objects.input.q")
      })
    ];
  }
  if (!values.house) {
    errors.house = [
      translate("validation.required", {
        name: translate("resources.objects.input.house")
      })
    ];
  }
  if (!values.flat) {
    errors.flat = [
      translate("validation.required", {
        name: translate("resources.objects.input.flat")
      })
    ];
  }
  if (!values.region) {
    errors.region = [
      translate("validation.required", {
        name: translate("resources.objects.input.regions")
      })
    ];
  }
  if (!values.category) {
    errors.category = [
      translate("validation.required", {
        name: translate("resources.objects.input.categories")
      })
    ];
  }
  if (!values.type_house) {
    errors.type_house = [
      translate("validation.required", {
        name: translate("resources.objects.input.type_house")
      })
    ];
  }
  if (!values.series) {
    errors.series = [
      translate("validation.required", {
        name: translate("resources.objects.input.series")
      })
    ];
  }
  if (!values.floor) {
    errors.floor = [
      translate("validation.required", {
        name: translate("resources.objects.input.floor")
      })
    ];
  }
  if (!values.floor_house) {
    errors.floor_house = [
      translate("validation.required", {
        name: translate("resources.objects.input.floor_house")
      })
    ];
  }
  if (!values.area) {
    errors.area = [
      translate("validation.required", {
        name: translate("resources.objects.input.area")
      })
    ];
  }
  if (!values.cost_of_owner) {
    errors.cost_of_owner = [
      translate("validation.required", {
        name: translate("resources.objects.input.cost_of_owner")
      })
    ];
  }
  if (!values.contact_info) {
    errors.contact_info = [
      translate("validation.required", {
        name: translate("resources.objects.input.contact_info")
      })
    ];
  }

  if (
    6 === status &&
    allValues.record &&
    values.description_cmi_posting === allValues.record.description_cmi_posting
  ) {
    errors.description_cmi_posting = [
      translate("validation.required", {
        name: translate("resources.objects.tabs.label.cmi_posting")
      })
    ];
  }

  if (
    6 === status &&
    allValues.record &&
    values.cost_of_cmi === allValues.record.cost_of_cmi
  ) {
    errors.cost_of_cmi = [
      translate("validation.required", {
        name: translate("resources.objects.input.cost_of_cmi")
      })
    ];
  }

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  tabSelected: {}
});

const ObjectActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <ListButton basePath={basePath} />
  </CardActions>
);

const ObjectTitle = ({ record }) => {
  let imgSrc = null;
  if (record.images) {
    imgSrc = get(record.images, "main.thumb.src");
  }
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      {imgSrc && <Avatar src={imgSrc} />}&nbsp;
      {record.street ? record.street.name : ""}{" "}
      {record.house ? `д ${record.house}` : ""}{" "}
      {record.flat ? `кв ${record.flat}` : ""}{" "}
      {record.floor ? `п ${record.floor}` : ""}
    </div>
  );
};

const optionRenderer = choice => `${choice.name !== null ? choice.name : ''} ${choice.last_name !== null ? choice.last_name : ''}`;

const ObjectEdit = ({ classes, ...props }) => {
  const { translate } = props;
  return (
    <Edit
      {...props}
      classes={{ card: classes.card }}
      title={<ObjectTitle />}
      actions={<ObjectActions />}
    >
      <TabbedForm
        scrollable={true}
        scrollButtons="auto"
        validate={validate(translate)}
      >
        <FormTab
          label="resources.objects.tabs.address"
          tabClasses={{ root: classes.tabRoot }}
        >
          <ReferenceInput
            label="resources.objects.input.categoriesr"
            source="category_id"
            reference="categories"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { category_id } = formData;
              const isApartment =
                category_id === 1 || category_id === 2 || category_id === 4;
              return (
                <Fragment>
                  <div>
                    <ObjectReferenceInput source="street" />
                  </div>
                  <div>
                    <YandexMap source="coords" />
                  </div>
                  <div>
                    <TextInput
                      label="resources.objects.input.houser"
                      source="house"
                      {...rest}
                    />
                  </div>
                  {isApartment && (
                    <div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.entrancer"
                          source="entrance"
                          {...rest}
                        />
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.flatr"
                          source="flat"
                          {...rest}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <ReferenceInput
                      label="resources.objects.input.regionsr"
                      source="region_id"
                      reference="regions"
                      {...rest}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </div>
                  <div>
                    <ReferenceInput
                      allowEmpty
                      label="resources.objects.input.districts"
                      source="district_id"
                      reference="districts"
                      {...rest}
                    >
                      <AutocompleteInput optionText="name" allowEmpty />
                    </ReferenceInput>
                  </div>
                </Fragment>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label="resources.objects.tabs.property_info"
          path="property_info"
          tabClasses={{ root: classes.tabRoot }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { category_id, id, imagesPosting } = formData;
              const isApartment =
                category_id === 1 || category_id === 2 || category_id === 4;
              return (
                <div>
                  <div>
                    <ObjectPhotos source="images" />
                  </div>
                  <div style={{color: 'red', marginTop: '20px'}}>
                    <div>Создайте папку {id}, а в ней подпапки 1, 2, 3, 4,... (с фотографиями объекта) по количеству месяцев переразмещения объявления.</div>
                    {imagesPosting &&
                    imagesPosting.length > 0 &&
                    imagesPosting.map((img, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundImage: `url('${img}')`,
                            width: 180,
                            height: 110,
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            borderRadius: '7px',
                            backgroundRepeat: 'no-repeat',
                            display: 'inline-block',
                            marginRight: '3px',
                            backgroundColor: 'grey'
                          }}
                        ></div>
                      )
                    })}
                  </div>
                  <div>
                    <SelectInput
                      optionText="name"
                      label="resources.objects.input.transaction_type"
                      source="transaction_type"
                      {...rest}
                      choices={[
                        { id: "1", name: "Продажа" },
                        { id: "2", name: "Аренда" }
                      ]}
                    />
                  </div>
                  <div>
                    <ReferenceInput
                      label="resources.objects.input.proptypes"
                      source="property_type_id"
                      reference="proptypes"
                      filter={{ cat_ids: category_id }}
                      {...rest}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </div>
                  {isApartment && (
                    <div>
                      <div>
                        <ReferenceInput
                          label="resources.objects.input.type_houser"
                          source="type_house_id"
                          reference="typehouse"
                          {...rest}
                        >
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                      </div>
                      {category_id === 1 && (
                        <div>
                          <ReferenceInput
                            label="resources.objects.input.developer"
                            source="developer_id"
                            reference="developers"
                            {...rest}
                          >
                            <SelectInput optionText="name" />
                          </ReferenceInput>
                        </div>
                      )}
                      <div>
                        <ReferenceInput
                          label="resources.objects.input.seriesr"
                          source="series_id"
                          reference="series"
                          {...rest}
                        >
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.floorr"
                          source="floor"
                          {...rest}
                        />
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.floor_houser"
                          source="floor_house"
                          {...rest}
                        />
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.completed_year"
                          source="completed_year"
                          {...rest}
                        />
                      </div>
                      <div>
                        <ReferenceInput
                          label="resources.objects.input.conditions"
                          source="state_id"
                          reference="conditions"
                          {...rest}
                        >
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label="resources.objects.tabs.area"
          path="area"
          tabClasses={{ root: classes.tabRoot }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { category_id } = formData;
              const isApartment =
                category_id === 1 || category_id === 2 || category_id === 4;
              const isCountryside = category_id === 5;
              return (
                <div>
                  <div>
                    <NumberInput
                      label="resources.objects.input.arear"
                      source="area"
                      {...rest}
                    />
                  </div>
                  <div>
                    <NumberInput
                      label="resources.objects.input.squared_area"
                      source="squared_area"
                      {...rest}
                    />
                  </div>
                  <div>
                    <NumberInput
                      label="resources.objects.input.area_commercial"
                      source="area_commercial"
                      {...rest}
                    />
                  </div>
                  {isApartment && (
                    <div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.area_without_balcony"
                          source="area_without_balcony"
                          {...rest}
                        />
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.area_with_balcony"
                          source="area_with_balcony"
                          {...rest}
                        />
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.balcony_coef"
                          source="balcony_coef"
                          {...rest}
                        />
                      </div>
                    </div>
                  )}
                  {isCountryside && (
                    <div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.house_area"
                          source="house_area"
                          {...rest}
                        />
                      </div>
                      <div>
                        <NumberInput
                          label="resources.objects.input.land_area"
                          source="land_area"
                          {...rest}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label="resources.objects.tabs.prices"
          path="prices"
          tabClasses={{ root: classes.tabRoot }}
        >
          <NumberInput
            label="resources.objects.input.cost_of_ownerr"
            source="cost_of_owner"
          />
          <NumberInput
            label="resources.objects.input.cost_of_in"
            source="cost_of_in"
          />
          <NumberInput
            label="resources.objects.input.cost_of_out"
            source="cost_of_out"
          />
          <NumberInput
            label="resources.objects.input.cost_of_recommended"
            source="cost_of_recommended"
          />
          <NumberInput
            label="resources.objects.input.cost_of_cmi"
            source="cost_of_cmi"
          />
        </FormTab>
        <FormTab
          label="resources.objects.tabs.contacts"
          path="contacts"
          tabClasses={{ root: classes.tabRoot }}
        >
          <TextInput
            label="resources.objects.input.contact_people"
            source="contact_people"
          />
          <DynamicGroupInput source="contact_info">
            <div>
              <SelectInput
                optionText="name"
                label="resources.objects.input.type_contact_phone"
                source="type_contact_phone"
                choices={[
                  { id: "1", name: "Домашний" },
                  { id: "2", name: "Рабочий" },
                  { id: "3", name: "Другое" }
                ]}
              />
              <PhoneInput
                source="contact_info"
                label="resources.objects.input.contact_infor"
                style={{marginLeft: 8}}
              />
            </div>
            <div>
              <SelectInput
                optionText="name"
                label="resources.objects.input.type_contact_phone"
                source="type_contact_phone_2"
                choices={[
                  { id: "1", name: "Домашний" },
                  { id: "2", name: "Рабочий" },
                  { id: "3", name: "Другое" }
                ]}
              />
              <PhoneInput
                source="contact_info_2"
                label="resources.objects.input.contact_info"
                style={{marginLeft: 8}}
              />
            </div>
            <div>
              <SelectInput
                optionText="name"
                label="resources.objects.input.type_contact_phone"
                source="type_contact_phone_3"
                choices={[
                  { id: "1", name: "Домашний" },
                  { id: "2", name: "Рабочий" },
                  { id: "3", name: "Другое" }
                ]}
              />
              <PhoneInput
                source="contact_info_3"
                label="resources.objects.input.contact_info"
                style={{marginLeft: 8}}
              />
            </div>
          </DynamicGroupInput>
          <ReferenceInput
            label="resources.objects.input.manager"
            source="user_id"
            reference="users"
          >
            <AutocompleteInput optionText={optionRenderer} />
          </ReferenceInput>
        </FormTab>
        <FormTab
          label="resources.objects.tabs.analysis"
          path="analysis"
          tabClasses={{ root: classes.tabRoot }}
        >
          <SelectInput
            optionText="name"
            label="resources.objects.input.status_dog"
            source="status_dog"
            choices={[
              { id: "1", name: "Договорной" },
              { id: "2", name: "Венчурный" }
            ]}
          />
          <ReferenceInput
            label="resources.objects.input.sales_status"
            source="sales_status_id"
            reference="sales_status"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            label="resources.objects.input.targets"
            source="target_id"
            reference="targets"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            optionText="name"
            label="resources.objects.input.using"
            source="using"
            choices={[
              { id: 1, name: "Мат. капитал" },
              { id: 2, name: "Ипотека" },
              { id: 3, name: "Кредит" }
            ]}
          />
          <NumberInput
            label="resources.objects.input.years_old"
            source="legal_info.years_old"
          />
          <NumberInput
            label="resources.objects.input.year"
            source="legal_info.year"
          />
          <ReferenceInput
            label="resources.objects.input.additional"
            source="legal_info.additional"
            reference="additionals"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            optionText="name"
            label="resources.objects.input.redevelopment"
            source="legal_info.redevelopment"
            choices={[{ id: "1", name: "Да" }, { id: "2", name: "Нет" }]}
          />
          <NumberInput
            label="resources.objects.input.owners"
            source="legal_info.owners"
          />
          <NumberInput
            label="resources.objects.input.prescription"
            source="legal_info.prescription"
          />
          <SelectInput
            optionText="name"
            label="resources.objects.input.mortgage"
            source="legal_info.mortgage"
            choices={[{ id: "1", name: "Да" }, { id: "2", name: "Нет" }]}
          />
          <SelectInput
            optionText="name"
            label="resources.objects.input.arrest"
            source="legal_info.arrest"
            choices={[{ id: "1", name: "Да" }, { id: "2", name: "Нет" }]}
          />
          <ImageInput
            multiple
            source="legal_info.documents"
            label="resources.objects.input.files"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab
          label="resources.objects.tabs.cmi"
          path="cmi"
          tabClasses={{ root: classes.tabRoot }}
        >
          <RichTextInput
            source="description_cmi"
            label="resources.objects.tabs.label.cmi"
          />
          <TextInput
            multiline
            style={{width: "100%", marginTop: "10px"}}
            source="description_cmi_posting"
            label="resources.objects.tabs.label.cmi_posting"
          />
          <ReferenceArrayInput
            label="resources.objects.input.cmi"
            source="cmi_id"
            reference="cmi"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(ObjectEdit);
