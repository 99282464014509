import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, CardActions, CardContent, Grid } from "@material-ui/core";

import { contentBarClose } from "../../actions/contentBarActions";
import { bpEnd } from "../../actions/bpActions";
import SaleScriptForm from "./SaleScriptForm";
import CustomerCard from "./CustomerCard";
import ObjectCard from "./ObjectCard";
import AgentCard from "./AgentCard";
import OwnerCard from "./OwnerCard";

import Call from "../ipphone/Call";

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  cardContent: {
    padding: "8px"
  },
  container: {
    paddingTop: 4
  },
  customerAvatar: {
    backgroundColor: "blue",
    height: 110,
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    width: 110
  },
  objectAvatar: {
    backgroundColor: "lightgreen",
    height: 110,
    width: 110,
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer"
  },
  objectCard: {
    borderLeft: "5px solid red",
    height: "100%"
  },
  root: {
    margin: 0,
    maxHeight: "calc(100vh - 72px)",
    maxWidth: "350px",
    overflowY: "scroll",
    padding: 4
  },
  fixedWrap: {},
  scriptProgressStepper: {
    width: "100%",
    boxSizing: "border-box"
  },
  progress: {
    width: "100%"
  },
  scriptResult: {
    maxHeight: "45vh",
    minHeight: "10vh",
    overflowY: "scroll"
  },
  sellerAvatar: {
    backgroundColor: "red",
    height: 110,
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    width: 110
  },
  question: {
    marginBottom: 4
  },
  statusesCard: {
    marginTop: 4
  },
  statusDesc: {
    marginBottom: 4,
    width: "100%"
  },
  statusesButtons: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  hint: {
    marginTop: 4,
    marginBottom: 4,
    color: "#545454"
  },
  typography: {
    marginBottom: "8px"
  }
});

class BpBar extends Component {
  state = {
    title: "",
    call: null
  };

  handleCloseBp = () => () => {
    this.props.bpEnd();
    this.props.contentBarClose("bp");
  };

  changeTitle = title => {
    if (title) {
      this.setState({
        title
      });
    }
  }

  render() {
    const { classes, bp } = this.props;
    let resource = null;
    let section = null;
    let id = null;

    if (!bp._call && bp.section && bp.bp) {
      resource = bp.section;
      section = bp.section.substring(0, bp.section.length - 1);
      id = bp.bp[section + "_id"];
    }

    return (
      <div className={classes.root}>
        <div className={classes.fixedWrap}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Card>
                <CardActions>
                  <Grid container>
                    <Grid item xs={12}>
                      {bp._call && (<Typography variant="headline" component="h2" className={classes.typography} color="primary">
                        {bp._call === 1 ? <span>Входящий звонок</span> : <span>Исходящий звонок</span>}
                      </Typography>)}
                    </Grid>
                    <Grid container justify="space-between">
                      {/* {!bp._call && resource && id && <Call resource={resource} id={id} />} */}
                      <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={this.handleCloseBp(bp)}
                      >
                        Закрыть
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          {bp.bp && (<Grid container className={classes.container}>
            <Grid item xs={12}>
              <Card>
                <CardContent className={classes.cardContent} style={{ paddingBottom: "8px" }}>
                  <Typography variant="subheading">
                    Задача: {this.state.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>)}
          {bp.bp && (<Grid container spacing={8} className={classes.container}>
            <Grid item xs={12}>
              <SaleScriptForm
                classes={classes}
                bp={bp.bp}
                changeTitle={this.changeTitle}
                object={bp.object}
                client={bp.client}
              />
            </Grid>
          </Grid>)}
          <Grid
            container
            spacing={8}
            className={classes.container}
            direction="row"
            alignItems="stretch"
          >
            {bp.object && bp.object.street_id && (
              <Grid item xs={12} sm={6}>
                <ObjectCard classes={classes} object={bp.object} />
              </Grid>
            )}
            {bp.user && (
              <Grid item xs={12} sm={6}>
                <AgentCard classes={classes} user={bp.user} />
              </Grid>
            )}
            {bp.user2 && (
              <Grid item xs={12} sm={6}>
                <AgentCard classes={classes} user={bp.user2} />
              </Grid>
            )}
            {bp.client && (
              <Grid item xs={12} sm={6}>
                <CustomerCard classes={classes} client={bp.client} />
              </Grid>
            )}
            {bp.contragent && (
              <Grid item xs={12} sm={6}>
                <CustomerCard classes={classes} client={bp.contragent} />
              </Grid>
            )}
            {bp.object && bp.object.contact_people && (
              <Grid item xs={12} sm={6}>
                <OwnerCard
                  classes={classes}
                  contact_people={bp.object.contact_people}
                  object_id={bp.object.id}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bp: state.bp
});

const mapDispatchToProps = {
  bpEnd,
  contentBarClose
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BpBar);
