import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { YMaps, Map, Polygon, Button } from "react-yandex-maps";
import { change, Field, formValueSelector } from "redux-form";
import { REDUX_FORM_NAME } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
  root: {
    marginTop: 16,
    marginBottom: 8
  }
});

class YandexMapInput extends Component {
  static propTypes = {
    change: PropTypes.func
  };

  state = {
    center: [55.174962, 61.416323],
    zoom: 12,
    behaviors: ["default", "scrollZoom"],
    controls: ["fullscreenControl"],
    geometry: [],
    editing: true
  };

  componentDidMount() {
    const { source } = this.props;
    const geo_coordinates = this.props[source];

    if (geo_coordinates) {
      let geometry = geo_coordinates;

      if (typeof geometry !== "object") {
        geometry = JSON.parse(geometry);
      }

      this.setState({
        geometry,
        center: geometry[0]
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { source } = this.props;
    const geo_coordinates = nextProps[source];

    if (geo_coordinates) {
      let geometry = geo_coordinates;

      if (typeof geometry !== "object") {
        geometry = JSON.parse(geometry);
      }

      this.setState({
        geometry,
        center: geometry[0]
      });
    }
  }

  handleClick = e => {
    const { change, input, source } = this.props;
    let coords = e.get(source);

    if (!coords) {
      coords = e.get("coords");
    }

    this.setState(state => {
      const _state = {
        geometry: [...state.geometry, coords]
      };
      change(REDUX_FORM_NAME, [source], _state.geometry);
      input.onChange(_state.geometry);

      return _state;
    });
  };

  clearPolygon = () => {
    this.setState({
      geometry: []
    });
  };

  render() {
    const geometry = this.state.geometry;
    const { classes, meta } = this.props;

    return (
      <div className={classes.root}>
        <YMaps
          query={{
            ns: "use-load-option",
            load:
              "control.FullscreenControl,geoObject.addon.balloon,geometryEditor.Polygon"
          }}
        >
          <Map state={this.state} onClick={this.handleClick}>
            {geometry.length > 1 && (
              <Button
                data={{ content: '<i class="material-icons">clear</i>' }}
                onClick={this.clearPolygon}
                options={{
                  selectOnClick: false
                }}
              />
            )}
            <Polygon
              geometry={[geometry]}
              options={{
                fillColor: "#283593",
                strokeColor: "#001cb9",
                opacity: 0.5,
                strokeWidth: 3,
                strokeStyle: "shortdash"
              }}
            />
          </Map>
        </YMaps>
        {meta.submitFailed &&
          meta.error && <FormHelperText error>{meta.error[0]}</FormHelperText>}
      </div>
    );
  }
}

class YandexMapPolygon extends Component {
  render() {
    return (
      <Field
        name={this.props.source}
        component={YandexMapInput}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  [props.source]: formValueSelector(REDUX_FORM_NAME)(state, [props.source])
});

const mapDispatchToProps = {
  change
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(YandexMapPolygon);
