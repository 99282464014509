import { Component } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

class ScrollToTop extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.location &&
      nextProps.location &&
      this.props.location !== nextProps.location
    ) {
      if (
        nextProps.location.search.indexOf("show") > -1 ||
        nextProps.location.search.indexOf("user") > -1
      ) {
        return false;
      }

      if (
        nextProps.location &&
        nextProps.location.search &&
        nextProps.location.search.indexOf("?") > -1
      ) {
        if (
          this.props.location &&
          (this.props.location.search.indexOf("show") > -1 ||
            this.props.location.search.indexOf("user") > -1)
        ) {
          return false;
        }
      }

      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ScrollToTop);
