import React, { Component, Fragment } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { API_URL } from "../consts";

import {
  Button,
  translate,
  // TextInput,
  // SimpleForm,
  // ReferenceInput,
  // SelectInput,
  // AutocompleteInput,
  // SaveButton,
  showNotification,
  UPDATE,
} from "react-admin";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconInput from "@material-ui/icons/Input";
// import IconOpenInBrowser from "@material-ui/icons/OpenInBrowser";
import IconPlayForWork from "@material-ui/icons/PlayForWork";

// import { parse } from "query-string";

import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import dataProviderFactory from "../dataProvider";

class PostingButton extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  
  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleExportXml = () => {
    window.open(`${API_URL}/posting/export-xml`);
    // const { showNotification, location } = this.props;
    this.setState({
      open: false
    });
  };

  handleImportXml = () => {
  };

  handleFindDiscontinued = () => {
    const { showNotification } = this.props;
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "objects/discontinued/find-discontinued", {
          id: 'all',
          // redirectTo: false
        })
          .then(({ data }) => {
            this.setState({
              open: false
            });

            if (data) {
              showNotification("Discontinued objects found", "info");
            }
          })
          .catch(e => {
            this.setState({
              open: false
            });
            // showNotification(e, "warning");
          });
      }
    );
  };

  handleSendToAutocall = () => {
    const { showNotification } = this.props;
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "objects/discontinued/send-to-autocall", {
          id: 'all',
          // redirectTo: false
        })
          .then(({ data }) => {
            this.setState({
              open: false
            });

            if (data) {
              showNotification("Discontinued objects send to autocall", "info");
            }
          })
          .catch(e => {
            this.setState({
              open: false
            });
            // showNotification(e, "warning");
          });
      }
    );
  };

  render() {
    const { translate } = this.props;
    // const { translate, location } = this.props;
    // const search = parse(location.search);

    return (
      <Fragment>
        <Button
          label="resources.objects.button.posting"
          onClick={this.handleClickOpen}
        >
          <IconInput />
        </Button>
        <Drawer
          open={this.state.open}
          anchor="right"
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle id="form-dialog-title">
            {translate("Posting objects")}
          </DialogTitle>
          <DialogContent>
            <div>
              <Button label="resources.objects.action.posting_export_xml" onClick={this.handleExportXml}>
                <IconPlayForWork />
              </Button>
            </div>
            {/* <div>
              <Button label="resources.objects.action.posting_import_xml" onClick={this.handleImportXml}>
                <IconOpenInBrowser />
              </Button>
            </div> */}
            {/* <div>
              <Button label="resources.objects.action.find_discontinued" onClick={this.handleFindDiscontinued}>
                <IconOpenInBrowser />
              </Button>
            </div> */}
            {/* <div>
              <Button label="resources.objects.action.send_to_autocall" onClick={this.handleSendToAutocall}>
                <IconOpenInBrowser />
              </Button>
            </div> */}
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Drawer>
      </Fragment>
    ) ;
  }
}

const mapStateToProps = (state, props) => ({
  location: state.router.location
});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { showNotification }
  )
)(PostingButton);
