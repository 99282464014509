import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change, formValueSelector, Field } from "redux-form";
import { REDUX_FORM_NAME } from "react-admin";
import IconPhoto from "@material-ui/icons/AddAPhoto";
import FileManagerDialog from "../components/media/FileManagerDialog";
import GalleryLightbox from "../components/media/GalleryLightbox";
import { FILEMANAGER_URL } from "../consts";
import IconButton from "@material-ui/core/IconButton";
import IconCancel from "@material-ui/icons/Cancel";
import red from "@material-ui/core/colors/red";
import FormHelperText from "@material-ui/core/FormHelperText";

const handleCancel = (e, { index, photo, change, images }) => {

  if (typeof images === "string") {
    images = JSON.parse(images);
  }
  
  const _images = images.all.filter((item, key) => {
    return key !== index;
  });

  if(_images.length === 0){
    change(REDUX_FORM_NAME, "images", null);
  }else{
    change(REDUX_FORM_NAME, "images.all", _images);
  }

};

const Image = ({
  index,
  onClick,
  photo,
  src,
  alt,
  change,
  images,
  className,
  input
}) => {
  return (
    <div style={{ display: "inline-block", height: "100%" }}>
      <IconButton
        onClick={e => handleCancel(e, { index, photo, change, images })}
        style={{ position: "absolute", right: 0, color: red[500] }}
      >
        <IconCancel />
      </IconButton>
      <img
        src={src}
        alt={alt}
        onClick={e => onClick(e, { index, photo })}
        className={className}
      />
    </div>
  );
};

class ObjectPhotosInput extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    images: PropTypes.object
  };

  handleApply = selection => {
    if (selection && selection.length > 0) {
      const { change } = this.props;
      let { images } = this.props;

      let newImages = [];

      if (images) {
        newImages = selection.map(src => {
          return { original: {src} };
        });

        newImages = [...newImages, ...images.all];

      } else {
        newImages = selection.map(src => {
          return { original: {src} };
        });
      }

      change(REDUX_FORM_NAME, "images.all", newImages);
    }
  };

  render() {
    const { meta } = this.props;
    let { images } = this.props;
    let lightboxImages = null;
    let galleryImages = null;

    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    if (images) {
      if (images.all) {
        lightboxImages = images.all.map(image => {
          const im = image.large || image.original;
          if (im.src.match(/(http|https)/i)) {
            return {
              src: im.src
            };
          }

          return {
            src: FILEMANAGER_URL + im.src
          };
        });

        galleryImages = images.all.map(image => {
          const im = image.thumb || image.original;
          if (im.src.match(/(http|https)/i)) {
            return {
              src: im.src
            };
          }

          return {
            src: FILEMANAGER_URL + im.src
          };
        });
      }
    }

    return (
      <Fragment>
        <FileManagerDialog
          label="resources.objects.input.images"
          icon={<IconPhoto />}
          onApple={this.handleApply}
        />
        {lightboxImages &&
          galleryImages && (
            <GalleryLightbox
              lightboxImages={lightboxImages}
              galleryImages={galleryImages}
              columns={6}
              ImageComponent={ReduxImage}
              {...this.props}
            />
          )}
        {meta.submitFailed &&
          meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  images: formValueSelector(REDUX_FORM_NAME)(state, "images"),
});

const mapDispatchToProps = {
  change
};

const ReduxImage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Image);

class ObjectPhotos extends Component {
  render() {
    return <Field name={this.props.source} component={ObjectPhotosInput} {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectPhotos);
