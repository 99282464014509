import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import { compose } from "redux";

import { FunctionField, translate } from "react-admin";

import keys from "lodash/keys";
import groupBy from "lodash/groupBy";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { parse } from "query-string";
import CustomizableDatagrid from "./";

const styles = theme => ({
  fieldLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  groupCount: {
    fontWeight: "normal",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem"
  }
});

class GroupedPanel extends Component {
  renderChild = child => {
    const source = get(child, ["props", "source"]);
    const { group } = this.props;

    if (group.indexOf(source) === -1) {
      return React.cloneElement(child, {});
    }

    return null;
  };

  render() {
    const { record, children } = this.props;
    return (
      <CustomizableDatagrid
        {...this.props}
        data={record.records}
        ids={record.ids}
        showGroup={false}
        showColumns={false}
      >
        {React.Children.map(children, this.renderChild)}
      </CustomizableDatagrid>
    );
  }
}

const renderLabel = record => {
  return <strong>{record.label}</strong>;
};

class GroupDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.initGroupColumns(this.props) };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.initGroupColumns(nextProps));
  }

  initGroupColumns(props) {
    const { location, data, ids, classes, translate, resource } = props;
    const params = parse(location.search);
    if (!isEmpty(data.fetchedAt) && params.group) {
      const group = JSON.parse(params.group);
      if (group.length > 0) {
        const dateForGroup = {};
        ids.forEach(id => {
          dateForGroup[id] = data[id];
        });

        const groupedData = groupBy(dateForGroup, record => {
          let field = "";
          group.forEach((item, key) => {
            let group_by = get(record, item);

            if (!group_by) {
              group_by = "не назначен";
            }

            if (item === "user_id") {
              if (record && record.user) {
                group_by = "";

                if (record.user.last_name) {
                  group_by += `${record.user.last_name} `;
                }

                if (record.user.name) {
                  if (!record.user.last_name) {
                    group_by += `${record.user.name}`;
                  } else {
                    group_by += `${record.user.name[0].toUpperCase()}. `;
                  }
                }

                if (record.user.middle_name) {
                  group_by += `${record.user.middle_name[0].toUpperCase()}.`;
                }
              }
            }

            if (item === "object_id") {
              if (record && record.object) {
                group_by = "";

                if (record.object.street) {
                  group_by += `${record.object.street &&
                    record.object.street.name} `;
                }

                if (record.object.house) {
                  group_by += `${record.object.house}`;
                }
              }
            }

            if (item === "createdAt") {
              group_by = "";

              const date = new Date(record.createdAt);

              const day = date.getDate();
              const monthIndex = date.getMonth() + 1;
              const year = date.getFullYear();

              group_by += `${day}.${monthIndex}.${year}`;
            }

            if (key === group.length - 1) {
              field +=
                translate(`resources.${resource}.group.${item}`) +
                " " +
                group_by;
            } else {
              field +=
                translate(`resources.${resource}.group.${item}`) +
                " " +
                group_by +
                ", ";
            }
          });

          return field;
        });

        const newIds = keys(groupedData);
        const newData = {};

        newIds.forEach(label => {
          if (groupedData[label][0]) {
            if (!newData[label]) {
              newData[label] = {};
            }
            newData[label].records = {};

            groupedData[label].forEach(item => {
              newData[label].records[item.id] = item;
            });

            newData[label].label = (
              <span className={classes.fieldLabel}>
                <span>{label}</span>
                <span className={classes.groupCount}>
                  {translate("Grouped:")} {groupedData[label].length}
                </span>
              </span>
            );

            newData[label].ids = keys(newData[label].records);
          }
        });

        return {
          data: newData,
          ids: newIds,
          group
        };
      } else {
        return {
          data: null,
          ids: null,
          group: null
        };
      }
    }
  }

  render() {
    const { group } = this.state;
    const { bp } = this.props;

    return group && group.length > 0 ? (
      <CustomizableDatagrid
        expand={<GroupedPanel {...this.props} group={this.state.group} />}
        {...this.props}
        data={this.state.data || this.props.data}
        ids={this.state.ids || this.props.ids}
        rowClick="expand"
        childrenForGroup={this.props.children}
      >
        <FunctionField render={renderLabel} />
      </CustomizableDatagrid>
    ) : (
      <CustomizableDatagrid expand={bp || null} {...this.props}>
        {this.props.children}
      </CustomizableDatagrid>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  translate,
  withStyles(styles)
)(GroupDatagrid);
