import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Call from "./Call";

const SmsSender = ({ classes, data }) => {
  let fio = "";
  let contact_people = "";
  if (data.contact_people) {
    contact_people = data.contact_people;
    const parts = contact_people.split(" ");
    fio = contact_people[0];

    if (parts.length > 0) {
      fio = "";
      parts.forEach(item => {
        fio += item[0] ? item[0] : "";
      });
    }
  }

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        subheader="Связь с собственником"
      />
      <CardContent>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <Avatar aria-label="Recipe" className={classes.companyAgentAvatar}>
              {fio}
            </Avatar>
            <Typography component="h2" style={{ textAlign: "center" }}>
              {contact_people}
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Call id={data.id} resource="objects" />
            {/* <Button
              variant="contained"
              fullWidth
              className={classes.connectButton}
            >
              Написать
            </Button> */}
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Ваше имя"
              // className={classNames(classes.textField, classes.dense)}
              margin="dense"
              variant="filled"
            />
            <TextField
              fullWidth
              label="Ваш телефон"
              margin="dense"
              variant="filled"
            />
            <TextField
              fullWidth
              multiline
              rows="5"
              label="Текст сообщения"
              margin="dense"
              variant="filled"
            />
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SmsSender;
