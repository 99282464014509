import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { parse } from "query-string";

const ViewMode = ({ table, grid, mapgrid, defaultMode, ...props }) => {
  const { query } = props;
  const { mode } = parse(query);

  let element;
  switch (mode) {
    case "table":
      element = typeof table !== "undefined" && table;
      break;
    case "grid":
      element = typeof grid !== "undefined" && grid;
      break;
    case "mapgrid":
      element = typeof mapgrid !== "undefined" && mapgrid;
      break;
    default:
      if (typeof defaultMode !== "undefined") {
        element = defaultMode;
        break;
      }
      throw new Error(`Unknown mode ${mode}`);
  }

  return element ? React.cloneElement(element, props) : null;
};

ViewMode.propTypes = {
  query: PropTypes.string,
  table: PropTypes.element,
  grid: PropTypes.element,
  mapgrid: PropTypes.element
};

const mapStateToProps = state => ({
  query: state.router.location.search
});

export default connect(
  mapStateToProps,
  {}
)(ViewMode);
