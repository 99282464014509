import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { trimText } from "../../utils";
import { Link } from "react-admin";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  link: {
    display: "flex",
    width: 115,
    alignItems: "center"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
});

class ReadMoreReact extends Component {
  state = {
    displaySecondary: false,
    primaryText: "",
    secondaryText: "",
    text: "",
    readMoreText: this.props.readMoreText
  };

  componentDidMount() {
    let args = [
      this.props.text,
      this.props.min,
      this.props.ideal,
      this.props.max
    ];

    let textBreakdown = trimText(...args);

    this.setState({
      primaryText: textBreakdown[0],
      secondaryText: textBreakdown[1]
    });
  }

  setStatus = ev => {
    ev.preventDefault();
    let display = !this.state.displaySecondary;
    this.setState({
      displaySecondary: display
    });
  };

  render() {
    const { classes, text } = this.props;

    return this.state.secondaryText !== "" ? (
      <div className="display-text-group">
        {this.state.displaySecondary ? (
          <div className="displayed-text" onClick={this.setStatus.bind(this)}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        ) : (
          <div className="displayed-text">
            <div dangerouslySetInnerHTML={{ __html: this.state.primaryText }} />
          </div>
        )}
        <Link
          onClick={this.setStatus}
          aria-expanded={this.state.displaySecondary}
          aria-label={this.state.readMoreText}
          to=""
          className={classes.link}
        >
          {this.state.readMoreText}
          <ExpandMoreIcon
            className={classnames(classes.expand, {
              [classes.expandOpen]: this.state.displaySecondary
            })}
          />
        </Link>
      </div>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: text }} />
    );
  }
}

export default withStyles(styles)(ReadMoreReact);

ReadMoreReact.propTypes = {
  text: PropTypes.string.isRequired,
  min: PropTypes.number,
  ideal: PropTypes.number,
  max: PropTypes.number,
  readMoreText: PropTypes.string
};

ReadMoreReact.defaultProps = {
  readMoreText: "read more"
};
