import React, { Component, Fragment } from "react";

import {
  Button,
  SaveButton,
  UPDATE,
  showNotification,
  translate,
  setListSelectedIds
} from "react-admin";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { connect } from "react-redux";
import { compose } from "redux";
import dataProviderFactory from "../dataProvider";

export class RestoreObjectButton extends Component {
  state = {
    open: false,
    saving: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const {
      selectedIds,
      showNotification,
      setListSelectedIds
    } = this.props;

    this.setState({
      saving: true
    });
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "objects/restore", {
          id: selectedIds,
        })
          .then(({ data }) => {
            this.setState({
              saving: false,
              open: false
            });

            if (data) {
              showNotification("Object restored", "info");
              console.log("data ::: ", data);
              let ids = (data.id && data.id.split(",")) || [];
              ids.forEach((id) => {
                // console.log(this.state)
              })
            }

            setListSelectedIds("objects", []);
          })
          .catch(e => {
            this.setState({
              saving: false,
              open: false
            });
            showNotification(e, "warning");
          });
      }
    );
  };

  render() {
    const { translate } = this.props;
    return (
      <Fragment>
        <Button
          label={translate("resources.objects.input.restore")}
          onClick={this.handleClickOpen}
        />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          anchor="right"
          aria-labelledby="form-dialog-title"
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle id="form-dialog-title">
            {translate("resources.objects.restore")}
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <SaveButton
              basePath=""
              onClick={this.handleSubmit}
              saving={this.state.saving}
            />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { showNotification, setListSelectedIds }
  )
)(RestoreObjectButton);
