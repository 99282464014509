import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GalleryLightbox from "../components/media/GalleryLightbox";
import { compose } from "redux";
import { translate } from "react-admin";
import ExpPanel from "../components/panel/ExpPanel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ReadMoreReact from "../components/text/ReadMoreReact";
import SmsSender from "../components/ipphone/SmsSender";

const styles = theme => ({
  details: {
    marginTop: 20
  },
  card: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    borderRadius: 0,
    "&:last-child": {
      borderBottom: "none"
    },
    boxShadow: "none"
  },
  cardHeader: {
    background: "lightgrey"
  },
  exproot: {
    boxShadow: "none",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    "&:before": {
      display: "none"
    },

    "&:last-child": {
      borderBottom: "none"
    }
  },
  types: {
    textTransform: "uppercase",
    fontSize: 28
  },
  title: {
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 12
  },
  companyAgentAvatar: {
    backgroundColor: "grey",
    height: 60,
    margin: "0 auto",
    marginTop: 8,
    width: 60
  },
  connectButton: {
    marginBottom: 8,
    marginTop: 8
  }
});

const ObjectContent = ({ data, translate, classes, permissions }) => {
  let galleryImages = null;
  let lightboxImages = null;

  if (data.images) {
    if (typeof data.images === "string") {
      data.images = JSON.parse(data.images);
    }

    const images = data.images.all;

    lightboxImages = images.map(image => {
      return {
        src: image.large.src
      };
    });

    galleryImages = images.map(image => {
      return {
        ...image.thumb
      };
    });
  }

  return (
    <Fragment>
      <DialogTitle id="object-dialog-title" className={classes.title}>
        <Grid container spacing={0}>
          <Grid item xs={7}>
            <strong>
              {data.address_for_sorting ? (
                <span>{data.address_for_sorting}</span>
              ) : (
                data.street && (
                  <span>
                    {data.street.name}
                    {data.house}
                  </span>
                )
              )}
            </strong>
            <span> {data.flat}</span>{" "}
            {data.floor ? <strong>п{data.floor}</strong> : ""}
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right" }}>
            <span>
              <strong>
                <i className="fa fa-ruble-sign" /> {data.cost_of_owner}
              </strong>/{(data.cost_of_owner / data.area).toFixed(2)}
            </span>
          </Grid>
          <Grid item xs={12}>
            {data.types && (
              <strong className={classes.types}>{data.types.name}</strong>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {galleryImages &&
          lightboxImages && (
            <GalleryLightbox
              galleryImages={galleryImages}
              lightboxImages={lightboxImages}
              isSlider
            />
          )}
        <Grid container spacing={8} className={classes.details}>
          <Grid item xs={6}>
            <ExpPanel
              name="area"
              heading={translate("resources.objects.fields.area")}
              secondaryHeading={
                data.area ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.area + translate("measure.m2")
                    }}
                  />
                ) : (
                  " "
                )
              }
              details=""
              className={classes.exproot}
            />
            <ExpPanel
              name="floor"
              heading={translate("resources.objects.fields.floor")}
              secondaryHeading={
                data.floor
                  ? data.floor +
                    "/" +
                    (data.floor_house ? data.floor_house : "")
                  : " "
              }
              details=""
              className={classes.exproot}
            />
            <ExpPanel
              name="area"
              heading={translate("resources.objects.fields.series.name")}
              secondaryHeading={data.series ? data.series.name : " "}
              details=""
              className={classes.exproot}
            />
            {data.completed_year && (
              <Card className={classes.card}>
                <CardContent>
                  {translate("resources.objects.input.completed_year")}:
                  <strong> {data.completed_year}</strong>
                </CardContent>
              </Card>
            )}
            <ExpPanel
              name="area"
              heading={translate("resources.objects.fields.type_house.name")}
              secondaryHeading={data.type_house ? data.type_house.name : " "}
              details=""
              className={classes.exproot}
            />
            <ExpPanel
              name="agent_parse"
              heading={translate("resources.objects.fields.agent_parse")}
              secondaryHeading = {
                data.agent_parse && data.agent_parse === true
                  ? translate("resources.objects.fields.agent_parse.agent")
                  : translate("resources.objects.fields.agent_parse.owner")
              }
              details=""
              className={classes.exproot}
            />
            {
              data.parse_source_status &&
              <ExpPanel
                name="parse_source_status"
                heading={`${translate("resources.objects.fields.parse_source_status")} (${data.source_parse})`}
                secondaryHeading = {
                  (() => {
                    switch (data.parse_source_status) {
                      case 11:
                        return translate("resources.objects.fields.parse_source_status.11_about_month")
                      case 12:
                        return translate("resources.objects.fields.parse_source_status.12_less_then_month")
                      case 21:
                        return translate("resources.objects.fields.parse_source_status.21_about_month_confirmed")
                      case 22:
                        return translate("resources.objects.fields.parse_source_status.22_less_then_month_confirmed")
                      default:
                        return ""
                    }
                  })()
                }
                details=""
                className={classes.exproot}
              />
            }
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardContent>
                {data.description_cmi && (
                  <ReadMoreReact
                    text={data.description_cmi}
                    min={100}
                    ideal={200}
                    max={201}
                    readMoreText={translate("Read more")}
                  />
                )}
              </CardContent>
            </Card>
            {permissions && permissions.roles && permissions.roles.find(role => role.role === 'admin') && (
              <SmsSender classes={classes} data={data} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment>
  );
};

export default compose(
  withStyles(styles),
  translate
)(ObjectContent);
