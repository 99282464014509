import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  translate,
  SaveButton,
  Toolbar,
} from "react-admin";
import Button from "@material-ui/core/Button";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({});

const NameEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
      <SaveButton />
      <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const NameEdit = ({ onCancel, classes, ...props }) => {
  return (
    <Edit
      title={" "}
      {...props}
      resource="clients"
    >
      <SimpleForm toolbar={<NameEditToolbar onCancel={onCancel} />}>
        <TextInput source="fio" />
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(NameEdit);
