import React, { Component, Fragment } from "react";
import {
  translate,
  CardActions,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  showNotification,
  REDUX_FORM_NAME,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput
} from "react-admin";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Drawer from "@material-ui/core/Drawer";
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { compose } from "redux";
import { change, untouch, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";

import set from "lodash/set";

const styles = theme => ({
  rootWrap: {
    marginTop: 16
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  rootInner: {
    background: "rgba(150, 255, 150, 0.03)",
    borderRadius: 4,
    border: "1px solid rgba(139, 255, 139, 0.3)",
    overflow: "hidden"
  },
  questions: {
    textAlign: "center",
    padding: 8
  },
  questionsWrap: {
    overflow: "hidden",
    padding: 8
  },
  questions2: {
    marginLeft: 8,
    marginRight: 8,
    borderLeft: "2px solid rgba(0, 128, 0, 0.15)",
    borderRight: "2px solid rgba(0, 128, 0, 0.15)",
    borderBottom: "2px solid rgba(0, 128, 0, 0.15)",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: "rgba(150, 255, 150, 0.03)"
  },
  question: {
    textAlign: "center",
    border: "2px solid rgba(220, 0, 78, 0.5)",
    marginBottom: 16,
    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: -20,
      background: "rgba(0, 128, 0, 0.15)",
      height: 2,
      width: 10000
    },
    "&::before": {
      content: "''",
      display: "block",
      width: 2,
      background: "rgba(220, 0, 78, 0.15)",
      height: 16,
      position: "absolute",
      top: -18
    }
  },
  questionButton: {
    border: "2px solid rgba(220, 0, 78, 0.5)",
    marginLeft: 4,
    marginRight: 4
  },
  answers: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      width: 2,
      background: "rgba(220, 0, 78, 0.15)",
      height: 16,
      position: "absolute",
      top: -16,
      left: 0,
      right: 0,
      margin: "auto"
    }
  },
  answer: {
    border: "2px solid rgba(0, 128, 0, 0.55)",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
    marginTop: 8,
    "&::before": {
      content: "''",
      display: "block",
      width: 2,
      background: "rgba(0, 128, 0, 0.15)",
      height: 16,
      position: "absolute",
      top: -18
    }
  },
  answerButton: {
    border: "2px solid rgba(0, 128, 0, 0.55)"
  },
  script: {
    border: "2px solid rgba(117, 123, 247, 0.55)",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8
  },
  delete: {
    border: "2px solid red",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
    color: "red"
  },
  task: {
    border: "2px solid rgba(0, 128, 0, 0.55)",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
  },
  call: {
    border: "2px solid rgb(0 12 255 / 73%)",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
  },
  textInput: {
    width: 400
  },
  cardContent: {
    width: 400
  },
  cardActions: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  formControlLabel: {
    width: "100%",
    marginBottom: "8px"
  }
});

class ScriptsTree extends Component {
  state = {
    data: {},
    root: [],
    index: 0,
    script: {
      path: "",
      type: "",
      id: "",
      item: {
        name: ""
      }
    },
    open: false,
    newItems: [],
    newQuestions: [],
    bindScript: false,
    bindTasks: false,
    bindCalls: false
  };

  constructor(props) {
    super(props);

    if(!props.script){
      props.change(REDUX_FORM_NAME, "script", {
        question: {
          name: "Вопрос"
        }
      })
    }
  }

  question(o, a, i) {
    return (
      <div key={i} className={this.props.classes.questions}>
        <Button
          className={this.props.classes.question}
          onClick={this.handleClickQuestion(i, o)}
        >
          {o.name}
        </Button>
        <div className={this.props.classes.answers}>{a}</div>
      </div>
    );
  }

  answers(o) {
    const answers = [];
    for (var i in o) {
      if (o[i]) {
        if (o[i].question) {
          const a = this.answers.apply(this, [o[i].question.answers]);
          const q = this.question.apply(this, [o[i].question, a, i]);
          answers.push(
            <div key={i} className={this.props.classes.questions2}>
              <div className={this.props.classes.questionsWrap}>
                <Button
                  className={this.props.classes.answer}
                  onClick={this.handleClickAnswer(i, o[i])}
                >
                  {o[i].name}
                </Button>
                <IconButton
                  onClick={this.handleClickVisibility(i, o[i])}
                >
                  { o[i].visible ? <VisibilityOffIcon/> :  <VisibilityIcon />}
                </IconButton>
                { o[i].visible && <div>{q}</div> }
              </div>
            </div>
          );
        } else {
          answers.push(
            <div key={i} className={this.props.classes.questions}>
              <Button
                className={this.props.classes.answer}
                onClick={this.handleClickAnswer(i, o[i])}
              >
                {o[i].name}
              </Button>
            </div>
          );
        }
      }
    }

    return answers;
  }

  traverse(o) {
    const root = [];
    for (var i in o) {
      if (o[i] !== null && typeof o[i] == "object") {
        if (i === "question") {
          const a = this.answers.apply(this, [o[i].answers]);
          root.push(
            <div key={i} className={this.props.classes.rootInner}>
              {this.question.apply(this, [o[i], a, i])}
            </div>
          );
        }
      }
    }

    return root;
  }

  renameKeys(o, prefix) {
    for (var i in o) {
      if (i.indexOf(".") === 1) {
        o[prefix + "." + i] = o[i];
        delete o[i];
      }
      if (o[i] !== null && typeof o[i] == "object") {
        this.renameKeys(o[i], prefix);
      }
      if (
        o[prefix + "." + i] !== null &&
        typeof o[prefix + "." + i] == "object"
      ) {
        this.renameKeys(o[prefix + "." + i], prefix);
      }
    }
  }

  getPath = key => {
    const indexes = key.split(".");
    let currIndex = [];
    let question = this.props.script.question;
    let path = "";

    indexes.forEach(item => {
      currIndex.push(item);
      if (currIndex.length > 1) {
        const index = currIndex.join(".");
        if (question.answers && question.answers[index]) {
          path += `['question']['answers']['${index}']`;
          if (question.answers[index].question) {
            question = question.answers[index].question;
          } else {
            question = question.answers[index];
          }
        }
      }
    });

    if (path === "") {
      return "['question']";
    }

    return path;
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        newItems: [],
        newQuestions: [],
        bindScript: false
      },
      () => {
        this.props.change(REDUX_FORM_NAME, "scripts", null);
        this.props.untouch(REDUX_FORM_NAME, "scripts");
      }
    );
  };

  handleClickAnswer = (i, o) => () => {
    this.setState({
      open: true,
      script: {
        item: o,
        type: "answer",
        id: i
      }
    });

    if (o.template_id) {
      this.props.change(REDUX_FORM_NAME, "template_id", o.template_id);
    } else {
      this.props.change(REDUX_FORM_NAME, "template_id", []);
    }
  };

  handleClickVisibility = (i, o) => () => {
    if (o.visible) {
      o.visible = false
    } else {
      o.visible = true
    }

    this.setState({
      script: {
        item: o,
        type: "answer",
        id: i
      }
    });
  };

  handleClickQuestion = (i, o) => () => {
    this.setState({
      open: true,
      script: {
        item: o,
        type: "question",
        id: i
      }
    });

    if (o.call_to) {
      this.setState({
        bindCalls: true
      });
      this.props.change(REDUX_FORM_NAME, "call_to", o.call_to);
    } else {
      this.props.change(REDUX_FORM_NAME, "call_to", null);
    }
  };

  handleChangeScriptItemQuestion = ev => {
    const data = this.props.script;
    const item = this.state.script.item;
    let path = this.getPath(this.state.script.id);

    if (this.state.script.id !== "question") {
      path += "['question']";
    }

    set(data, path, { ...item, name: ev.target.value });
    this.setState({
      data,
      script: {
        ...this.state.script,
        item: {
          ...this.state.script.item,
          name: ev.target.value
        }
      }
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleChangeScriptItemAnswer = ev => {
    const data = this.props.script;
    const item = this.state.script.item;
    const path = this.getPath(this.state.script.id);
    set(data, path, { ...item, name: ev.target.value });
    this.setState({
      data,
      script: {
        ...this.state.script,
        item: {
          ...this.state.script.item,
          name: ev.target.value
        }
      }
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleChangeScriptNewItemQuestion = item => ev => {
    let path = this.getPath(item.id);
    const data = this.props.script;

    if (item.id !== "question") {
      path += "['question']";
    }

    set(data, path, { ...item, name: ev.target.value });
    this.setState({
      data
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleChangeScriptNewItemAnswer = item => ev => {
    const path = this.getPath(item.id);
    const data = this.props.script;

    set(data, path, { ...item, name: ev.target.value });
    this.setState({
      data
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleChangeCheckbox = (event) => {
    const data = this.props.script;
    const item = this.state.script.item;
    const path = this.getPath(this.state.script.id);

    this.setState({
      script: {
        ...this.state.script,
        item: {
          ...this.state.script.item,
          complete: event.target.checked
        }
      }
    });

    set(data, path, {
      ...item,
      complete: event.target.checked
    });

    this.setState({
      data
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  }

  handleClickAddQuestion = () => {
    const data = this.props.script;
    const item = this.state.script.item;
    const path = this.getPath(this.state.script.id);

    set(data, path, {
      ...item,
      question: {
        name: ""
      }
    });

    this.setState({
      data,
      newQuestions: [
        ...this.state.newQuestions,
        {
          name: "",
          id: this.state.script.id
        }
      ]
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleClickAddAnswer = () => {
    const data = this.props.script;
    const item = this.state.script.item;
    const id = this.state.script.id;
    let path = this.getPath(id);
    let lastId = "";
    let newId = "1.0";

    if (id !== "question") {
      newId = id + ".0";
      path += "['question']";
    }

    if (!item.answers) {
      item.answers = {};
    }

    for (let k in item.answers) {
      lastId = k;
    }

    if (!lastId) {
      lastId = newId;
    }

    const parts = lastId.split(".");
    const last = parseInt(parts[parts.length - 1]) + 1;
    parts[parts.length - 1] = last;
    newId = parts.join(".");

    item.answers[newId] = {
      name: "",
      type: Object.keys(item.answers).length || 1
    };

    set(data, path, {
      ...item
    });

    this.setState({
      data,
      script: {
        ...this.state.script,
        item: item
      },
      newItems: [
        ...this.state.newItems,
        {
          id: newId,
          name: "",
          type: Object.keys(item.answers).length || 1
        }
      ]
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleClickDeleteAnswer = () => {
    const data = this.props.script;
    let path = this.getPath(this.state.script.id);

    set(data, path, null);
    this.setState({
      open: false,
      data,
      script: {
        path: "",
        type: "",
        id: "",
        item: {
          name: ""
        }
      }
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleClickDeleteQuestion = () => {
    const data = this.props.script;
    let path = this.getPath(this.state.script.id);

    if (this.state.script.id !== "question") {
      path += "['question']";
    }

    set(data, path, null);
    this.setState({
      open: false,
      data,
      script: {
        path: "",
        type: "",
        id: "",
        item: {
          name: ""
        }
      }
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleClickBindScript = () => {
    this.setState({
      bindScript: !this.state.bindScript
    });
  };

  handleClickBindTasks = () => {
    this.setState({
      bindTasks: !this.state.bindTasks
    });
  };

  handleClickBindCalls = () => {
    this.setState({
      bindCalls: !this.state.bindCalls
    });
  };

  handleChangeBindScript = (ev, value) => {
    const script = this.props.scripts[value].script;
    const data = this.props.script;
    const item = this.state.script.item;
    const path = this.getPath(this.state.script.id);
    this.renameKeys(script, this.state.script.id);

    set(data, path, {
      ...item,
      question: script.question
    });

    this.setState({
      data
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  };

  handleChangeBindTasks = (ev, value) => {
    const data = this.props.script;
    const item = this.state.script.item;
    const path = this.getPath(this.state.script.id);

    set(data, path, {
      ...item,
      template_id: value
    });

    this.setState({
      data
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  }

  handleChangeBindCalls = (ev, value) => {
    const data = this.props.script;
    const item = this.state.script.item;
    let path = this.getPath(this.state.script.id);

    if (this.state.script.id !== "question") {
      path += "['question']";
    }

    set(data, path, { ...item, call_to: value });
    this.setState({
      data,
      script: {
        ...this.state.script,
        item: {
          ...this.state.script.item,
          call_to: value
        }
      }
    },
    () => {
      this.props.change(REDUX_FORM_NAME, "script", data);
    });
  }

  render() {
    const { classes, catId } = this.props;
    return (
      <div className={classes.rootWrap}>
        <div className={classes.root}>{this.traverse(this.props.script)}</div>
        <Drawer
          anchor="right"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div>
            <Button onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <CardContent className={this.props.classes.cardContent}>
            {this.state.script.type === "answer" && (<div>
              <TextInput
                name="answer"
                source="answer"
                label="resources.scripts.input.answer"
                defaultValue={this.state.script.item.name || ""}
                onChange={this.handleChangeScriptItemAnswer}
                className={this.props.classes.textInput}
              />
              {this.state.newItems.map((item, key) => {
                return (
                  <TextInput
                    key={item.id + key}
                    name={"answer" + item.id + key}
                    source={"answer" + item.id + key}
                    label="resources.scripts.input.answer"
                    onChange={this.handleChangeScriptNewItemAnswer(item)}
                    className={this.props.classes.textInput}
                  />
                );
              })}
              {this.state.newQuestions.map((item, key) => {
                return (
                  <TextInput
                    key={item.id}
                    name={"question" + item.id}
                    source={"question" + item.id}
                    label="resources.scripts.input.question"
                    onChange={this.handleChangeScriptNewItemQuestion(item)}
                    className={this.props.classes.textInput}
                  />
                );
              })}
              {this.state.bindScript && (
                <ReferenceInput
                  source={"scripts"}
                  resource="scripts"
                  label="resources.scripts.input.script"
                  reference="scripts"
                  perPage={25}
                  sort={{ field: "id", order: "ASC" }}
                  onChange={this.handleChangeBindScript}
                  className={this.props.classes.textInput}
                >
                  <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
              )}
              {this.state.bindTasks && (
              <ReferenceArrayInput
                source="template_id"
                reference="tasks-templates"
                resource="tasks-templates"
                label="resources.event-actions.input.task"
                onChange={this.handleChangeBindTasks}
                // filter={{ cat_ids: catId ? [catId] : null }}
              >
                <AutocompleteArrayInput
                  optionText="title"
                  optionValue="id"
                  fullWidth={true}
                  source="template_id"
                />
              </ReferenceArrayInput>
              )}
            </div>)}
            {this.state.script.type === "question" && (<div>
              <TextInput
                name="question"
                source="question"
                label="resources.scripts.input.question"
                defaultValue={this.state.script.item.name || ""}
                onChange={this.handleChangeScriptItemQuestion}
                className={this.props.classes.textInput}
              />
              {this.state.newItems.map((item, key) => {
                return (
                  <TextInput
                    key={item.id + key}
                    name={"answer" + item.id + key}
                    source={"answer" + item.id + key}
                    label="resources.scripts.input.answer"
                    onChange={this.handleChangeScriptNewItemAnswer(item)}
                    className={this.props.classes.textInput}
                  />
                );
              })}
              {this.state.bindCalls && (
                <SelectInput
                  source="call_to"
                  label="resources.event-actions.input.call_to"
                  choices={[
                    { id: 1, name: "Собственнику" },
                    { id: 2, name: "Клиенту" }
                  ]}
                  fullWidth={true}
                  allowEmpty={true}
                  onChange={this.handleChangeBindCalls}
                />
              )}
            </div>)}
          </CardContent>
          <CardContent className={this.props.classes.cardContent}>
            <CardActions className={this.props.classes.cardActions}>
              {this.state.script.type === "question" && (
                <Fragment>
                  <Button
                    key="answer"
                    className={this.props.classes.answerButton}
                    onClick={this.handleClickAddAnswer}
                  >
                    <AddIcon /> {" Ответ"}
                  </Button>
                  <Button
                    className={this.props.classes.call}
                    onClick={this.handleClickBindCalls}
                  >
                    <AddIcon /> {" Звонок"}
                  </Button>
                  <Button
                    className={this.props.classes.call}
                    onClick={this.handleClickBindCalls}
                  >
                    <AddIcon /> {" Автоматический обзвон"}
                  </Button>
                  <Button
                    className={this.props.classes.delete}
                    onClick={this.handleClickDeleteQuestion}
                  >
                    <DeleteIcon /> {" Удалить"}
                  </Button>
                </Fragment>
              )}
              {this.state.script.type === "answer" && (
                <Fragment>
                  <FormControlLabel
                    control={<Checkbox
                      checked={this.state.script.item.complete}
                      color="primary"
                      onChange={this.handleChangeCheckbox}
                    />}
                    label="Завершить задачу?"
                    className={classes.formControlLabel}
                  />
                  <FormControlLabel
                    control={<Checkbox
                      checked={this.state.script.item.complete}
                      color="primary"
                      onChange={this.handleChangeCheckbox}
                    />}
                    label="Включить аудио уведомление?"
                    className={classes.formControlLabel}
                  />
                  <Button
                    className={this.props.classes.questionButton}
                    onClick={this.handleClickAddQuestion}
                    disabled={this.state.newQuestions.length > 0}
                  >
                    <AddIcon /> {" Вопрос"}
                  </Button>
                  <Button
                    className={this.props.classes.task}
                    onClick={this.handleClickBindTasks}
                  >
                    <AddIcon /> {" Задачи"}
                  </Button>
                  <Button
                    className={this.props.classes.script}
                    onClick={this.handleClickBindScript}
                  >
                    <AddIcon /> {" Скрипт"}
                  </Button>
                  <Button
                    className={this.props.classes.delete}
                    onClick={this.handleClickDeleteAnswer}
                  >
                    <DeleteIcon /> {" Удалить"}
                  </Button>
                </Fragment>
              )}
            </CardActions>
          </CardContent>
        </Drawer>
      </div>
    );
  }
}

class FieldScriptTree extends Component {
  render() {
    return <Field name="script" component={ScriptsTree} {...this.props} />;
  }
}

const mapStateToProps = (state, props) => ({
  scripts: state.admin.resources.scripts.data,
  script: formValueSelector(REDUX_FORM_NAME)(state, "script"),
});

export default compose(
  withStyles(styles),
  translate,
  connect(mapStateToProps, { showNotification, change, untouch })
)(FieldScriptTree);
