import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography, 
  withStyles
} from "@material-ui/core";

const styles = theme => ({
  question: {
    color: "rgba(220, 0, 78, 1)",
    display: "block"
  },
  answer: {
    color: "#283593",
    display: "block"
  },
  title: {
    fontSize: "1.2rem"
  }
});

export class SaleScriptHistory extends Component {
  render() {
    const { classes, script, name } = this.props;
    return (
      <Grid item xs={12}>
        <Card className={classes.scriptResult}>
          <CardHeader 
            title="Скрипт"
            subheader={name}
            classes={{
              title: classes.title
            }}
          />
          <CardContent className={classes.cardContent}>
            <div>
              {script.map((item, key) => {
                return <Typography paragraph key={key}>
                  <span className={classes.question}>{item.question.name}</span>
                  <strong className={classes.answer}>{item.question.answer.name}</strong>
                </Typography>
              })}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SaleScriptHistory);
