import React, { Component } from "react";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import IconMessage from "@material-ui/icons/Message";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import { translate, GET_LIST, withDataProvider } from "react-admin";
import {
  contentBarOpen,
  contentBarClose
} from "../../actions/contentBarActions";

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";

import { compose } from "redux";
import { connect } from "react-redux";
import { socket } from "../../consts";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        padding: 0,
        backgroundColor: "#fff"
      },
      popper: {
        opacity: 1
      }
    }
  }
});

const styles = theme => ({
  messages: {
    padding: "0!important"
  },
  message: {
    paddingBottom: 0
  },
  tooltip: {
    backgroundColor: "#fff"
  },
  inline: {
    display: "inline",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap"
  }
});

const renderMessages = (messages, classes) => {
  return (
    <Card className={classes.chatCard}>
      <CardContent className={classes.messages}>
        {messages.length > 0 && (
            <List className={classes.messagesList}>
              {messages.map((item, key) => {
                return [
                  item.format_date && (
                    <ListItem alignitems="flex-center" key={key} className={classes.message}>
                      <ListItemText
                        secondary={
                          <Typography
                            component="span"
                            className={classes.messagesDate}
                          >
                            {item.format_date}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ),
                  <ListItem
                    key={key + 10}
                    className={classes.messagesListItem}
                  >
                    {item.photo && (
                      <ListItemAvatar>
                        <Avatar
                          alt={item.username}
                          src={item.photo}
                          className={classes.userAvatar}
                        />
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      primary=""
                      secondary={
                        <React.Fragment>
                          {item.username}
                          <br />
                          <Typography
                            component="span"
                            className={classes.inline}
                          >
                            {item.message}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ];
              })}
            </List>
          )}
      </CardContent>
    </Card>
  );
}

class ChatButton extends Component {
  state = {
    count: 0,
    tooltipContent: "",
    loading: false,
    open: false,
    id: null,
    data: null,
    play: false
  };

  componentWillMount() {
    socket.instance.on("new messages in room", this.playSound);
  }

  componentWillUnmount() {
    socket.instance.off("new messages in room", this.playSound);
  }

  playSound = data => {
    if (data.rooms && data.rooms.length === 1) {
      this.setState({
        play: true
      });
      setTimeout(() => this.setState({ play: false }),2000);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rooms } = nextProps;
    let count = 0;

    rooms.forEach(item => {
      if (item.count) {
        count += item.count;
      }

    });

    this.setState({ count });
  }

  handleClick = (ev) => {
    ev.stopPropagation();
    const { contentBarOpen, contentBarClose, chat, object } = this.props;
    if (chat) {
      contentBarClose("chat");
    } else {
      if (object) {
        contentBarOpen("chat", object);
      } else {
        contentBarOpen("chat");
      }
    }
  };

  tooltip = (label) => {
    const { translate, object } = this.props
    if (object) {
      return this.state.tooltipContent;
    } else {
      return label && translate(label, { _: label });
    }
  }

  async fetchChat(id) {
    if (!this.state.loading ) {
      const { dataProvider } = this.props;
      this.setState({
        loading: true,
        tooltipContent: <CircularProgress color="primary" />
      });
      
      await dataProvider(GET_LIST, 'chat', {
        filter: { id },
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 3 },
      }).then(res => {
        this.setState({
          loading: false,
          tooltipContent: renderMessages(res.data, this.props.classes)
        });
        return res;
      });
    }
  }

  handleOpen = () => {
    const { object } = this.props
    this.setState({
      open: true
    });
    if (object && this.state.open && (!this.state.id || this.state.id !== object.id)) {
      this.setState({
        id: object.id
      });
      this.fetchChat(object.id)
    }
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  }

  render() {
    const { label, style, showBadge, object } = this.props;

    return (
      <MuiThemeProvider theme={object ? theme : defaultTheme}>
        {this.state.play && <audio src="sound/3088_call-sound.ru__.mp3" controls={false} autoPlay />}
        <Tooltip
          title={this.tooltip(label)}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          open={this.state.open}
        >
          <IconButton color="inherit" onClick={this.handleClick} style={style}>
            {showBadge && this.state.count > 0 ? (
              <Badge badgeContent={this.state.count} color="primary">
                <IconMessage />
              </Badge>
            ) : (
              <IconMessage />
            )}
          </IconButton>
        </Tooltip>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  chat: state.contentBar.chat,
  rooms: state.chat.rooms,
  leave: state.chat.leave
});

export default compose(
  connect(
    mapStateToProps,
    { contentBarOpen, contentBarClose }
  ),
  translate,
  withDataProvider,
  withStyles(styles)
)(ChatButton);
