import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";

import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  translate,
  GET_LIST,
  CardActions,
  ListButton
} from "react-admin";

import RoleAccesses from "./RoleAccesses";
import dataProviderFactory from "../dataProvider";
import CircularProgress from "@material-ui/core/CircularProgress";

const validate = translate => values => {
  const errors = {};

  if (!values.name) {
    errors.name = [
      translate("validation.required", {
        name: translate("resources.roles.fields.role_title")
      })
    ];
  }

  if (!values.slug) {
    errors.slug = [
      translate("validation.required", {
        name: translate("resources.roles.fields.slug")
      })
    ];
  }

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  tabSelected: {},
  last_name: { display: "inline-block" },
  name: { display: "inline-block", marginLeft: 16 },
  middle_name: {},
  email: { display: "inline-block" },
  phone: { display: "inline-block", marginLeft: 16 }
});

const RoleActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

class RoleCreate extends Component {
  state = {
    perms: null
  };

  constructor(props) {
    super(props);
    
    this.fetchPerms();
  }

  fetchPerms = () => {
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(GET_LIST, `perms/`, {
          filter: {},
          sort: {},
          pagination: {}
        }).then(({ data }) => {
          this.setState({
            perms: data
          });
        });
      }
    );
  };

  render() {
    const { classes, translate } = this.props;
    return (
      <Create {...this.props} actions={<RoleActions />}>
        <TabbedForm
          scrollable={true}
          scrollButtons="auto"
          validate={validate(translate)}
        >
          <FormTab
            label="resources.roles.tabs.main"
            tabClasses={{ root: classes.tabRoot }}
          >
            <TextInput
              source="name"
              label="resources.roles.fields.role_title"
            />
            <TextInput source="slug" />
          </FormTab>
          <FormTab
            label="resources.roles.tabs.accesses"
            path="accesses"
            tabClasses={{ root: classes.tabRoot }}
          >
            {!this.state.perms ? (
              <div className={classes.ProgressRoot}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <RoleAccesses perms={this.state.perms} />
            )}
          </FormTab>
        </TabbedForm>
      </Create>
    );
  }
}

export default compose(
  withStyles(styles),
  translate
)(RoleCreate);
