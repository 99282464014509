import React, { Component } from "react";
import {
  Edit,
  FormTab,
  TabbedForm,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  translate,
  CardActions,
  ListButton,
  CreateButton
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "../components/input/PhoneInput";
import UserPhoto from "./UserPhoto";
import FullNameField from "../components/field/FullNameField";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  tabSelected: {},
  last_name: { display: "inline-block" },
  name: { display: "inline-block", marginLeft: 16 },
  middle_name: {},
  phone: {
    display: "inline-block",
    marginRight: 16
  }
});

const UserActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <ListButton basePath={basePath} />
  </CardActions>
);

const UserTitle = ({ record }) =>
  record ? <FullNameField record={record} size={32} /> : null;

class UserEdit extends Component {

  state = {
    perms: null
  };

  render() {
    const { translate, classes } = this.props;
    return (
      <Edit
        title={<UserTitle />}
        {...this.props}
        classes={{ card: classes.card }}
        actions={<UserActions />}
      >
        <TabbedForm
          scrollable={true}
          scrollButtons="auto"
          validate={validate(translate)}
        >
          <FormTab
            label="resources.users.tabs.profile"
            tabClasses={{ root: classes.tabRoot }}
          >
            <UserPhoto />
            <TextInput
              label="ra.profile.last_name"
              source="last_name"
              formClassName={classes.last_name}
            />
            <TextInput
              label="ra.profile.name"
              source="name"
              formClassName={classes.name}
            />
            <TextInput
              label="ra.profile.middle_name"
              source="middle_name"
              formClassName={classes.middle_name}
            />
            <TextInput
              label="ra.profile.email"
              type="email"
              source="email"
              formClassName={classes.email}
            />
            <br />
            <PhoneInput
              source="phone"
              label="ra.profile.phone"
              formClassName={classes.phone}
            />
            <PhoneInput
              source="line"
              label="ra.profile.line"
              formClassName={classes.phone}
            />
          </FormTab>
          <FormTab
            label="resources.users.tabs.main"
            tabClasses={{ root: classes.tabRoot }}
          >
            <TextInput label="ra.auth.login" source="login" />
            <TextInput
              label="ra.auth.password"
              source="password"
              type="password"
            />
            <ReferenceArrayInput
              label="resources.users.input.role"
              source="role_id"
              reference="roles"
            >
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  }
}

export default compose(
  withStyles(styles),
  translate
)(UserEdit);
