import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getFormValues, change } from "redux-form";
import { compose } from "redux";

import { translate, REDUX_FORM_NAME, UPDATE } from "react-admin";
import dataProviderFactory from "../../dataProvider";
import { refreshPercent } from "../../tasks/taskActions";

import withStyles from "@material-ui/core/styles/withStyles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckIcon from "@material-ui/icons/Check";
import UndoIcon from "@material-ui/icons/Undo";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper
  },
  listItemText: {
    maxWidth: 300
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8
  },
  isCompleted: {
    textDecoration: "line-through",
    opacity: 0.5
  },
  red: {
    color: red[800]
  },
  orange: {
    color: orange[500]
  },
  green: {
    color: green[500]
  }
});

function compare(a, b) {
  if (a.isCompleted) {
    return 1;
  }
  return -1;
}

class Checklist extends Component {
  state = {
    hover: null,
    edit: {}
  };

  componentDidMount() {
    const { record, source, change } = this.props;

    if (record.checklist && record.checklist.length > 0) {
      record.checklist.sort(compare);
      change(REDUX_FORM_NAME, `${source}.${record.id}`, record.checklist);
    }
  }

  calculatePercent = checklist => {
    const count = checklist.length;
    let count_completed = 0;

    checklist.forEach(item => {
      if (item.isCompleted) {
        count_completed++;
      }
    });

    return Math.floor((100 / count) * count_completed);
  }

  updateChecklist = checklist => {
    const { source, change, refreshPercent, isRefresh, record } = this.props;
    const id = record.id;
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(UPDATE, "tasks", { id, data: { checklist } }).then(() => {
          checklist.sort(compare);
          change(REDUX_FORM_NAME, `${source}.${id}`, checklist);
          if (isRefresh) {
            record.percent = this.calculatePercent(checklist);
            refreshPercent(id, this.props.record);
          }
        });
      }
    );
  };

  handleEnter = index => () => {
    this.setState({
      hover: index
    });
  };

  handleLeave = () => {
    this.setState({
      hover: null
    });
  };

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  handleClickComplete = index => ev => {
    const { source, values, record } = this.props;

    if (values && values[source] && values[source][record.id]) {
      const checklist = values[source][record.id];

      checklist[index].isCompleted = !checklist[index].isCompleted;

      this.updateChecklist(checklist);
    }
  };

  handleClickEdit = (text, key) => () => {
    this.setState({
      edit: {
        text,
        key
      },
      text
    });
  };

  handleChangeCheckitem = key => ev => {
    this.setState({
      edit: {
        key,
        text: ev.target.value
      }
    });
  };

  handleClickChecklistComplete = index => ev => {
    const { source, values, record } = this.props;

    if (values && values[source] && values[source][record.id]) {
      if (this.state.edit.text !== this.state.text) {
        const checklist = values[source][record.id];

        checklist[index].title = this.state.edit.text;

        this.updateChecklist(checklist);
      }

      this.setState({
        edit: {}
      });
    }
  };

  handleClickDelete = index => ev => {
    const { source, values, record } = this.props;

    if (values && values[source] && values[source][record.id]) {
      const checklist = values[source][record.id];

      checklist.splice(index, 1);

      this.updateChecklist(checklist);
    }
  };

  handleClickAdd = ev => {
    const title = this.state.checkitem;
    if (title && title.length > 0) {
      const { source, values, record } = this.props;

      let data = [];

      if (values && values[source] && values[source][record.id]) {
        data = values[source][record.id];
      }

      data.push({
        title,
        isCompleted: false
      });

      this.updateChecklist(data);

      this.setState({
        checkitem: ""
      });
    }
  };

  render() {
    const { label, source, translate, values, classes, record } = this.props;
    const { checkitem, edit } = this.state;

    let data = null;

    if (values && values[source] && values[source][record.id]) {
      data = values[source][record.id];
    }

    return (
      <Fragment>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">{label && translate(label)}</FormLabel>
          <div>
            <TextField
              name="checkitem"
              onChange={this.handleChange}
              value={checkitem || ""}
            />
            <Button
              color="primary"
              aria-label="Add"
              onClick={this.handleClickAdd}
            >
              <AddIcon />
            </Button>
          </div>
        </FormControl>
        {data && (
          <div className={classes.root}>
            <List>
              {data.map((item, key) => {
                if (!item.isCompleted) {
                  return (
                    <ListItem
                      key={key}
                      role={undefined}
                      dense
                      button
                      onMouseEnter={this.handleEnter(key)}
                      onMouseLeave={this.handleLeave}
                    >
                      {edit.key === key ? (
                        <TextField
                          value={this.state.edit.text}
                          onChange={this.handleChangeCheckitem(key)}
                        />
                      ) : (
                        <ListItemText
                          primary={item.title}
                          className={classes.listItemText}
                        />
                      )}
                      <ListItemSecondaryAction
                        hidden={this.state.hover !== key}
                        onMouseEnter={this.handleEnter(key)}
                        onMouseLeave={this.handleLeave}
                      >
                        {edit.key !== key ? (
                          <Fragment>
                            <IconButton
                              aria-label="complete"
                              onClick={this.handleClickComplete(key)}
                            >
                              <CheckIcon className={classes.green} />
                            </IconButton>
                            <IconButton
                              aria-label="edit"
                              onClick={this.handleClickEdit(item.title, key)}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={this.handleClickDelete(key)}
                            >
                              <DeleteIcon className={classes.red} />
                            </IconButton>
                          </Fragment>
                        ) : (
                          <IconButton
                            aria-label="complete"
                            onClick={this.handleClickChecklistComplete(key)}
                          >
                            <CheckIcon className={classes.green} />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      key={key}
                      role={undefined}
                      dense
                      button
                      className={classes.isCompleted}
                      onMouseEnter={this.handleEnter(key)}
                      onMouseLeave={this.handleLeave}
                    >
                      <ListItemText
                        primary={item.title}
                        className={classes.listItemText}
                      />
                      <ListItemSecondaryAction
                        hidden={this.state.hover !== key}
                        onMouseEnter={this.handleEnter(key)}
                        onMouseLeave={this.handleLeave}
                      >
                        <IconButton
                          aria-label="complete"
                          onClick={this.handleClickComplete(key)}
                        >
                          <UndoIcon className={classes.orange} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={this.handleClickDelete(key)}
                        >
                          <DeleteIcon className={classes.red} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }
              })}
            </List>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  values: getFormValues(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  refreshPercent
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate,
  withStyles(styles)
)(Checklist);
