import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import IconPhone from "@material-ui/icons/Phone";
import IconRingPhone from "@material-ui/icons/RingVolume";
import Button from "@material-ui/core/Button";
import { translate } from "react-admin";

import dataProviderFactory from "../../dataProvider";

export class Call extends Component {
  state = {
    icon: "phone"
  };

  handleClickCallTo = () => {
    const { resource, id } = this.props;
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider("CALL_TO", "pbx/callto", {
          resource,
          id
        }).then(({ data }) => {
          if (data.success) {
            this.setState({
              icon: "ring"
            });
          }
        });
      }
    );
  };

  render() {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleClickCallTo}
      >
        {this.state.icon === "phone" && (
          <span>Позвонить</span>
        )}
        {this.state.icon === "ring" && (
          <span>Гудки</span>
        )}
      </Button>
    );
  }
}

const mapStateToProps = state => ({
  pbx: state.pbx
});

export default compose(
  connect(
    mapStateToProps,
    {}
  ),
  translate
)(Call);
