export const CONTENT_BAR_OPEN = "CONTENT_BAR_OPEN";
export const CONTENT_BAR_CLOSE = "CONTENT_BAR_CLOSE";

export const contentBarOpen = (name, object) => ({
    type: CONTENT_BAR_OPEN,
    payload: {
        name,
        object
    }
});

export const contentBarClose = (name) => ({
    type: CONTENT_BAR_CLOSE,
    payload: {
        name,
        object: null
    }
});