import React, { Component } from "react";
import T from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { parse, stringify } from "query-string";

import { Datagrid, changeListParams } from "react-admin";

import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import get from "lodash/get";

import ColumnIcon from "@material-ui/icons/ViewColumn";
import GroupIcon from "@material-ui/icons/GroupWork";
import IconButton from "@material-ui/core/IconButton";

import SelectionDialog from "./SelectionDialog";
import LocalStorage from "./LocalStorage";

import { findDOMNode } from "react-dom";

const arrayToSelection = values =>
  values.reduce((selection, columnName) => {
    selection[columnName] = true;
    return selection;
  }, {});

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
class CustomizableDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openGroup: false,
      selection: this.getInitialSelection(),
      selectionGroup: this.getInitialSelectionGroup()
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selection: this.getInitialSelection()
    });
  }

  getColumnNames() {
    const { children } = this.props;
    return filter(
      React.Children.map(children, field => get(field, ["props", "source"]))
    );
  }

  getColumnLabels() {
    const { children, childrenForGroup } = this.props;
    if (childrenForGroup) {
      return filter(
        React.Children.map(
          childrenForGroup,
          field =>
            field && {
              source: get(field, ["props", "source"]),
              label:
                get(field, ["props", "label"]) ||
                get(field, ["props", "source"])
            }
        ),
        item => item && item.source
      );
    }

    return filter(
      React.Children.map(
        children,
        field =>
          field && {
            source: get(field, ["props", "source"]),
            label:
              get(field, ["props", "label"]) || get(field, ["props", "source"])
          }
      ),
      item => item && item.source
    );
  }

  getInitialSelection() {
    const { defaultColumns, resource, storage } = this.props;

    const previousSelection = storage.get(resource);

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    // if defaultColumns are set let's return them
    if (!isEmpty(defaultColumns)) {
      return arrayToSelection(defaultColumns);
    }

    // otherwise we fallback on the default behaviour : display all columns
    return arrayToSelection(this.getColumnNames());
  }

  getInitialSelectionGroup() {
    const { location } = this.props;
    const params = parse(location.search);

    if (params.group) {
      const group = JSON.parse(params.group);
      return arrayToSelection(group);
    }

    return arrayToSelection([]);
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    this.props.storage.set(this.props.resource, this.state.selection);
  };

  toggleColumn = columnName => {
    const previousSelection = this.state.selection;
    const selection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName]
    };

    this.setState({ selection }, this.updateStorage);
  };

  changeParam = param => {
    const { location, resource } = this.props;
    const params = parse(location.search);
    const newParams = { ...params };

    if (param) {
      if (!params.group) {
        params.group = [param];
        newParams.group = [param];
      } else {
        const group = JSON.parse(params.group);
        const index = group.indexOf(param);
        if (index !== -1) {
          group.splice(index, 1);
          params.group = group;
          newParams.group = group;
        } else {
          params.group = [...group, param];
          newParams.group = [...group, param];
        }
      }
    }

    if (!newParams.filter) {
      newParams.filter = {};
    } else {
      newParams.filter = JSON.parse(params.filter);
    }

    const query = `?${stringify({
      ...params,
      group: JSON.stringify(params.group)
    })}`;

    this.props.push({
      ...this.props.location,
      search: query
    });

    this.props.changeListParams(resource, newParams);
  };

  toggleColumnGroup = columnName => {
    const previousSelection = this.state.selectionGroup;
    const selectionGroup = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName]
    };
    this.setState({ selectionGroup });
    this.changeParam(columnName);
  };

  handleOpen = event =>
    this.setState({ open: true, anchorEl: findDOMNode(this.button) });
  handleOpenGroup = event =>
    this.setState({ openGroup: true, anchorEl: findDOMNode(this.groupButton) });

  handleClose = () => this.setState({ open: false });
  handleCloseGroup = () => this.setState({ openGroup: false });

  renderChild = child => {
    const source = get(child, ["props", "source"]);
    const { selection } = this.state;

    // Show children without source, or children explicitly visible
    if (!source || selection[source]) {
      return React.cloneElement(child, {});
    }

    return null;
  };

  button = null;
  groupButton = null;

  render() {
    const {
      children,
      defaultColumns,
      push,
      dispatch,
      changeListParams,
      childrenForGroup,
      showGroup,
      showColumns,
      classes,
      bp,
      ...rest
    } = this.props;
    const { selection, selectionGroup, open, openGroup, anchorEl } = this.state;

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 24
          }}
        >
          {showGroup && (
            <IconButton
              ref={node => {
                this.groupButton = node;
              }}
              aria-label="group"
              onClick={this.handleOpenGroup}
              color="primary"
            >
              <GroupIcon />
            </IconButton>
          )}
          {showColumns && <IconButton
            ref={node => {
              this.button = node;
            }}
            aria-label="add"
            onClick={this.handleOpen}
            color="primary"
          >
            <ColumnIcon />
          </IconButton>}
        </div>
        {showColumns && <SelectionDialog
          selection={selection}
          columns={this.getColumnLabels()}
          onColumnClicked={this.toggleColumn}
          open={open}
          handleClose={this.handleClose}
          anchorEl={anchorEl}
        />}
        {showGroup && (
          <SelectionDialog
            selection={selectionGroup}
            columns={this.getColumnLabels()}
            onColumnClicked={this.toggleColumnGroup}
            open={openGroup}
            handleClose={this.handleCloseGroup}
            anchorEl={anchorEl}
          />
        )}
        <Datagrid expand={bp || null} {...rest}>
          {React.Children.map(children, this.renderChild)}
        </Datagrid>
      </div>
    );
  }
}

CustomizableDatagrid.propTypes = {
  defaultColumns: T.arrayOf(T.string),
  childrenForGroup: T.array,
  showGroup: T.bool,
  showColumns: T.bool,
  storage: T.shape({
    get: T.func.isRequired,
    set: T.func.isRequired
  })
};

CustomizableDatagrid.defaultProps = {
  defaultColumns: [],
  showGroup: true,
  showColumns: true,
  storage: LocalStorage
};

const mapStateToProps = state => ({
  location: state.router.location
});

export default compose(
  connect(
    mapStateToProps,
    { push, changeListParams }
  )
)(CustomizableDatagrid);
