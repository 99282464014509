import React, { Fragment } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  translate,
  UPDATE
} from "react-admin";
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import dataProviderFactory from "../dataProvider";
import moment from "moment";
import 'moment/locale/ru';

const styles = theme => ({
  objectParams: {
    display: "flex",
    alignItems: "center",
    minHeight: 110
  },
  objectParamsItem: {
    marginRight: 20,
    textAlign: "center"
  },
  avatar: {
    width: 110,
    height: 110,
    marginTop: 10
  },
  types: {
    textTransform: "uppercase",
    fontSize: 28
  },
  title: {
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: "solid 2px #c4c4c4",
    marginBottom: 12
  },
  infoItem: {
    marginBottom: 12
  }
})

class FormControlLabelStatusObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ((props && props.statusConfirmed && props.statusConfirmed.toString()) || '')
    };
  }

  handleChange = data => event => {
    this.setState({ value: event.target.value });
    const updatedRecord = { ...data, statusConfirmed: event.target.value };
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "objects", {
          id: data.id,
          data: updatedRecord
        })
          .then(({ message }) => {
            // if (message) {}
            // this.setState({ value: event.target.value });
          })
          .catch(e => {
            alert(e, "warning");
          })
      }
    )
  };

  render() {
    return <FormControl component="fieldset" style={{width: "100%", textAlign: "center"}}>
          <Button component="legend" variant="contained" color="primary" >Подтверждение актуальности</Button>
       <RadioGroup aria-label="status" name="status" value={this.state.value} onChange={this.handleChange(this.props)} row>
        <FormControlLabel value="1" control={<Radio color="primary" />} label="Актуально" />
        <FormControlLabel value="2" control={<Radio style={{color: "red"}} />} label="Упало" />
      </RadioGroup>
      
    </FormControl>
    ;
  }
}

class FormControlLabelStatusPoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ((props && props.objposters && props.objposters.statusConfirmed && props.objposters.statusConfirmed.toString()) || '')
    };
  }

  handleChange = data => event => {
    this.setState({ value: event.target.value });
    const updatedRecord = { ...data.objposters, statusConfirmed: event.target.value };
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "posters", {
          id: data.objposters && data.objposters.id,
          data: updatedRecord
        })
          .then(({ message }) => {
            // if (message) {}
            // this.setState({ value: event.target.value });
          })
          .catch(e => {
            alert(e, "warning");
          })
      }
    )
  };

  render() {
    return <FormControl component="fieldset" style={{width: "100%", textAlign: "center"}}>
                <Button component="legend" variant="contained" color="primary" >Подтверждение размещения</Button>
      <RadioGroup aria-label="status" name="status" value={this.state.value} onChange={this.handleChange(this.props)} row>
        <FormControlLabel value="1" control={<Radio color="primary" />} label="Запощено" />
        <FormControlLabel value="2" control={<Radio style={{color: "red"}} />} label="Не запощено" />
      </RadioGroup>
    </FormControl>;
  }
}

const ObjectStats = ({ data, translate, classes }) => {
  return (
    <Fragment>
      <DialogTitle className={classes.title}>Статистика</DialogTitle>
      <DialogContent>
        <Grid item xs={12} className={classes.title}>
          <div className={classes.infoList}>
            <div className={classes.infoItem}>
              ID: {<strong>{data.id}</strong>}
            </div>
            <div className={classes.infoItem}>
              Исходное объявление: {<a href={data.url_parse} target="_blank" rel="noopener noreferrer">{data.url_parse}</a>}
            </div>
            <div className={classes.infoItem}>
              Размещенное объявление: {data.objposters && <a href={data.objposters.url} target="_blank" rel="noopener noreferrer">{data.objposters.url}</a>}
            </div>
            <div className={classes.infoItem}>
              Кол-во попыток постинга: {<strong>{data.attempts}</strong>}
            </div>
            <div className={classes.infoItem}>
              Номер переразмещения: {<strong>{(data.objpostersettings && data.objpostersettings.prolongation) || 1}</strong>}
            </div>
            <div className={classes.infoItem}>
              Дата постинга: {data.objposters && data.objposters.postedAt && <strong>{moment(data.objposters.postedAt).format('DD.MM.YYYY')}</strong>}
            </div>
            <div className={classes.infoItem}>
              Кол-во просмотров: {data.objposters && <strong>{data.objposters.views}</strong>}
            </div>
            <div className={classes.infoItem}>
              Постер ID: {data.objposters && <strong>{data.objposters.id}</strong>}
            </div>
            <div className={classes.infoItem}>
              Постер статус: {data.objposters && <strong>{data.objposters.statusConfirmed}</strong>}
            </div>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            {null !== data.url_parse &&
              <div>
                <iframe title={" "} src={data.url_parse} width="100%" height={400}></iframe>
                <FormControlLabelStatusObject {...data} />
              </div>
            }
            {null === data.url_parse &&
              <div style={{height: '408px', alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%'}}>Нет URL исходного объявления</div>
            }
          </Grid>
          <Grid item xs={6}>
            {data.objposters &&
            (null !== data.objposters.url) &&
              <div>
                <iframe title={" "} src={data.objposters.url} width="100%" height={400}></iframe>
                <FormControlLabelStatusPoster {...data} />
              </div>
            }
            {(null === data.objposters ||
            (null !== data.objposters && null === data.objposters.url)) &&
              <div style={{height: '408px', alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%'}}>Нет URL размещенного объявления</div>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment>
  );
};

export default compose(
  withStyles(styles),
  translate
)(ObjectStats);
