import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClientIcon from '@material-ui/icons/AssignmentInd';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from "query-string";

import CardIcon from './CardIcon';

import moment from "moment";
import 'moment/locale/ru';

const styles = theme => ({
    main: {
        flex: '1',
        marginLeft: '0.5em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        flex: "1",
        paddingRight: 0,
    },
    datetime: {
        flex: "1",
      "& span": {
          flex: "1",
          fontStyle: "italic",
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: "0.9rem"
      }
    }
});

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.createdAt)
  const m2 = moment(record.createdAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const NewCustomers = ({ clients = [], nb, translate, classes, style }) => (
    <div className={classes.main} style={style}>
        <CardIcon Icon={ClientIcon} bgColor="#4caf50" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                {translate('pos.dashboard.new_clients')}
            </Typography>
            <Typography
                variant="headline"
                component="h2"
                className={classes.value}
            >
                {nb}
            </Typography>
            <Divider />
            <List>
                {clients.map(record => (
                    <ListItem
                        button
                        to={`/clients/?${stringify({
                          filter: JSON.stringify({ client_id: [record.id] })
                        })}`}
                        component={Link}
                        key={record.id}
                    >
                        <ListItemText
                            primary={`${record.name}`}
                            className={classes.listItemText}
                        />
                        <ListItemText
                            primary={`${renderDate(record)}`}
                            className={classes.datetime}
                        />
                    </ListItem>
                ))}
            </List>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(NewCustomers);
