import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { change, Field, formValueSelector } from "redux-form";
import { REDUX_FORM_NAME } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
  root: {
    marginTop: 16,
    marginBottom: 8
  }
});

class YandexMapInput extends Component {
  static propTypes = {
    change: PropTypes.func,
    lat: PropTypes.string,
    lon: PropTypes.string
  };

  state = {
    center: [55.174962, 61.416323],
    zoom: 12,
    behaviors: ["default", "scrollZoom"],
    controls: ["fullscreenControl"],
    coords: null
  };

  componentDidMount() {
    const { lat, lon } = this.props;
    const coords = [lat, lon];
    if (lat && lon) {
      this.setState({
        center: coords,
        coords
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { lat, lon } = nextProps;
    const coords = [lat, lon];
    if (lat && lon) {
      this.setState({
        center: !this.state.coords ? coords : this.state.center,
        coords
      });
    }
  }

  handleClick = e => {
    const { change, input, source } = this.props;
    const coords = e.get(source);

    input.onChange(coords);

    change(REDUX_FORM_NAME, "lat", coords[0]);
    change(REDUX_FORM_NAME, "lon", coords[1]);
  };

  render() {
    const coords = this.state.coords;

    const { classes, meta } = this.props;

    return (
      <div className={classes.root}>
        <YMaps
          query={{
            ns: "use-load-option",
            load:
              "control.FullscreenControl,geoObject.addon.balloon,geometryEditor.Polygon"
          }}
        >
          <Map state={this.state} onClick={this.handleClick}>
            {coords && (
              <Placemark
                options={{
                  preset: "islands#darkBlueDotIcon"
                }}
                geometry={coords}
              />
            )}
          </Map>
        </YMaps>
        {meta.submitFailed &&
          meta.error && <FormHelperText error>{meta.error[0]}</FormHelperText>}
      </div>
    );
  }
}

class YandexMap extends Component {
  render() {
    return <Field name={this.props.source} component={YandexMapInput} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  lat: formValueSelector(REDUX_FORM_NAME)(state, "lat"),
  lon: formValueSelector(REDUX_FORM_NAME)(state, "lon")
});

const mapDispatchToProps = {
  change
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(YandexMap);
