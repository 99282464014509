import React, { Component } from "react";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { parse, stringify } from "query-string";
import { push } from "react-router-redux";

class ObjectCard extends Component {
  handleClickObject = id => ev => {
    ev.preventDefault();

    const { location, push } = this.props;
    const params = parse(location.search);

    push(
      `?${stringify({
        ...params,
        show: id
      })}`
    );

    return `?${stringify({
      ...params,
      show: id
    })}`;
  };

  render() {
    const { classes, object } = this.props;

    return (
      <Card className={classes.objectCard} style={{ borderColor: "red" }}>
        <CardContent className={classes.cardContent}>
          <Typography component="h3">
            {object.street && object.street.name.replace("ул ", "")}
            <br />
            {object.house} {object.flat && object.flat}{" "}
            {object.entrance && "п " + object.entrance}
          </Typography>
          <Typography color="textSecondary" gutterBottom align="right">
            <a
              href={`/#/clients?${stringify({
                filter: JSON.stringify({
                  object_id: object.id
                })
              })}`}
            >
              {"Покупатели"}
            </a>
          </Typography>
          <Typography component="h1" align="right">
            {object.types && object.types.name}
          </Typography>
          {object.images && object.images.main ? (
            <Avatar
              alt={object.street && object.street.name.replace("ул ", "")}
              src={object.images.main.thumb.src}
              className={classes.objectAvatar}
              onClick={this.handleClickObject(object.id)}
            />
          ) : (
            <Avatar className={classes.objectAvatar}>{"Нет фото"}</Avatar>
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default compose(
  connect(
    mapStateToProps,
    { push }
  )
)(ObjectCard);
