import React, { Component } from "react";
import moment from "moment";
import 'moment/locale/ru';

import {
  List,
  Datagrid,
  Pagination,
  TextField,
  NumberField,
  FunctionField
} from "react-admin";

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.updatedAt)
  const m2 = moment(record.updatedAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const DistrictsTable = props => (
  <Datagrid {...props} rowClick="edit">
    <TextField source="name" />
    <NumberField source="id_cian" />
    <FunctionField source="updatedAt" style={{ fontStyle: "italic" }} render={renderDate} />
  </Datagrid>
);

const DistrictsPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10]} {...props} />
);

class DistrictsList extends Component {

  render() {
    const props = this.props;
    return (
        <List
          {...props}
          pagination={<DistrictsPagination />}
          perPage={15}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <DistrictsTable />
        </List>
    );
  }
}

export default DistrictsList;
