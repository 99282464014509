import React, { Component } from "react";
import {
  Edit,
  SimpleForm,
  translate,
  CardActions,
  ListButton,
  TextInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import ScriptsTree from "./ScriptsTree";

import { sections } from "../consts";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const ScriptTitle = ({ record }) => record.name || "";

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  root: {
    overflow: "scroll",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  rootInner: {
    background: "rgba(150, 255, 150, 0.03)",
    borderRadius: 4,
    border: "1px solid rgba(139, 255, 139, 0.3)"
  },
  questions: {
    textAlign: "center",
    padding: 8
  },
  questions2: {
    padding: 8,
    textAlign: "center",
    marginLeft: 8,
    marginRight: 8,
    border: "1px dashed rgba(139, 255, 139, 0.3)",
    borderRadius: 4,
    background: "rgba(150, 255, 150, 0.03)"
  },
  question: {
    textAlign: "center",
    border: "2px solid rgba(220, 0, 78, 0.5)",
    marginBottom: 8
  },
  answers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  answer: {
    border: "2px solid rgba(0, 128, 0, 0.55)",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8
  },
  script: {
    border: "2px solid rgba(117, 123, 247, 0.55)",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8
  },
  delete: {
    border: "2px solid red",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
    color: "red"
  },
  textInput: {
    width: 400
  },
  cardContent: {
    width: 400
  },
  hidden: {
    display: "none"
  }
});

const ScriptEditActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

class ScriptEdit extends Component {
  render() {
    const { translate, classes } = this.props;
    return (
      <Edit
        title={<ScriptTitle />}
        {...this.props}
        classes={{ card: classes.card }}
        actions={<ScriptEditActions />}
      >
        <SimpleForm validate={validate(translate)}>
          <TextInput source="name" />
          <SelectInput
            source="section"
            label="resources.tasks.input.section"
            resettable={true}
            choices={sections}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { section } = formData;

              if (section === "objects") {
                return <div><ReferenceInput
                  label="resources.objects.input.categories"
                  source="category_id"
                  reference="categories"
                  resource="categories"
                  {...rest}
                >
                  <SelectInput
                    resettable={true}
                    optionText="name"
                  />
                </ReferenceInput>
                <ScriptsTree catId={formData.category_id} /></div>
              }

              return <ScriptsTree />
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  }
}
export default compose(withStyles(styles), translate)(ScriptEdit);
