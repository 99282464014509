import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { NumberInput } from 'react-admin';

const rangeInputStyles = {};

class RangeInput extends Component {

    state = {
        isFocus: false
    };

    handleBlur = event => {
        this.setState({
            isFocus: false
        });
        setTimeout(() => {
            if(!this.state.isFocus){
                this.props.onBlur(event);
            }
        }, 100); 
    }

    handleFocus = event => {
        this.setState({
            isFocus: true
        });
    }

    render() {
        const { classes, source, label } = this.props;

        return <div>
            <NumberInput
                source={`${source}.from`}
                label={`${label}.from`}
                step={1}
                className={classes.cost}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
            />
            &nbsp;&nbsp;
            <NumberInput
                source={`${source}.to`}
                label={`${label}.to`}
                step={1}
                className={classes.cost}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
            />
        </div>
    }
}

export default withStyles(rangeInputStyles)(RangeInput);