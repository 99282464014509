export const START_BP = "START_BP";
export const END_BP = "END_BP";

export const bpStart = (payload) => ({
  type: START_BP,
  payload: {
    ...payload,
    action: "start"
  }
});

export const bpEnd = () => ({
  type: END_BP,
  payload: {
    action: "end"
  }
});
