import React, { Component, Fragment } from "react";

import keyBy from 'lodash/keyBy'

import {
  List,
  FunctionField,
  Pagination,
  Link,
  ReferenceField,
  EditButton,
  BulkDeleteButton,
  Filter,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  Datagrid,
  Query,
  Loading
} from "react-admin";

import withStyles from "@material-ui/core/styles/withStyles";

import moment from "moment";
import 'moment/locale/ru';

import { push } from "react-router-redux";
import { Route } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { parse, stringify } from "query-string";

import GroupDatagrid from "../components/ra-customizable-datagrid/GroupDatagrid";
import FullNameField from "../components/field/FullNameField";
import LinearProgress from "@material-ui/core/LinearProgress";
import UserQuickPreview from "../users/UserQuickPreview";
import ObjectModal from "../objects/ObjectModal";
import Aside from "./Aside";
// import ChangeStatusButton from "./ChangeStatusButton";
// import Checklist from "../components/list/Checklist";
import Avatar from "@material-ui/core/Avatar";
import BpStartButton from "../components/button/BpStartButton";
import { bpStart } from "../actions/bpActions";

import ChipInput from "../components/input/ChipInput";

import rowStyle from './rowStyle';

import { getNumberWithLabel } from "../utils/formatter";
import { find, get } from "lodash";
import { sections } from "../consts";

const filterStyles = {
};

const styles = theme => ({
  customList: {
    "& thead": {
      display: "none"
    }
  }
});

const renderDeadline = record => {
  let deadline = '';

  if (record.deadline_d) {
    deadline += `${getNumberWithLabel(record.deadline_d, 'день', 'дня', 'дней')} `;
  }

  if (record.deadline_h) {
    deadline += `${getNumberWithLabel(record.deadline_h, 'час', 'часа', 'часов')} `;
  }

  if (record.deadline_m) {
    deadline += `${getNumberWithLabel(record.deadline_m, 'минута', 'минут', 'минут')} `;
  }

  return deadline;
}

const renderStatus = record => {
  const status = record.status;

  if (status === 0) {
    return "В процессе";
  } else {
    return "Завершена";
  }
};

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      task_id: record.id
    })}`;
    return (
      <Link
        to={`/tasks${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        {record.title}
      </Link>
    );
  }
};

const renderTo = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      to: record.id
    })}`;
    return (
      <Link
        to={`/tasks${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderFrom = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      from: record.id
    })}`;
    return (
      <Link
        to={`/tasks${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderClient = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      client: record.id
    })}`;
    return (
      <Link
        to={`/tasks${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderProgress = record => {
  const { percent } = record;

  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>{percent}%</div>
      <LinearProgress value={percent} variant="determinate" />
    </Fragment>
  );
};

const renderSection = record => {
  let name = record.section;

  const section = find(sections, {
    id: name
  });
  if (section) {
    name = section.name;
  }

  return name;
}

// const TaskPanel = ({ id, record, resource }) => {
//   return (
//     <Checklist
//       record={record}
//       label="resources.tasks.input.checklist"
//       source="checklist"
//       isRefresh
//     />
//   );
// };

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.createdAt)
  const m2 = moment(record.createdAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const renderObject = location => record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const params = parse(location.search);
  const query = `?${stringify({
    ...params,
    object: record.id
  })}`;
  if (record.images) {
    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");
    return (
      <Link
        to={`/tasks${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          <Avatar alt={street + " " + house} src={src} />
          <span
            style={{
              marginLeft: 3
            }}
          >
            {street} {house}
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/tasks/?object=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span>
          {street} {house}
        </span>
      </Link>
    );
  }
};

const startTask = (start, resources) => record => {
  let object, client, user;

  if (resources["users"] && resources["users"].data) {
    if (resources["users"].data[record.to_id]) {
      user = resources["users"].data[record.to_id];
    }
  }

  if (resources[record.section] && resources[record.section].data) {
    if (record.section === "clients" && resources[record.section].data[record.client_id]) {
      client = resources[record.section].data[record.client_id];
    }
  }
  if (resources[record.section] && resources[record.section].data) {
    if (record.section === "objects" && resources[record.section].data[record.object_id]) {
      object = resources[record.section].data[record.object_id];
    }
  }

  return <BpStartButton
    label={"Начать задачу"}
    disabled={record.active === 0 || record.status === 1}
    status={record.status}
    handleClick={() => { start({
      bp: record,
      section: record.section,
      user,
      client: client || record.client,
      object: object || record.object
    }); }}
  />
}

export const TaskFilter = withStyles(filterStyles)(
  ({ classes, ...props }) => {
    return (
      <Filter {...props}>
        <ReferenceArrayInput
          source="cat_ids"
          label="resources.objects.input.categories"
          reference="categories"
          allowEmpty={false}
        >
          <ChipInput>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceArrayInput>
        <ChipInput
          source="section"
          label="resources.tasks.input.section"
          isTranslated={false}
        >
          <SelectInput
            choices={sections}
          />
        </ChipInput>
      </Filter>);
  }
);

const CustomList = withStyles(styles)(
  ({ classes, record, ...props }) => {
    const perPage = 50;
    const page = 1;

    return record.parent_id && (
      <Query
        type="GET_LIST"
        resource="tasks"
        payload={{
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
          filter: { id: record.parent_id },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />
          }
          if (error) {
            return <p>ERROR: {error}</p>
          }
          return (
            <>
              <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={{ field: 'id', order: 'DESC' }}
                className={classes.customList}
                expand={<CustomList {...props} />}
                rowStyle={rowStyle}
              >
                <FunctionField source="title" render={renderName(props.location)} />
                <ReferenceField source="to_id" reference="users" linkType={false} allowEmpty {...props} basePath="/">
                  <FunctionField source="to_id" render={renderTo(props.location)} />
                </ReferenceField>
                <ReferenceField source="from_id" reference="users" linkType={false} allowEmpty {...props}  basePath="/">
                  <FunctionField source="from_id" render={renderFrom(props.location)} />
                </ReferenceField>
                <FunctionField source="createdAt" style={{ fontStyle: "italic" }} render={renderDate} />
                <FunctionField source="deadline" render={renderDeadline} />
                <FunctionField source="percent" render={renderProgress} />
              </Datagrid>
            </>
          )
        }}
      </Query>
    )
});

const TasksTable = props => (
  <GroupDatagrid
    expand={<CustomList {...props} />}
    {...props}
    showGroup={true}
    rowStyle={rowStyle}
  >
    <FunctionField render={startTask(props.bpStart, props.resources)} />
    <FunctionField source="title" render={renderName(props.location)} />
    <FunctionField source="section" render={renderSection} />
    <ReferenceField source="to_id" reference="users" linkType={false} allowEmpty>
      <FunctionField source="to_id" render={renderTo(props.location)} />
    </ReferenceField>
    <ReferenceField source="from_id" reference="users" linkType={false} allowEmpty>
      <FunctionField source="from_id" render={renderFrom(props.location)} />
    </ReferenceField>
    <ReferenceField
      source="object_id"
      reference="objects"
      label="resources.tasks.fields.object"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="object_id" render={renderObject(props.location)} />
    </ReferenceField>
    <ReferenceField
      source="client_id"
      reference="clients"
      label="resources.tasks.fields.client"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="client_id" render={renderClient(props.location)} />
    </ReferenceField>
    <FunctionField source="createdAt" style={{ fontStyle: "italic" }} render={renderDate} />
    <FunctionField source="deadline" render={renderDeadline} />
    <FunctionField source="percent" render={renderProgress} />
    <FunctionField source="status" render={renderStatus} />
    <EditButton label="" source="edit" />
  </GroupDatagrid>
);

const TasksPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const TasksBulkActionButtons = props => (
  <Fragment>
    {/* <ChangeStatusButton status={0} {...props} />
    <ChangeStatusButton status={1} {...props} /> */}
    <BulkDeleteButton {...props} />
  </Fragment>
);

class TasksList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.to;
    delete params.from;
    delete params.object;
    delete params.client;
    delete params.task_id;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };

  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          filters={<TaskFilter />}
          pagination={<TasksPagination />}
          perPage={10}
          sort={{ field: "status", order: "ASC" }}
          bulkActionButtons={<TasksBulkActionButtons />}
          filterDefaultValues={{ status: 0 }}
        >
          <TasksTable
            {...props}
          />
        </List>
        <Route path="/tasks">
          {route => {
            const values = parse(route.location.search);
            let id = null;

            if (values.object) {
              const object = values.object;
              return (
                <ObjectModal
                  id={object}
                  open={!!object}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            if(values.to){
              id = values.to;
            }else if(values.from){
              id = values.from;
            } else if (values.client) {
              id = values.client

              return <UserQuickPreview
                id={id}
                open={!!id}
                resource="clients"
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            }

            if (values.task_id) {
              return <Aside
                id={values.task_id}
                handleClose={this.handleClose}
              />
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location,
  resources: state.admin.resources
});

export default compose(
connect(
  mapStateToProps,
  { push, bpStart }
))(TasksList);
