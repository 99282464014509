import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import IconCancel from "@material-ui/icons/Cancel";
import IconSave from "@material-ui/icons/Save";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { FileManager, FileNavigator } from "@opuscapita/react-filemanager";
import connectorNodeV1 from "@opuscapita/react-filemanager-connector-node-v1";
import { FILEMANAGER_URL } from "../../consts";

import { Base64 } from "../../utils";

const apiOptions = {
  ...connectorNodeV1.apiOptions,
  apiRoot: FILEMANAGER_URL,
  locale: "ru"
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  button: {
    marginTop: 16
  }
});

class FileManagerDialog extends Component {
  static propTypes = {
    label: PropTypes.string,
    icon: PropTypes.object,
    onApple: PropTypes.func,
    classes: PropTypes.object
  };

  state = {
    showDialog: false,
    previewFiles: [],
    selectedFiles: []
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = event => {
    this.setState({ showDialog: false });
  };

  handleApple = event => {
    event.stopPropagation();
    if (this.props.onApple) {
      this.props.onApple(this.state.selectedFiles);
    }

    this.setState({ showDialog: false });
  };

  handleSelectionChange = selection => {
    if (selection && selection.length > 0) {
      const selectedFiles = [];
      const previewFiles = [];

      selection.forEach(file => {
        const decodeFile = Base64.decode(file);
        if (decodeFile.match(/.(jpg|jpeg|png|gif)/i)) {
          const file = decodeFile.replace(/\\/g, "/");
          previewFiles.push(file);
          selectedFiles.push(file);
        }
      });

      this.setState({
        previewFiles,
        selectedFiles
      });
    } else {
      this.setState({
        previewFiles: []
      });
    }
  };

  render() {
    const { showDialog, previewFiles } = this.state;
    const { icon, label, classes, customButton } = this.props;

    return (
      <Fragment>
        {customButton ? (
          React.cloneElement(customButton, { onClick: this.handleClick })
        ) : (
          <Button
            onClick={this.handleClick}
            label={label}
            className={classes.button}
          >
            {icon}
          </Button>
        )}
        <Dialog
          scroll="body"
          fullScreen
          open={showDialog}
          onClose={this.handleCloseClick}
          TransitionComponent={Transition}
        >
          <DialogActions>
            <Button label="ra.action.apple" onClick={this.handleApple}>
              <IconSave />
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
          <DialogContent>
            <Grid
              container
              spacing={8}
              style={{ height: "calc(100vh - 124px)" }}
            >
              <Grid item xs={previewFiles.length > 0 ? 9 : 12}>
                <FileManager>
                  <FileNavigator
                    id="filemanager-1"
                    api={connectorNodeV1.api}
                    apiOptions={apiOptions}
                    capabilities={connectorNodeV1.capabilities}
                    initialResourceId={""}
                    listViewLayout={connectorNodeV1.listViewLayout}
                    viewLayoutOptions={{
                      ...connectorNodeV1.viewLayoutOptions,
                      locale: "ru"
                    }}
                    onSelectionChange={this.handleSelectionChange}
                  />
                </FileManager>
              </Grid>
              {previewFiles.length > 0 && (
                <Grid item xs={3}>
                  <GridList cellHeight={250} cols={1}>
                    {previewFiles.map((file, key) => {
                      return (
                        <GridListTile key={key} cols={1}>
                          <img
                            src={FILEMANAGER_URL + file}
                            alt={file}
                            data-object-fit="cover"
                          />
                        </GridListTile>
                      );
                    })}
                  </GridList>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(FileManagerDialog);
