import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GET_ONE, UPDATE, refreshView } from "react-admin";
import Call from "../ipphone/Call";
import dataProviderFactory from "../../dataProvider";

import { bpEnd } from "../../actions/bpActions";

class SaleScriptForm extends Component {
  state = {
    script: null,
    history: [],
    answer: null,
    loading: false,
    status: null,
    script_id: null,
    script_name: null
  };

  componentWillMount() {
    const { bp, changeTitle } = this.props;

    if (bp) {
      const task_id = bp.id;
      const script_id = bp.script_id;

      this.setState({
        task_id,
        script_id,
        script_name: bp.name
      });

      if (changeTitle) {
        changeTitle(bp.title);
      }

      this.fetchScript(task_id, script_id);
    }
  }

  fetchScript = (task_id, script_id) => {
    this.setState({
      loading: true
    });
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(GET_ONE, `sale-scripts/bp/${task_id}/${script_id}`).then(
          ({ data }) => {
            if (data.success) {
              if (data.history) {
                const index = data.history.recent_number;
                const indexes = index.split(".");
                let currIndex = [];
                let question = data.script.question;

                indexes.forEach(item => {
                  currIndex.push(item);
                  if (currIndex.length > 1) {
                    const index = currIndex.join(".");
                    if (question.answers && question.answers[index]) {
                      if (question.answers[index].question) {
                        question = question.answers[index].question;
                      } else {
                        question = question.answers[index];
                      }
                    }
                  }
                });

                this.setState({
                  script_id: data.id,
                  script_name: data.name,
                  history: data.history.recent_script,
                  status: data.history.status
                });

                this.parseScript({ question });
              } else {
                this.setState({
                  script_id: data.id,
                  script_name: data.name
                });
                this.parseScript(data.script);
              }
            }
          }
        );
      }
    );
  }

  saveHistory = () => {
    const { bp, changeTitle, refreshView, location } = this.props;
    if (bp) {
      dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
        dataProvider => {
          dataProvider(UPDATE, "sale-scripts-history/bp", {
            id: this.state.task_id,
            data: {
              sale_scripts_id: this.state.script_id,
              recent_script: this.state.history,
              recent_number: this.state.answer,
              status: this.state.status
            }
          })
          .then(({data}) => {
            if (data && data.complete) {
              refreshView();
            }
            // if (data && data.next_task) {
            //   if (data.next_task.id && data.next_task.script_id) {
            //     this.setState({
            //       task_id: data.next_task.id,
            //       script_id: data.next_task.script_id
            //     });

            //     if (changeTitle) {
            //       changeTitle(data.next_task.title)
            //     }

            //     if (location && location.pathname && location.pathname === "/tasks") {
            //       refreshView()
            //     }

            //     this.fetchScript(data.next_task.id, data.next_task.script_id);
            //   } else {
            //     if (data.complete) {
            //       this.setState({
            //         script: null
            //       })
            //     }
            //   }
            // }
          });
        }
      );
    }
  };

  parseScript = script => {
    if (script) {
      let question = "";
      let answers = {};

      if (script.question) {
        if (script.question.name) {
          question = script.question.name;
        }

        if (script.question.answers) {
          answers = script.question.answers;
        }
      }

      const status =
        script.question && script.question.status
          ? script.question.status
          : null;

      this.setState({
        question,
        answers,
        script,
        loading: false,
        status
      });
    }
  };

  getCallsButtons = () => {
    const { object, client } = this.props;
    const { question } = this.state.script;
    let jsx = [];
      
    if (question.call_to) {
      const call_to = question.call_to;
      if (call_to === 1 && object) {
        const contact_people = object.contact_people

        jsx.push(
          <Grid item key={"call_to_" + call_to} xs="6">
            <Card style={{ height: "100%", marginBottom: "16px" }}>
              <CardContent style={{ padding: "8px" }}>
                <Typography color="textSecondary" component="h3">
                  СОБСТВЕННИК
                </Typography>
                <Typography color="textSecondary" component="h2">
                  {contact_people}
                </Typography>
                <Call resource={"objects"} id={object.id} />
              </CardContent>
            </Card>
          </Grid>
        );
      }

      if (call_to === 2 && client) {
        const f = client.fio || client.name;

        jsx.push(
          <Grid item key={"call_to_" + call_to} xs="6">
            <Card style={{ height: "100%", marginBottom: "16px" }}>
              <CardContent style={{ padding: "8px" }}>
                <Typography color="textSecondary" component="h3">
                  КЛИЕНТ
                </Typography>
                <Typography color="textSecondary" component="h2">
                  {f}
                </Typography>
                <Call resource={"clients"} id={client.id} />
              </CardContent>
            </Card>
          </Grid>
        );
      }
    }

    return jsx;
  };

  getAnswersButtons = () => {
    let answers = this.state.answers;

    let jsx = [];

    for (let answer in answers) {
      
      if (answers[answer]) {
        let color = "primary";
        const name = answers[answer].name;
        const type = answers[answer].type;
        if (type === 1) {
          color = "primary";
        }
        if (type === 2) {
          color = "secondary";
        }

        jsx.push(
          <Grid item key={answer}>
            <Button
              variant="contained"
              color={color}
              onClick={this.handleClickAnswer(answer)}
            >
              {name}
            </Button>
          </Grid>
        );
      }
    }

    return jsx;
  };

  handleClickAnswer = index => () => {
    const script = this.state.script;
    if (
      script.question &&
      script.question.answers &&
      script.question.answers[index]
    ) {
      this.setState({
        history: [
          ...this.state.history,
          {
            question: {
              name: script.question.name,
              answer: {
                index,
                name: script.question.answers[index].name,
                template_id: script.question.answers[index].template_id,
                type: script.question.answers[index].type,
                complete: script.question.answers[index].complete,
              }
            }
          }
        ],
        answer: index
      });

      this.parseScript(script.question.answers[index]);
      this.saveHistory();
    }
  };

  render() {
    const { classes } = this.props;
    const { loading, question, script, script_name } = this.state;

    return (
      script && (
        <div>
          <Card>
            {question && (
              <CardContent className={classes.cardContent}>
                <Typography variant="subheading" className={classes.typography}>
                  Скрипт: {script_name}
                </Typography>
                <p
                  dangerouslySetInnerHTML={{ __html: question || "" }}
                  className={classes.question}
                />
                <Grid
                  container
                  spacing={8}
                  justify="space-between"
                  alignItems="flex-start"
                >
                  {this.getCallsButtons()}
                </Grid>
                <Grid
                  container
                  spacing={8}
                  justify="space-between"
                  alignItems="flex-start"
                >
                  {this.getAnswersButtons()}
                </Grid>
              </CardContent>
            )}
            {loading && <LinearProgress variant="query" />}
          </Card>
          
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

const mapDispatchToProps = {
  bpEnd,
  refreshView
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SaleScriptForm);
