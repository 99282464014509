import React, { Component } from "react";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import { stringify } from "query-string";

export default class OwnerrCard extends Component {
  render() {
    const { classes, contact_people, object_id } = this.props;

    const parts = contact_people.split(" ");
    let fio = contact_people[0];

    if (parts.length > 0) {
      fio = "";
      parts.forEach(item => {
        fio += item[0] ? item[0] : "";
      });
    }

    return (
      <Card style={{ height: "100%" }}>
        <CardContent className={classes.cardContent}>
          <Typography color="textSecondary" component="h3">
            СОБСТВЕННИК
          </Typography>
          <Typography color="textSecondary" component="h2">
            <a
              href={`/#/objects?${stringify({
                filter: JSON.stringify({
                  id: [object_id]
                })
              })}`}
            >
              {contact_people}
            </a>
          </Typography>
          <Avatar aria-label="Recipe" className={classes.customerAvatar}>
            {fio}
          </Avatar>
        </CardContent>
      </Card>
    );
  }
}
