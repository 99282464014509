import React from "react";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import ContentCreate from "@material-ui/icons/Create";
import { Link } from "react-router-dom";
import { linkToRecord } from "ra-core";
import { translate } from "react-admin";
import get from "lodash/get";

const styles = theme => ({
  gridListTile: {
    cursor: "pointer"
  }
});

const getColsForWidth = width => {
  if (width === "xs") return 1;
  if (width === "sm") return 2;
  if (width === "md") return 4;
  if (width === "lg") return 4;
  return 6;
};

const handleRowClick = (id, rowClick) => () => {
  if (id && rowClick) {
    rowClick(id);
  }
};

const ObjectsGrid = translate(
  ({ classes, ids, data, basePath, translate, width, rowClick }) => (
    <div style={{ margin: "1em" }}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
          >
            {ids.map(id => {
              let images = get(data[id], "images");
              if (typeof images === "string") {
                images = JSON.parse(images);
              }
              
              const image = get(images, "main.thumb.src");
              const street = get(data[id], "street.name");
              const house = get(data[id], "house");

              const cost_of_owner = get(data[id], "cost_of_owner");
              const area = get(data[id], "area");
              let price = "";

              if (cost_of_owner && area) {
                price = (
                  <span>
                    <strong>{cost_of_owner}</strong>/{(
                      cost_of_owner / area
                    ).toFixed(2)}
                  </span>
                );
              }

              return (
                <GridListTile
                  key={id}
                  onClick={handleRowClick(id, rowClick)}
                  className={classes.gridListTile}
                >
                  {image && <img src={image} alt={street + " " + house} />}
                  <GridListTileBar
                    className={classes.tileBar}
                    title={price}
                    subtitle={`${street} ${house}`}
                    actionIcon={
                      <IconButton
                        to={linkToRecord(basePath, data[id].id)}
                        className={classes.link}
                        component={Link}
                        onClick={(ev) => {
                          ev.stopPropagation();
                        }}
                      >
                        <ContentCreate />
                      </IconButton>
                    }
                  />
                </GridListTile>
              );
            })}
          </MuiGridList>
        </Grid>
      </Grid>
    </div>
  )
);

ObjectsGrid.defaultProps = {
  data: {},
  ids: []
};

const enhance = compose(
  withWidth(),
  withStyles(styles)
);

export default enhance(ObjectsGrid);
