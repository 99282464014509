import React, { Component } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography
} from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";
import { socket } from "../../consts";

class ChatMessages extends Component {
  state = {
    messages: [],
    loading: false,
    offset: 0,
    current_date: ""
  };

  limit = 10;

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.loading === false;
  }

  componentWillMount() {
    socket.instance.on("room message", this.handleReceiveMessages);
    socket.instance.on("get room messages", this.handleReceiveMessages);
    this.fetchMessages({
      offset: 0,
      limit: this.limit
    });
  }

  componentDidMount() {
    this.messagesListNode.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    socket.instance.off("room message", this.handleReceiveMessages);
    socket.instance.off("get room messages", this.handleReceiveMessages);
    this.messagesListNode.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = ev => {
    if (
      ev.target.scrollTop === 0 &&
      this.state.count > this.state.messages.length
    ) {
      this.fetchMessages({
        limit: this.limit,
        offset: this.state.offset + this.limit
      });
      this.setState(state => (state.offset = state.offset + this.limit));
    }
  };

  scrollToBottom = () => {
    if (this.messagesListNode && this.messagesListNode.scrollHeight) {
      this.messagesListNode.scrollTo(0, this.messagesListNode.scrollHeight);
    }
  };

  fetchMessages = params => {
    this.setState({
      loading: true
    });
    socket.instance.emit("get room messages", {
      object_id: this.props.room,
      ...params
    });
  };

  handleReceiveMessages = data => {
    if (Array.isArray(data.messages)) {
      let messages;

      if (this.state.offset === 0) {
        messages = [...this.state.messages, ...data.messages];
      } else {
        messages = [...data.messages, ...this.state.messages];
      }

      messages = messages.map(item => {
        const datetime = new Date(item.createdAt);
        const format_date = datetime.toLocaleString("ru", {
          day: "numeric",
          month: "long",
          hour: "numeric",
          minute: "numeric"
        });
        if (this.state.current_date !== format_date) {
          item.format_date = format_date;
          this.setState({
            current_date: format_date
          });
          return item;
        } else {
          item.format_date = null;
          return item;
        }
      });

      this.setState({
        messages: messages,
        count: data.count,
        loading: false
      });

      if (this.state.offset === 0) {
        this.scrollToBottom();
      }
    } else {
      const datetime = new Date(data.createdAt);

      const format_date = datetime.toLocaleString("ru", {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric"
      });

      if (this.state.current_date !== format_date) {
        data.format_date = format_date;
        this.setState({
          current_date: format_date
        });
      } else {
        data.format_date = null;
      }

      this.setState(state => ({
        messages: [...state.messages, data],
        loading: false
      }));
      this.scrollToBottom();
    }
  };

  handleChangeMessage = ev => {
    this.setState({
      message: ev.target.value
    });
  };

  handleClickMessageSend = () => {
    if (this.state.message) {
      socket.instance.emit("room message", {
        object_id: this.props.room,
        message: this.state.message,
        name: this.props.name,
        image: this.props.image ? this.props.image.src : ''
      });

      this.setState({
        message: "",
        loading: true
      });
    }
  };

  handleKeyPressMessageSend = ev => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      if (this.state.message) {
        socket.instance.emit("room message", {
          object_id: this.props.room,
          message: this.state.message,
          name: this.props.name,
          image: this.props.image ? this.props.image.src : ''
        });

        this.setState({
          message: ""
        });
      }
    }
  };

  createMessagesRef = node => (this.messagesListNode = node);

  render() {
    const { classes, handleClickBack, room, name, image } = this.props;
    return (
      <Grid item xs={12}>
        <Card className={classes.chatCard}>
          <CardActions>
            <Button size="small" variant="raised" onClick={handleClickBack(room)}>
              Назад
            </Button>
            {image && (
              <Avatar
                alt={name}
                src={image.src || image}
                className={classes.roomAvatar}
              />
            )}
            <Typography component="h3" className={classes.chatTitle}>
              {name}
            </Typography>
          </CardActions>
          <CardContent className={classes.messages}>
            <div
              className={classes.messagesContent}
              ref={this.createMessagesRef}
            >
              {this.state.loading && <LinearProgress variant="query" />}
              {this.state.messages.length > 0 && (
                <List className={classes.messagesList}>
                  {this.state.messages.map((item, key) => {
                    return [
                      item.format_date && (
                        <ListItem alignitems="flex-center" key={key}>
                          <ListItemText
                            secondary={
                              <Typography
                                component="span"
                                className={classes.messagesDate}
                              >
                                {item.format_date}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ),
                      <ListItem
                        key={key + 10}
                        className={classes.messagesListItem}
                      >
                        {item.photo && (
                          <ListItemAvatar>
                            <Avatar
                              alt={item.username}
                              src={item.photo}
                              className={classes.userAvatar}
                            />
                          </ListItemAvatar>
                        )}
                        <ListItemText
                          primary=""
                          secondary={
                            <React.Fragment>
                              {item.username}
                              <br />
                              <Typography
                                component="span"
                                className={classes.inline}
                              >
                                {item.message}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ];
                  })}
                </List>
              )}
            </div>
          </CardContent>
          <CardContent className={classes.sendMessageContent}>
            <TextField
              id="outlined-textarea"
              label="Cообщение"
              placeholder="Введите текст"
              multiline
              fullWidth
              margin="none"
              variant="outlined"
              value={this.state.message || ""}
              onChange={this.handleChangeMessage}
              onKeyPress={this.handleKeyPressMessageSend}
            />
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              size="small"
              variant="raised"
              onClick={this.handleClickMessageSend}
            >
              Отправить
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default ChatMessages;
