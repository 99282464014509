import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { change, formValueSelector } from "redux-form";
import { REDUX_FORM_NAME } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import FileManagerDialog from "../components/media/FileManagerDialog";
import { UPLOADS_URL, FILEMANAGER_URL } from "../consts";

const styles = theme => ({
  avatar: {
    borderRadius: 10,
    width: 256,
    height: 256,
    marginTop: 16,
    cursor: "pointer"
  }
});

class UserPhoto extends Component {
  handleApply = selection => {
    if (selection && selection.length > 0) {
        const { change } = this.props;
        change(REDUX_FORM_NAME, "photo", selection[0]);
      }
  };

  noimage = `${UPLOADS_URL}/no-image.png`;

  render() {
    const { classes } = this.props;
    let photo = this.props.photo;

    if(!/http/.test(photo)){
      photo = FILEMANAGER_URL + photo;
    }

    return (
      <Fragment>
        <FileManagerDialog
          customButton={
            <Avatar src={photo || this.noimage} className={classes.avatar} />
          }
          onApple={this.handleApply}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  photo: formValueSelector(REDUX_FORM_NAME)(state, "photo")
});

const mapDispatchToProps = {
  change
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserPhoto);
