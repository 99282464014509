import green from '@material-ui/core/colors/green';
// import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import moment from "moment";

const rowStyle = (record, index, defaultStyle = {}) => {
  let status = record.status;
  if (record.status === 0) {
    let theDate = moment(record.createdAt);
    const curDate = moment();

    if (record.deadline_d) {
      theDate = theDate.add(record.deadline_d, 'days');
    }

    if (record.deadline_h) {
      theDate = theDate.add(record.deadline_h, 'hours');
    }

    if (record.deadline_m) {
      theDate = theDate.add(record.deadline_m, "minutes");
    }

    if (record.active === 0) {
      status = -1;
    }

    if (curDate.diff(theDate) > 0) {
      status = -2;
    }
  }


//   deadline_d: 2
// deadline_h: 2
// deadline_m: 30

  if (status === 0)
      return {
          ...defaultStyle,
          borderLeftColor: green[500],
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
          backgroundColor: green[100]
      };
  if (status === 1)
      return {
          ...defaultStyle,
          borderLeftColor: grey[500],
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
          textDecoration: 'line-through',
          backgroundColor: grey[100]
      };
  if (status === -1)
    return {
        ...defaultStyle,
        borderLeftColor: grey[500],
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        backgroundColor: grey[100]
    };
  if (status === -2)
      return {
          ...defaultStyle,
          borderLeftColor: red[600],
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
          backgroundColor: red[200]
      };
  return defaultStyle;
};

export default rowStyle;