import React, { Component } from "react";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import { stringify } from "query-string";

export default class CustomerCard extends Component {
  render() {
    const { classes, client } = this.props;
    const f = client.fio || client.name;

    const parts = f.split(" ");
    let fio = f[0];

    if (parts.length > 0) {
      fio = "";
      parts.forEach(item => {
        fio += item[0] ? item[0] : ""
      });
    }

    return (
      <Card style={{ height: "100%" }}>
        <CardContent className={classes.cardContent}>
          <Typography color="textSecondary" component="h3">
            КЛИЕНТ
          </Typography>
          <Typography color="textSecondary" component="h2">
            <a
              href={`/#/clients?${stringify({
                filter: JSON.stringify({
                  client_id: [client.id]
                })
              })}`}
            >
              {f}
            </a>
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            <a
              href={`/#/objects?${stringify({
                filter: JSON.stringify({
                  client_id: client.id
                })
              })}`}
            >
              Объекты клиента
            </a>
          </Typography>
          <Avatar aria-label="Recipe" className={classes.customerAvatar}>
            {fio}
          </Avatar>
        </CardContent>
      </Card>
    );
  }
}
