import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  List,
  Pagination,
  TextField,
  FunctionField,
  ReferenceField,
  DateField,
  Link,
  TextInput,
  Filter
} from "react-admin";

import Avatar from "@material-ui/core/Avatar";

import GroupDatagrid from "../../components/ra-customizable-datagrid/GroupDatagrid";

import { parse, stringify } from "query-string";
import { push } from "react-router-redux";
import { Route } from "react-router";
import { get } from "lodash";

import ObjectModal from "../../objects/ObjectModal";

import FullNameField from "../../components/field/FullNameField";
import UserQuickPreview from "../../users/UserQuickPreview";

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id
    })}`;
    return (
      <Link
        to={`/pbx/phones${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderObject = location => record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const params = parse(location.search);
  const query = `?${stringify({
    ...params,
    object: record.id
  })}`;
  if (record.images) {
    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");
    return (
      <Link
        to={`/pbx/phones${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          <Avatar alt={street + " " + house} src={src} />
          <span
            style={{
              marginLeft: 3
            }}
          >
            {street} {house}
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/pbx/phones/?object=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span>
          {street} {house}
        </span>
      </Link>
    );
  }
};

const PbxPhonesTable = props => (
  <GroupDatagrid {...props} rowClick={null}>
    <DateField source="createdAt" style={{ fontStyle: "italic" }}  />
    <ReferenceField source="user_id" reference="users" linkType={false} allowEmpty>
      <FunctionField source="user" render={renderName(props.location)} />
    </ReferenceField>
    <ReferenceField
      source="object_id"
      reference="objects"
      label="resources.tasks.fields.object"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="object_id" render={renderObject(props.location)} />
    </ReferenceField>
    <TextField source="phone" />
  </GroupDatagrid>
);

const PbxPhonesPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

const PbxPhonesFilter = props => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

class PbxPhonesList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.user;
    delete params.object;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };
  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          filters={<PbxPhonesFilter />}
          pagination={<PbxPhonesPagination />}
          perPage={25}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <PbxPhonesTable location={props.location} />
        </List>
        <Route path="/pbx/phones">
          {route => {
            const values = parse(route.location.search);
            let id = null;

            if (values.object) {
              const object = values.object;
              return (
                <ObjectModal
                  id={object}
                  open={!!object}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            if (values.user) {
              id = values.user;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(PbxPhonesList);
