import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { REDUX_FORM_NAME } from "react-admin";
import { change, formValueSelector } from "redux-form";

const styles = theme => ({
  remove: {
    display: "flex",
    alignItems: "center"
  }
});

const Remove = withStyles(styles)(
  ({ children, classes, onClick, disabled }) => {
    return (
      <div className={classes.remove}>
        {children}
        <IconButton onClick={onClick} disabled={disabled}>
          <RemoveIcon />
        </IconButton>
      </div>
    );
  }
);

export class DynamicGroupInput extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    group_inputs: PropTypes.object,
    source: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    const { children } = props;

    if (children) {
      if (Array.isArray(children)) {
        const hidden = [];
        const childrens = children.map((item, key) => {
          if (key > 0) {
            hidden.push(key);
          }

          return {
            key,
            isShow: key === 0
          };
        });

        this.state = {
          childrens,
          hidden
        };
      } else {
        this.state = {
          childrens: [
            {
              key: 0,
              isShow: true
            }
          ],
          hidden: []
        };
      }
    }
  }

  handleRemoveClick = k => () => {
    const { hidden, childrens } = this.state;
    const { change, source } = this.props;

    hidden.unshift(k);
    childrens[k].isShow = false;

    this.setState({
      childrens,
      hidden
    });

    change(REDUX_FORM_NAME, "group_inputs", {
      [source]: {
        childrens,
        hidden
      }
    });
  };

  handleAddClick = () => {
    const { hidden, childrens } = this.state;
    const { change, source } = this.props;

    if (hidden.length > 0) {
      const last = hidden.shift();

      childrens[last].isShow = true;

      this.setState({
        childrens,
        hidden
      });

      change(REDUX_FORM_NAME, "group_inputs", {
        [source]: {
          childrens,
          hidden
        }
      });
    }
  };

  render() {
    let { childrens, hidden } = this.state;
    const { children, group_inputs, source } = this.props;

    if (group_inputs && group_inputs[source]) {
      childrens = group_inputs[source].childrens;
      hidden = group_inputs[source].hidden;
    }

    return (
      <Fragment>
        {childrens.map((child, key) => {
          return key > 0 ? (
            child.isShow === true && (
              <Remove
                onClick={this.handleRemoveClick(child.key)}
                key={child.key}
              >
                {children[child.key]}
              </Remove>
            )
          ) : (
            <Remove
              onClick={this.handleRemoveClick(child.key)}
              key={child.key}
              disabled
            >
              {children[child.key]}
            </Remove>
          );
        })}
        {hidden.length > 0 && (
          <div>
            <IconButton
              size="small"
              color="secondary"
              aria-label="Add"
              onClick={this.handleAddClick}
            >
              <AddIcon />
            </IconButton>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  group_inputs: formValueSelector(REDUX_FORM_NAME)(state, "group_inputs")
});

const mapDispatchToProps = {
  change
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);

export default enhance(DynamicGroupInput);
