import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import ResizeObserver from "resize-observer-polyfill";
import MobileStepper from "@material-ui/core/MobileStepper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const styles = theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  image: {
    objectFit: "cover",
    height: 180,
    width: 145,
    cursor: "pointer",
    margin: 2,
    zIndex: 1
  },
  mobileStepper: {
    position: "absolute",
    background: "transparent",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 48,
    margin: "auto"
  },
  button: {
    zIndex: 2
  },
  arrowIcon: {
    fontSize: 40
  },
  arrowNext: {
      position: "absolute",
      top: 0,
      transform: "rotate(90deg)"
  },
  arrowPrev: {
    position: "absolute",
    bottom: 0,
    transform: "rotate(90deg)"
  }
});

class GalleryVerticalSlider extends Component {
  static propTypes = {
    photos: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    classes: PropTypes.object.isRequired
  };

  state = {
    containerWidth: 0,
    activeStep: 0,
    slides: [],
    count: 0
  };

  componentDidMount() {
    this.animationFrameID = null;
    this.observer = new ResizeObserver(entries => {
      const newWidth = entries[0].contentRect.width;
      if (this.state.containerWidth !== newWidth) {
        this.animationFrameID = window.requestAnimationFrame(() => {
          this.setState({ containerWidth: Math.floor(newWidth) });
        });
      }
    });
    this.observer.observe(this._gallery);

    const { slides, count } = this.generateSlides(
      this.props.photos,
      this.props.classes
    );

    this.setState({ slides, count });
  }

  componentWillUnmount() {
    this.observer.disconnect();
    window.cancelAnimationFrame(this.animationFrameID);
  }

  handleClick = (photo, index) => event => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event, { photo, index });
    }
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  };

  generateSlides = (photos, classes) => {
    let slides = [];
    let count = 0;
    let counter = 0;

    photos.forEach((photo, key) => {
      if (!slides[count]) {
        slides[count] = [];
      }

      slides[count][counter] = (
        <img
          src={photo.src}
          alt={photo.alt}
          onClick={this.handleClick(photo, key)}
          key={key}
          className={classes.image}
        />
      );

      counter++;

      if (key % 2) {
        counter = 0;
        count++;
      }
    });

    return { slides, count };
  };

  createGalleryRef = c => {
    this._gallery = c;
  };

  render() {
    const { classes } = this.props;
    const { containerWidth, slides, count } = this.state;
    if (!containerWidth) return <div ref={this.createGalleryRef}>&nbsp;</div>;

    return (
      <div className={classes.root} style={{ width: containerWidth }}>
        {slides[this.state.activeStep]}
        {count > 0 && (
          <MobileStepper
            orientation="vertical"
            steps={count}
            position="static"
            activeStep={this.state.activeStep}
            className={classes.mobileStepper}
            variant="text"
            nextButton={
              <IconButton
                color="primary"
                onClick={this.handleNext}
                disabled={this.state.activeStep === count}
                className={classnames([classes.button, classes.arrowPrev])}
              >
                <KeyboardArrowRight className={classes.arrowIcon} />
              </IconButton>
            }
            backButton={
              <IconButton
                color="primary"
                onClick={this.handleBack}
                disabled={this.state.activeStep === 0}
                className={classnames([classes.button, classes.arrowNext])}
              >
                <KeyboardArrowLeft className={classes.arrowIcon} />
              </IconButton>
            }
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(GalleryVerticalSlider);
