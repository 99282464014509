import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  List,
  Pagination,
  FunctionField,
  ReferenceField,
  DateField,
  Link
} from "react-admin";

import GroupDatagrid from "../../components/ra-customizable-datagrid/GroupDatagrid";

import { parse, stringify } from "query-string";
import { push } from "react-router-redux";
import { Route } from "react-router";

import FullNameField from "../../components/field/FullNameField";
import UserQuickPreview from "../../users/UserQuickPreview";

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id
    })}`;
    return (
      <Link
        to={`/pbx/bl${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderPhones = record => {
  return (
    <span>
      {record.phonefrom} - {record.phoneto}
    </span>
  );
};

const PBXBlTable = props => (
  <GroupDatagrid {...props} rowClick={null}>
    <DateField source="createdAt" style={{ fontStyle: "italic" }} />
    <ReferenceField source="user_id" reference="users" linkType={false}>
      <FunctionField source="user" render={renderName(props.location)} />
    </ReferenceField>
    <FunctionField source="phones" render={renderPhones} />
  </GroupDatagrid>
);

const PBXBlPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

class PBXBlList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.user;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };
  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<PBXBlPagination />}
          perPage={25}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <PBXBlTable location={props.location} />
        </List>
        <Route path="/pbx/bl">
          {route => {
            const values = parse(route.location.search);
            let id = null;

            if (values.user) {
              id = values.user;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(PBXBlList);
