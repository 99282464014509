import React, { Component } from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {
  Button,
  SimpleShowLayout,
  TextField,
  FunctionField,
  ImageField,
  EmailField
} from "react-admin";

import Call from "../components/ipphone/Call";

const styles = theme => ({
  imageField: {
    maxWidth: 300,
    textAlign: "center"
  },
  textField: {
    display: "inline-flex",
    marginRight: 3
  }
});

const numRender = record => {
  if (
    record["pbx-manager"] &&
    record["pbx-manager"][0] &&
    record["pbx-manager"][0].id
  ) {
    return (
      <span>
        <strong>{record["pbx-manager"][0].id}</strong>
      </span>
    );
  }
};

const UserPreviewView = ({ classes, ...props }) => {
  const { resource, id, permissions } = props;
  const record = { ...props.record };

  const { name, photo, last_name, middle_name, email, phone, fio } = record;

  return (
    <SimpleShowLayout {...props} className={classes.root}>
      {photo && (
        <ImageField
          source="photo"
          title="last_name"
          className={classes.imageField}
        />
      )}
      {fio && (
        <TextField source="fio" label="" className={classes.textField} />
      )}
      {last_name && (
        <TextField source="last_name" label="" className={classes.textField} />
      )}
      {name && (
        <TextField source="name" label="" className={classes.textField} />
      )}
      {middle_name && (
        <TextField
          source="middle_name"
          label=""
          className={classes.textField}
        />
      )}
      {email && <EmailField source="email" label="" />}
      {phone && (
        <TextField source="phone" label="" className={classes.textField} />
      )}
      <FunctionField
        source="pbx-manager"
        label=""
        render={numRender}
        className={classes.textField}
      />
      {permissions && permissions.roles && permissions.roles.find(role => role.role === 'admin') && (
        <Call resource={resource} id={id} />
      )}
    </SimpleShowLayout>
  );
};

const mapStateToProps = (state, props) => ({
  record: state.admin.resources[props.resource]
    ? state.admin.resources[props.resource].data[props.id]
    : null,
  version: state.admin.ui.viewVersion
});

const UserPreview = connect(
  mapStateToProps,
  {}
)(withStyles(styles)(UserPreviewView));

class UserQuickPreview extends Component {
  state = { showPanel: false };

  render() {
    const { id, open, resource, handleClose } = this.props;

    return (
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <div>
          <Button label="ra.action.close" onClick={handleClose}>
            <IconKeyboardArrowRight />
          </Button>
        </div>

        <UserPreview
          id={id}
          basePath={resource ? "/" + resource : "/users"}
          resource={resource || "users"}
          permissions={this.props.permissions}
        />
      </Drawer>
    );
  }
}

export default connect()(UserQuickPreview);
