import React, { Fragment, Component } from "react";

import {
  List,
  FunctionField,
  ReferenceField,
  Pagination,
  ReferenceInput,
  AutocompleteArrayInput,
  Link,
  Filter
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import ChipInput from "../components/input/ChipInput";

import moment from "moment";
import 'moment/locale/ru';

import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { Route } from "react-router";
import { parse, stringify } from "query-string";

import CustomizableDatagrid from "../components/ra-customizable-datagrid";
import FullNameField from "../components/field/FullNameField";
import UserQuickPreview from "../users/UserQuickPreview";

import dataProviderFactory from "../dataProvider";

const styles = theme => ({});

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id,
      resource: "contractors"
    })}`;
    return (
      <Link
        to={`/contractors${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.date_contact)
  const m2 = moment(record.date_contact).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const renderUserName = location => record => {
  const params = parse(location.search);

  const query = `?${stringify({
    ...params,
    user: record.id
  })}`;

  return (
    <Link
      to={`/contractors${query}`}
      onClick={ev => {
        ev.stopPropagation();
      }}
    >
      <FullNameField record={record} size={32} />
    </Link>
  );
};

const renderDistricts = record => {
  if (record.districts && record.districts.length > 0) {
    const districts = record.districts;
    let list = "";
    districts.forEach((item, key) => {
      if (key === districts.length - 1) {
        list += item.district.name;
      } else {
        list += item.district.name + ", ";
      }
    });

    return list;
  }

  return "";
};

const renderUser = record => {
  if (record.developers && record.developers.length > 0) {
    const developers = record.developers;
    let list = "";
    developers.forEach((item, key) => {
      if (key === developers.length - 1) {
        list += item.developer.name;
      } else {
        list += item.developer.name + ", ";
      }
    });

    return list;
  }

  return "";
};

class ContractorPanel extends Component {
  state = {
    count: 0
  };
  componentWillMount() {
    const { id } = this.props;

    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider("GET_COUNT", "objects/count", {
          filter: { contractor_id: id }
        }).then(({ count }) => {
          const filter = stringify({
            filter: JSON.stringify({
              contractor_id: [parseInt(id)]
            })
          });

          this.setState({ count, filter });
        });
      }
    );
  }
  render() {
    const { translate } = this.props;
    return (
      <Fragment>
        <span>
          {translate("Number of objects:")} {this.state.count}
        </span>{" "}
        {this.state.count > 0 ? (
          <span>
            <Link to={`/objects?${this.state.filter}`} target="_blank">
              {translate("Go to")}
            </Link>
            {" | "}
            <Link to="/objects" target="_blank">
              {translate("Add")}
            </Link>
          </span>
        ) : (
          <Link to="/objects" target="_blank">
            {translate("Add")}
          </Link>
        )}
      </Fragment>
    );
  }
}

const ContractorsTable = props => (
  <CustomizableDatagrid
    {...props}
    rowClick="edit"
    showGroup={false}
    expand={
      <ContractorPanel location={props.location} translate={props.translate} />
    }
  >
    <FunctionField source="date_contact" style={{ fontStyle: "italic" }} render={renderDate} />

    <FunctionField source="name" render={renderName(props.location)} label="resources.contractors.fields.name" />
    <ReferenceField
      source="user_id"
      reference="users"
      label="resources.contractors.fields.manager"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="user_id" render={renderUserName(props.location)} />
    </ReferenceField>
    <FunctionField source="district" render={renderDistricts} />
    <FunctionField source="developer" render={renderUser} />
  </CustomizableDatagrid>
);

const ContractorsPagination = props => (
  <Pagination rowsPerPageOptions={[5, 15, 30, 50]} {...props} />
);

const ContractorsFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="district_id"
      label="resources.contractors.input.districts"
      reference="districts"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput />
      </ChipInput>
    </ReferenceInput>
    <ReferenceInput
      source="developer_id"
      label="resources.contractors.input.developers"
      reference="developers"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput />
      </ChipInput>
    </ReferenceInput>
    <ReferenceInput
      source="contractor_id"
      label="resources.contractors.input.contractors"
      reference="contractors"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput />
      </ChipInput>
    </ReferenceInput>
  </Filter>
);

class ContractorsList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);

    delete params.user;
    delete params.show;
    delete params.resource;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };

  render() {
    const props = this.props;
    const { location } = props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<ContractorsPagination />}
          perPage={15}
          sort={{ field: "createdAt", order: "DESC" }}
          filters={<ContractorsFilter />}
        >
          <ContractorsTable location={location} />
        </List>
        <Route path="/contractors">
          {route => {
            const values = parse(route.location.search);
            let id = null;
            let resource = null;

            if (values.user) {
              id = values.user;
            }

            if (values.resource) {
              resource = values.resource;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                resource={resource}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default compose(
  connect(
    mapStateToProps,
    { push }
  ),
  withStyles(styles)
)(ContractorsList);