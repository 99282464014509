import React, { Component } from "react";

import { List, TextField, Pagination } from "react-admin";

import CustomizableDatagrid from "../components/ra-customizable-datagrid";

const RolesTable = props => (
  <CustomizableDatagrid {...props} rowClick="edit" showGroup={false}>
    <TextField source="name" label="resources.roles.fields.role_title"/>
    <TextField source="slug" />
  </CustomizableDatagrid>
);

const RolesPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10]} {...props} />
);

class RolesList extends Component {
  render() {
    const props = this.props;
    return (
      <List
        {...props}
        pagination={<RolesPagination />}
        perPage={5}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <RolesTable />
      </List>
    );
  }
}

export default RolesList;
