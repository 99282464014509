import React, { Fragment, Component } from "react";

import moment from "moment";
import 'moment/locale/ru';

import {
  List,
  ReferenceField,
  FunctionField,
  Pagination,
  Filter,
  AutocompleteArrayInput,
  ReferenceInput,
  SelectInput,
  BulkDeleteButton
} from "react-admin";

import { Link } from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Route } from "react-router";
import { parse, stringify } from "query-string";

import TasksPanel from "../components/bp/TasksPanel";

import ConfirmButton from "./ConfirmButton";

import GroupDatagrid from "../components/ra-customizable-datagrid/GroupDatagrid";
import FullNameField from "../components/field/FullNameField";
import ChipInput from "../components/input/ChipInput";
import UserQuickPreview from "../users/UserQuickPreview";
import ObjectModal from "../objects/ObjectModal";
import Avatar from "@material-ui/core/Avatar";
import dataProviderFactory from "../dataProvider";
import get from "lodash/get";

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id
    })}`;
    return (
      <Link
        to={`/clients${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderClientName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id,
      resource: "clients"
    })}`;
    return (
      <Link
        to={`/clients${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderObject = location => record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const params = parse(location.search);
  const query = `?${stringify({
    ...params,
    object: record.id
  })}`;
  if (record.images) {
    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");
    return (
      <Link
        to={`/clients${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          <Avatar alt={street + " " + house} src={src} />
          <span
            style={{
              marginLeft: 3
            }}
          >
            {street} {house}
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/clients/?object=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span>
          {street} {house}
        </span>
      </Link>
    );
  }
};

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.last_contact)
  const m2 = moment(record.last_contact).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

class ClientsPanel extends Component {
  state = {
    count: 0
  };
  componentWillMount() {
    const { id } = this.props;

    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider("GET_COUNT", "objects/count", {
          filter: { client_id: id }
        }).then(({ count }) => {
          const _id = [parseInt(id)];
          const filter = stringify({
            filter: JSON.stringify({
              client_id: _id,
              _client_id: _id
            })
          });

          const filterAdd = stringify({
            filter: JSON.stringify({
              _client_id: _id
            })
          });

          this.setState({ count, filter, filterAdd });
        });
      }
    );
  }
  render() {
    const { translate, id } = this.props;
    return (
      <Fragment>
        <div style={{padding: "8px 16px"}}>
          <span>
            {translate("Number of objects:")} {this.state.count}
          </span>{" "}
          {this.state.count > 0 ? (
            <span>
              <Link to={`/objects?${this.state.filter}`}>
                {translate("Go to")}
              </Link>
              {" | "}
              <Link to={`/objects?${this.state.filterAdd}`}>
                {translate("Add")}
              </Link>
            </span>
          ) : (
            <Link to={`/objects?${this.state.filterAdd}`}>
              {translate("Add")}
            </Link>
          )}
        </div>
      </Fragment>
    );
  }
}

class Panel extends Component {
  render () {
    return <Fragment>
      <ClientsPanel
        location={this.props.location}
        translate={this.props.translate}
        id={this.props.id}
      />
      <TasksPanel {...this.props} />
    </Fragment>
  }
}

const ClientsBulkActionButtons = props => (
  <Fragment>
    <ConfirmButton {...props}/>
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ClientsTable = props => (
  <GroupDatagrid
    {...props}
    rowClick="edit"
    showGroup={true}
    bp={
      <Panel {...props} />
    }
  >
    <FunctionField source="last_contact" style={{ fontStyle: "italic" }} render={renderDate} />
    <FunctionField source="name" render={renderClientName(props.location)} />
    <ReferenceField
      source="user_id"
      reference="users"
      label="resources.clients.fields.manager"
      allowEmpty
      linkType={false}
    >
      <FunctionField source="user_id" render={renderName(props.location)} />
    </ReferenceField>
    <ReferenceField
      source="object_id"
      reference="objects"
      label="resources.clients.fields.object"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="object_id" render={renderObject(props.location)} />
    </ReferenceField>
  </GroupDatagrid>
);

const renderUserOption = choice => {
  return `${choice.name !== null ? choice.name : ""} ${
    choice.last_name !== null ? choice.last_name : ""
  }`;
};

const ClientsFilter = props => (  
  <Filter {...props}>
    <ReferenceInput
      source="client_id"
      label="resources.objects.input.clients"
      reference="clients"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput />
      </ChipInput>
    </ReferenceInput>
    <ReferenceInput
      source="user_id"
      label="resources.objects.input.manager"
      reference="users"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput optionText={renderUserOption} />
      </ChipInput>
    </ReferenceInput>
    <ChipInput source="spam" label="Спам">
      <SelectInput
        source="spam"
        choices={[
          { id: "спам", name: "спам" },
          { id: "не спам", name: "не спам" }
        ]}
      />
    </ChipInput>
  </Filter>
);

const ClientsPagination = props => (
  <Pagination rowsPerPageOptions={[5, 15, 30, 50]} {...props} />
);

class ClientsList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.user;
    delete params.resource;
    delete params.object;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };

  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<ClientsPagination />}
          perPage={15}
          sort={{ field: "last_contact", order: "DESC" }}
          filterDefaultValues={{ spam: "не спам" }}
          filters={<ClientsFilter />}
          bulkActionButtons={<ClientsBulkActionButtons />}
        >
          <ClientsTable location={props.location} />
        </List>
        <Route path="/clients">
          {route => {
            const values = parse(route.location.search);
            let id = null;
            let resource = null;

            if (values.object) {
              const object = values.object;
              return (
                <ObjectModal
                  id={object}
                  open={!!object}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            if (values.user) {
              id = values.user;
            }

            if (values.resource) {
              resource = values.resource;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                resource={resource}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(ClientsList);
