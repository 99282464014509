import React, { Fragment, Component } from "react";
import moment from "moment";
import 'moment/locale/ru';
import {
  EditButton,
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  TextField,
  FunctionField,
  NumberField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Pagination,
  Link,
  ReferenceField,
  SelectInput,
  BulkDeleteButton,
  CreateButton,
  ExportButton,
  UPDATE,
  // showNotification,
} from "react-admin";

// import Button from '@material-ui/core/Button';
import CardActions from "@material-ui/core/CardActions";

import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { Route } from "react-router";
import { parse, stringify } from "query-string";

import Avatar from "@material-ui/core/Avatar";
import GroupDatagrid from "../components/ra-customizable-datagrid/GroupDatagrid";

import get from "lodash/get";
import withStyles from "@material-ui/core/styles/withStyles";
import ObjectsGrid from "./ObjectsGrid";
import MapAndGrid from "./MapAndGrid";

import ViewMode from "../components/layout/ViewMode";
import RangeInput from "../components/input/RangeInput";
import ObjectsToolbar from "./ObjectsToolbar";
import ObjectModal from "./ObjectModal";
import ChipInput from "../components/input/ChipInput";
import FullNameField from "../components/field/FullNameField";
import UserQuickPreview from "../users/UserQuickPreview";
import TransferObjectsButton from "./TransferObjectsButton";
import SoldOutObjectButton from "./SoldOutObjectButton";
import AssignPhone from "./AssignPhone";
import SavedFilterButton from "./SavedFilterButton";
import SaveFilterButton from "./SaveFilterButton";
import PostingButton from "./PostingButton";
import dataProviderFactory from "../dataProvider";
import ObjectStatuses from "./ObjectStatuses";
import RestoreObjectButton from "./RestoreObjectButton";

import ChatButton from "../components/button/ChatButton";

import TasksPanel from "../components/bp/TasksPanel";

import rowStyle from './rowStyle';

const filterStyles = {
  cost: { width: 100 }
};

const styles = theme => ({
  
});

const svgStyles = {
  fil3: {fill: '#45C045'},
  fil4: {fill: '#6AABF8'},
  fil1: {fill: '#FEFEFE'},
  fil2: {fill: '#FF3F52'},
  fil0: {fill: '#FFA917'},
  fil5: {fill: '#A0A0A0'},
}

const renderAddress = record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const address_parse = get(record, "address_parse");

  if (street) {
    return (
      <span>
        <strong>{street}</strong> {house}
      </span>
    );
  } else {
    return (
      <span>
        {address_parse}
      </span>
    );
  }
};

const renderFloor = record => {
  const floor = get(record, "floor");
  const floor_house = get(record, "floor_house");

  if (floor && floor_house) {
    return (
      <span>
        {floor}/{floor_house}
      </span>
    );
  }
};

const renderPrice = record => {
  const cost_of_owner = get(record, "cost_of_owner");
  const area = get(record, "area");

  if (cost_of_owner && area) {
    return (
      <span>
        <strong>{cost_of_owner}</strong>/{(cost_of_owner / area).toFixed(2)}
      </span>
    );
  }
};

const renderPriceCmi = record => {
  const cost_of_cmi = get(record, "cost_of_cmi");
  const area = get(record, "area");

  if (cost_of_cmi && area) {
    return (
      <span>
        <strong>{cost_of_cmi}</strong>/{(cost_of_cmi / area).toFixed(2)}
      </span>
    );
  }
};

const renderImage = classes => record => {
  if (record.images) {
    const street = get(record, "street.name");
    const house = get(record, "house");

    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");

    let status = ObjectStatuses(record);

    let statusIcon = (
      // Продано
      1 === status &&
      <g>
        <circle style={svgStyles.fil5} cx={25} cy={25} r={25}/>
        <polygon style={svgStyles.fil1} points={"32,15 25,21 18,15 15,18 21,25 15,32 18,35 25,29 32,35 35,32 29,25 35,18 "}/>
      </g>
    ) || (
      // Аванс / задаток (под авансом 3 / снято 4)
      2 === status &&
      <g>
        <path style={svgStyles.fil0} d={"M25 0c-14,0 -25,11 -25,25 0,14 11,25 25,25 14,0 25,-11 25,-25 0,-14 -11,-25 -25,-25z"}/>
        <path style={svgStyles.fil1} d={"M16 35l6 0 0 -20 -6 0 0 20zm12 -20l0 20 6 0 0 -20 -6 0z"}/>
      </g>
    ) || (
      // Отпраавлено на постинг
      3 === status &&
      <g>
        <path style={svgStyles.fil0} d={"M25 0c-14,0 -25,11 -25,25 0,14 11,25 25,25 14,0 25,-11 25,-25 0,-14 -11,-25 -25,-25z"}/>
        <polygon style={svgStyles.fil1} points={"35,17 22,30 15,23 12,26 22,36 38,20 "}/>
      </g>
    ) || (
      // Запощено
      4 === status &&
      <g>
        <circle style={svgStyles.fil1} cx={25} cy={25} r={25}/>
        <polygon style={svgStyles.fil3} points={"20,37 7,25 11,21 20,30 39,11 42,15 20,37 "}/>
        <path style={svgStyles.fil3} d={"M25 0c-14,0 -25,11 -25,25 0,14 11,25 25,25 14,0 25,-11 25,-25 0,-14 -11,-25 -25,-25z"}/>
        <polygon style={svgStyles.fil1} points={"35,17 22,30 15,23 12,26 22,36 38,20 "}/>
      </g>
    ) || (
      // Упало
      5 === status &&
      <g>
        <circle style={svgStyles.fil1} cx={25} cy={25} r={25}/>
        <polygon style={svgStyles.fil4} points={"20,37 7,25 11,21 20,30 39,11 42,15 20,37 "}/>
        <path style={svgStyles.fil4} d={"M25 0c-14,0 -25,11 -25,25 0,14 11,25 25,25 14,0 25,-11 25,-25 0,-14 -11,-25 -25,-25z"}/>
        <polygon style={svgStyles.fil1} points={"27,14 23,14 23,23 14,23 14,27 23,27 23,37 27,37 27,27 36,27 36,23 27,23 "}/>
      </g>
    ) || (
      // Отредактировать
      6 === status &&
      <g>
        <circle style={svgStyles.fil2} cx={25} cy={25} r={25}/>
        <path style={svgStyles.fil1} d={"M13 32l0 5 6 0 15 -15 -5 -5 -16 15zm25 -14c1,0 1,-1 0,-2l-3 -3c-1,-1 -2,-1 -2,0l-3 2 5 6 3 -3z"}/>
      </g>
    ) || (
      // Ушло на автообзвон
      7 === status &&
      <g>
        <circle style={svgStyles.fil0} cx={25} cy={25} r={25}/>
        <path style={svgStyles.fil1} d={"M36 30c-1,0 -3,-1 -4,-1 -1,0 -1,0 -2,0l-2 3c-3,-2 -7,-5 -9,-9l3 -2c0,-1 0,-1 0,-1 0,-2 0,-3 0,-5 0,-1 -1,-1 -2,-1l-4 0c-1,0 -2,0 -2,1 0,12 10,22 22,22 1,0 1,-1 1,-2l0 -4c0,-1 0,-1 -1,-1l0 0z"}/>
      </g>
    ) || (
      // Попытки закончились или завершен без действия клиента
      8 === status &&
      <g>
        <circle style={svgStyles.fil5} cx={25} cy={25} r={25}/>
        <path style={svgStyles.fil1} d={"M36 30c-1,0 -3,-1 -4,-1 -1,0 -1,0 -2,0l-2 3c-3,-2 -7,-5 -9,-9l3 -2c0,-1 0,-1 0,-1 0,-2 0,-3 0,-5 0,-1 -1,-1 -2,-1l-4 0c-1,0 -2,0 -2,1 0,12 10,22 22,22 1,0 1,-1 1,-2l0 -4c0,-1 0,-1 -1,-1l0 0z"}/>
      </g>
    ) || null;

    return (
      <div style={{position: 'relative'}}>
        <Avatar alt={street + " " + house} src={src} style={{position: 'relative'}} />
        {statusIcon &&
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} version="1.1" style={{ shapeRendering:'geometricPrecision', textRendering:'geometricPrecision', imageRendering:'optimizeQuality', fillRule:'evenodd', clipRule:'evenodd', position: 'absolute', bottom: "-3px", left: "33px" }} viewBox="0 0 50 50">
            <g>
              {statusIcon}
            </g>
          </svg>
        }
        <ChatButton
          label="Chat"
          object={record}
          style={{
            position: "absolute",
            top: "-19px",
            left: "19px",
            color: "#283593"
          }}
          className={classes.chatButton}
        />
      </div>
    ) || ''
  }

  return "";
};

const renderUser = location => record => {
  const params = parse(location.search);

  const query = `?${stringify({
    ...params,
    user: record.id
  })}`;

  return (
    <Link
      to={`/objects${query}`}
      onClick={ev => {
        ev.stopPropagation();
      }}
    >
      <FullNameField record={record} size={32} />
    </Link>
  );
};

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.updatedAt).utcOffset(8).format('YYYY-MM-DD HH:mm')
  const m2 = moment(record.updatedAt).utcOffset(8).format('HH:mm')
  return moment(m1).fromNow() + ' в ' + m2
}

// const renderSoldOut = record => {
//   return record.sold_out || ""
// }

class ObjectsTable extends Component {
  state = {
    chatButtonId: null
  };

  confirmPosterStatus = (posterId, objectId, statusConfirmed) => () => {
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "posting/actualize-posters", {
          id: posterId,
          data: {
            statusConfirmed,
            objectId
          }
        })
          .then(({ data }) => {
            // this.setState({
            //   saving: false,
            //   open: false
            // });

            if (data) {
              // this.props.showNotification("Object sold out", "info");
            }

            // setListSelectedIds("objects", []);
          })
          .catch(e => {
            // this.setState({
            //   saving: false,
            //   open: false
            // });
            console.log("warning", e);
            // this.props.showNotification(e, "warning");
          });
      }
    );
  }
  confirmDiscontinuedObject = (objectId, statusConfirmed) => () => {
    dataProviderFactory("rest").then(
      dataProvider => {
        dataProvider(UPDATE, "objects/status-confirmed", {
          id: objectId,
          data: {
            statusConfirmed
          }
        })
          .then(({ data }) => {
            if (data) {
              // this.props.showNotification("Object sold out", "info");
            }
          })
          .catch(e => {
            console.log("warning", e);
            // this.props.showNotification(e, "warning");
          });
      }
    );
  }


  render() {
    const { rowClick, location } = this.props;
    // let cmiIcon = (record) => { return (
    //   // упало, ушло на обзвон
    //   record.url_parse &&
    //   !record.autocallStatus &&
    //   record.autocallAt &&
    //   <g>
    //     <path style={{fill:'#898989',fillOpacity:0.501961}} d={"M36 1c12,0 23,10 23,22 0,12 -11,22 -23,22 -12,0 -22,-10 -22,-22 0,-12 10,-22 22,-22z"}/>
    //     <circle style={{fill:'white'}} cx="29" cy="17" r="6"/>
    //     <circle style={{fill:'white'}} cx="42" cy="17" r="5"/>
    //     <circle style={{fill:'white'}} cx="29" cy="31" r="3"/>
    //     <circle style={{fill:'white'}} cx="42" cy="31" r="7"/>
    //   </g>
    // ) || (
    //   // авито актуальное
    //   record.url_parse &&
    //   <g>
    //     <circle style={{fill:'#EBECEC'}} cx="36" cy="23" r="23"/>
    //     <circle style={{fill:'#00A8FF'}} cx="29" cy="17" r="6"/>
    //     <circle style={{fill:'#FE6263'}} cx="42" cy="17" r="5"/>
    //     <circle style={{fill:'#9F69F5'}} cx="29" cy="31" r="3"/>
    //     <circle style={{fill:'#97CC26'}} cx="42" cy="31" r="7"/>
    //   </g>
    // ) || ''};

    // let posterIcon = (record) => { return (
    //   // объявление от Мегадом
    //   record.objposters &&
    //   record.objposters.url &&
    //   <g>
    //     <circle style={{fill:'#EBECEC'}} cx="36" cy="23" r="23"/>
    //     <circle style={{fill:'#00A8FF'}} cx="29" cy="17" r="6"/>
    //     <circle style={{fill:'#FE6263'}} cx="42" cy="17" r="5"/>
    //     <circle style={{fill:'#9F69F5'}} cx="29" cy="31" r="3"/>
    //     <circle style={{fill:'#97CC26'}} cx="42" cy="31" r="7"/>
    //     <g>
    //       <path style={{fill:'#E31E24',fillOpacity:0.470588}} d={"M53 22c8,0 14,7 14,14 0,8 -6,14 -14,14 -7,0 -13,-6 -13,-14 0,-7 6,-14 13,-14z"}/>
    //       <circle style={{fill:'#FEFEFE'}} cx="50" cy="39" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="53" cy="39" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="57" cy="39" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="50" cy="36" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="53" cy="36" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="57" cy="36" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="50" cy="32" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="53" cy="32" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="57" cy="32" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="60" cy="36" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="47" cy="36" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="53" cy="29" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="50" cy="43" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="53" cy="43" r="1"/>
    //       <circle style={{fill:'#FEFEFE'}} cx="57" cy="43" r="1"/>
    //     </g>
    //   </g>
    // ) || ''};

    // const ObjectPanel = ({ id, record, resource }) => (
    //   <div style={{textAlign: 'center'}}>
    //     {record.url_parse &&
    //     null &&
    //       <a href={record.url_parse} target="_blank" style={{textDecoration:'none'}}>
    //         <svg xmlns="http://www.w3.org/2000/svg" width={40} height={25} version="1.1" style={{shapeRendering:'geometricPrecision', textRendering:'geometricPrecision', imageRendering:'optimizeQuality', fillRule:'evenodd', clipRule:'evenodd'}} viewBox="0 0 80 50">
    //           <g>
    //             {cmiIcon(record)}
    //           </g>
    //         </svg>
    //       </a>
    //     }
    //     {record.objposters &&
    //     record.objposters.url &&
    //     null &&
    //       <a href={record.objposters && record.objposters.url} target="_blank" style={{textDecoration:'none'}}>
    //         <svg xmlns="http://www.w3.org/2000/svg" width={40} height={25} version="1.1" style={{shapeRendering:'geometricPrecision', textRendering:'geometricPrecision', imageRendering:'optimizeQuality', fillRule:'evenodd', clipRule:'evenodd'}} viewBox="0 0 80 50">
    //           <g>
    //             {posterIcon(record)}
    //           </g>
    //         </svg>
    //         <span>
    //           {record.objposters && record.objposters.views || '0'}
    //         </span>
    //       </a>
    //     }
    //     {record.url_parse &&
    //       <div style={{position: 'relative', display: 'inline-block', width: '47%'}}>
    //         <iframe src={record.url_parse} style={{position: 'relative'}} width="100%" height={350}></iframe>
    //         <Button
    //           color="primary"
    //           onClick={this.confirmDiscontinuedObject(record.id, 2 === record.statusConfirmed ? 1 : 2)}
    //           style={{position: 'absolute', top: 0, right: '15px'}}
    //           variant="raised"
    //         >
    //           {2 !== record.statusConfirmed &&
    //             'Упало точно'
    //           }
    //           {2 === record.statusConfirmed &&
    //             'Поднято точно'
    //           }
    //         </Button>
    //       </div>
    //     }
    //     {record.objposters &&
    //     record.objposters.url &&
    //       <div style={{position: 'relative', display: 'inline-block', width: '47%'}}>
    //         <iframe src={record.objposters.url} style={{position: 'relative'}} width="100%" height={350}></iframe>
    //         <Button
    //           color="primary"
    //           onClick={this.confirmPosterStatus(record.objposters.id, record.id, 1 === record.objposters.statusConfirmed ? 2 : 1)}
    //           style={{position: 'absolute', top: 0, right: '15px'}}
    //           variant="raised"
    //         >
    //           {1 !== record.objposters.statusConfirmed &&
    //             'Запостилось точно'
    //           }
    //           {1 === record.objposters.statusConfirmed &&
    //             'Не запостилось точно'
    //           }
    //         </Button>
    //       </div>
    //     }
    //   </div>
    // );

    return (
      <GroupDatagrid
        {...this.props}
        rowClick={rowClick}
        rowStyle={rowStyle}
        bp={<TasksPanel />}
      >
        <FunctionField source="updatedAt" style={{ fontStyle: "italic" }} render={renderDate} />
        {/* <FunctionField source="sold_out" render={renderSoldOut} /> */}
        <FunctionField
          source=""
          render={renderImage(this.props.classes)}
        />
        <TextField source="region.name" />
        <FunctionField source="street.name" render={renderAddress} />
        <TextField source="types.name" />
        <NumberField source="area" />
        <TextField source="series.name" />
        <TextField source="type_house.name" />
        <FunctionField source="floor" render={renderFloor} />
        <FunctionField source="cost_of_owner" render={renderPrice} />
        <FunctionField source="cost_of_cmi" render={renderPriceCmi} />
        <ReferenceField
          source="user_id"
          label="resources.objects.fields.User"
          reference="users"
          linkType={false}
          allowEmpty
        >
          <FunctionField source="user_id" render={renderUser(location)} />
        </ReferenceField>
        <EditButton label="" source="edit" />
      </GroupDatagrid>
    );
  }
}

const ObjectsPagination = props => (
  <Pagination
    rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800]}
    {...props}
  />
);

const renderUserOption = choice => {
  return `${choice.name !== null ? choice.name : ""} ${
    choice.last_name !== null ? choice.last_name : ""
  }`;
};

export const ObjectsFilter = withStyles(filterStyles)(
  ({ classes, ...props }) => {
    const { cat_ids } = props.filterValues;
    return (
      <Filter {...props}>
        <ReferenceArrayInput
          source="cat_ids"
          label="resources.objects.input.categories"
          reference="categories"
          allowEmpty={false}
        >
          <ChipInput>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceArrayInput>
        <ChipInput
          source="q"
          label="resources.objects.input.q"
          isTranslated={false}
        >
          <SearchInput />
        </ChipInput>
        <ReferenceArrayInput
          source="pt_ids"
          label="resources.objects.input.pt"
          reference="proptypes"
          filter={{ cat_ids }}
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="cmi_ids"
          label="resources.objects.input.cmi"
          reference="cmi"
        >
          <ChipInput>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceArrayInput>
        <ChipInput
          label="resources.objects.input.cost"
          source="cost"
          isTranslated={false}
        >
          <RangeInput />
        </ChipInput>
        <ChipInput
          label="resources.objects.input.floor"
          source="floor"
          isTranslated={false}
        >
          <RangeInput />
        </ChipInput>
        <ChipInput
          label="resources.objects.input.floor_house"
          source="floor_house"
          isTranslated={false}
        >
          <RangeInput />
        </ChipInput>
        <ChipInput
          label="resources.objects.input.area"
          source="area"
          isTranslated={false}
        >
          <RangeInput />
        </ChipInput>
        <ReferenceInput
          source="series_ids"
          label="resources.objects.input.series"
          reference="series"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceInput>
        <ReferenceInput
          source="th_ids"
          label="resources.objects.input.th"
          reference="typehouse"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceInput>

        <ReferenceInput
          source="user_id"
          label="resources.objects.input.manager"
          reference="users"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput optionText={renderUserOption} />
          </ChipInput>
        </ReferenceInput>
        <ReferenceInput
          source="reg_ids"
          label="resources.objects.input.regions"
          reference="regions"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceInput>
        <ReferenceInput
          source="dist_ids"
          label="resources.objects.input.districts"
          reference="districts"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceInput>
        <ReferenceInput
          source="client_id"
          label="resources.objects.input.clients"
          reference="clients"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceInput>
        <ReferenceInput
          source="contractor_id"
          label="resources.objects.input.contractors"
          reference="contractors"
        >
          <ChipInput isTranslated={false}>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceInput>
        <ChipInput
          source="agent_parse"
          label="resources.objects.input.agent_parse"
          choices={[
            {
              value: "0",
              id: "0",
              name: "resources.objects.fields.agent_parse.owner"
            },
            {
              value: 1,
              id: 1,
              name: "resources.objects.fields.agent_parse.agent"
            }
          ]}
          isTranslated={false}
        >
          <SelectInput
            source="agent_parse"
            label="resources.objects.input.agent_parse"
            optionValue="value"
            allowEmpty={false}
            choices={[
              {
                value: "0",
                id: "0",
                name: "resources.objects.fields.agent_parse.owner"
              },
              {
                value: 1,
                id: 1,
                name: "resources.objects.fields.agent_parse.agent"
              }
            ]}
          ></SelectInput>
        </ChipInput>
        <ChipInput
          source="sold_out"
          label="resources.objects.input.sold_out"
          choices={[
            {
              value: "0",
              id: "0",
              name: "resources.objects.fields.sale"
            },
            {
              value: 1,
              id: 1,
              name: "resources.objects.fields.sold_out"
            }
          ]}
          isTranslated={false}
        >
          <SelectInput
            source="sold_out"
            label="resources.objects.input.sold_out"
            optionValue="value"
            choices={[
              {
                value: "0",
                id: "0",
                name: "resources.objects.fields.sale"
              },
              {
                value: 1,
                id: 1,
                name: "resources.objects.fields.sold_out"
              }
            ]}
          ></SelectInput>
        </ChipInput>
        <ChipInput
          source="parse_source_status"
          label="resources.objects.input.parse_source_status"
          choices={[
            {
              value: 11,
              id: 11,
              name: "resources.objects.fields.parse_source_status.11_about_month"
            },
            {
              value: 12,
              id: 12,
              name: "resources.objects.fields.parse_source_status.12_less_then_month"
            },
            {
              value: 21,
              id: 21,
              name: "resources.objects.fields.parse_source_status.21_about_month_confirmed"
            },
            {
              value: 22,
              id: 22,
              name: "resources.objects.fields.parse_source_status.22_less_then_month_confirmed"
            }
          ]}
          isTranslated={false}
        >
          <SelectInput
            source="parse_source_status"
            label="resources.objects.input.parse_source_status"
            optionValue="value"
            allowEmpty={false}
            choices={[
              {
                value: 11,
                id: 11,
                name: "resources.objects.fields.parse_source_status.11_about_month"
              },
              {
                value: 12,
                id: 12,
                name: "resources.objects.fields.parse_source_status.12_less_then_month"
              },
              {
                value: 21,
                id: 21,
                name: "resources.objects.fields.parse_source_status.21_about_month_confirmed"
              },
              {
                value: 22,
                id: 22,
                name: "resources.objects.fields.parse_source_status.22_less_then_month_confirmed"
              }
            ]}
          ></SelectInput>
        </ChipInput>
        <ChipInput
          source="deletedAt"
          label="resources.objects.fields.deletedAt"
          choices={[
            {
              value: "0",
              id: "0",
              name: "resources.objects.input.no"
            },
            {
              value: 1,
              id: 1,
              name: "resources.objects.input.yes"
            }
          ]}
          isTranslated={false}
        >
          <SelectInput
            source="deletedAt"
            label="resources.objects.fields.deletedAt"
            optionValue="value"
            choices={[
              {
                value: "0",
                id: "0",
                name: "resources.objects.input.no"
              },
              {
                value: 1,
                id: 1,
                name: "resources.objects.input.yes"
              }
            ]}
          ></SelectInput>
        </ChipInput>
      </Filter>
    );
  }
);

const ObjectsBulkActionButtons = props => (
  <Fragment>
    <SoldOutObjectButton {...props}/>
    <AssignPhone {...props} />
    <TransferObjectsButton
      {...props}
      resource="transfer/objects"
      source="user_id"
      reference="users"
      label="resources.objects.input.manager"
    />
    <TransferObjectsButton
      {...props}
      resource="clients/objects"
      source="client_id"
      reference="clients"
      label="resources.objects.input.client"
    />
    <TransferObjectsButton
      {...props}
      resource="transfer/objects"
      source="contractor_id"
      reference="contractors"
      label="resources.contractors.input.contractors"
    />
    <RestoreObjectButton {...props}/>
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ObjectsActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions
    style={{
      padding: "8px 0",
      width: 400,
      minWidth: 400,
      flexWrap: "wrap",
      justifyContent: "flex-end"
    }}
  >
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <div>
      <PostingButton />
      <SaveFilterButton />
      <SavedFilterButton />
    </div>
  </CardActions>
);

class ObjectsList extends Component {
  handleRowClick = id => {
    const { location, push } = this.props;
    const params = parse(location.search);

    push(
      `?${stringify({
        ...params,
        show: id
      })}`
    );

    return `?${stringify({
      ...params,
      show: id
    })}`;
  };

  handleClose = ev => {
    const params = parse(this.props.location.search);

    delete params.show;
    delete params.tab;
    delete params.user;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };

  render() {
    const { location } = this.props;
    return (
      <Fragment>
        <List
          {...this.props}
          filters={<ObjectsFilter />}
          toolbar={
            <ObjectsToolbar sort={{ field: "updatedAt", order: "DESC" }} />
          }
          actions={<ObjectsActions />}
          pagination={<ObjectsPagination />}
          perPage={25}
          bulkActionButtons={<ObjectsBulkActionButtons />}
          sort={{ field: "updatedAt", order: "DESC" }}
          filterDefaultValues={{ deletedAt: "0" }}
        >
          <ViewMode
            mapgrid={
              <MapAndGrid
                resource={this.props.resource}
                rowClick={this.handleRowClick}
              />
            }
            table={
              <ObjectsTable
                location={location}
                rowClick={this.handleRowClick}
                classes={this.props.classes}
              />
            }
            grid={<ObjectsGrid rowClick={this.handleRowClick} />}
            defaultMode={
              <ObjectsTable
                location={location}
                rowClick={this.handleRowClick}
                classes={this.props.classes}
              />
            }
          />
        </List>
        <Route path="/objects">
          {route => {
            const values = parse(route.location.search);
            let id = null;

            if (values.show) {
              id = values.show;
            }

            let user = null;

            if (values.user) {
              user = values.user;
            }

            if (user) {
              return (
                <UserQuickPreview
                  id={user}
                  open={!!user}
                  handleClose={this.handleClose}
                  permissions={this.props.permissions}
                />
              );
            }

            if (id) {
              return (
                <ObjectModal
                  id={id}
                  open={!!id}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            return null;
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default compose(
  connect(
    mapStateToProps,
    {
      push,
      // refreshView
    }
  ),
  withStyles(styles)
)(ObjectsList);
