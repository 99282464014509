import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  LongTextInput,
  AutocompleteInput,
  translate,
  CardActions,
  ListButton
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

const validate = translate => values => {};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  }
});

const optionRenderer = choice => `${choice.name !== null ? choice.name : ''} ${choice.last_name !== null ? choice.last_name : ''}`;

const PbxPhonesActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const PbxPhonesCreate = ({ classes, ...props }) => {
  const { translate } = props;
  return (
    <Create
      {...props}
      classes={{ card: classes.card }}
      actions={<PbxPhonesActions />}
    >
      <SimpleForm validate={validate(translate)}>
        <ReferenceInput
          label="resources.users.fields.user"
          source="user_id"
          reference="users"
          allowEmpty={true}
        >
          <AutocompleteInput optionText={optionRenderer} />
        </ReferenceInput>
        <LongTextInput source="phones" label="resources.pbx/phones.input.phones" resettable placeholder="79998887766,.." />
      </SimpleForm>
    </Create>
  );
};

export default compose(
  withStyles(styles),
  translate
)(PbxPhonesCreate);
