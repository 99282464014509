import { UPDATE_MANY, UPDATE, FETCH_END } from "react-admin";

export const REFRESH_PERCENT = "REFRESH_PERCENT";

export const CRUD_UPDATE_MANY = "RA/CRUD_UPDATE_MANY";
export const crudTaskUpdateMany = (
  resource,
  ids,
  data,
  basePath,
  refresh = true
) => ({
  type: CRUD_UPDATE_MANY,
  payload: { ids, data },
  meta: {
    resource,
    fetch: UPDATE_MANY,
    cancelPrevious: false,
    onSuccess: {
      notification: {
        body: "ra.notification.updated",
        level: "info",
        messageArgs: {
          smart_count: ids.length
        }
      },
      basePath,
      refresh,
      unselectAll: true
    },
    onFailure: {
      notification: {
        body: "ra.notification.http_error",
        level: "warning"
      }
    }
  }
});

export const refreshPercent = (id, data, basePath) => ({
  type: REFRESH_PERCENT,
  payload: { id, data: { ...data } },
  meta: { resource: "tasks", fetchResponse: UPDATE, fetchStatus: FETCH_END }
});
