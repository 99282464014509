import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    paddingRight: 8
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600
  },
  summaryContent: {
    alignItems: "baseline"
  }
});

class ExpPanel extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    secondaryHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    details: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    classes: PropTypes.object.isRequired
  };

  state = {
    expanded: false
  };

  handleChange = panel => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    });
  };

  render() {
    const {
      name,
      heading,
      secondaryHeading,
      details,
      classes,
      className
    } = this.props;

    return (
      <ExpansionPanel
        expanded={this.state.expanded === name}
        onChange={this.handleChange(name)}
        className={className}
      >
        <ExpansionPanelSummary
          classes={{ content: classes.summaryContent }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>{heading}: </Typography>
          <Typography className={classes.secondaryHeading}>
            {secondaryHeading}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>{details || ""}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(ExpPanel);
