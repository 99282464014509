import React, { Component, Fragment } from "react";

import {
  FunctionField,
  TextField,
  List,
  Pagination,
  EditButton,
} from "react-admin";

import { find } from "lodash";

import moment from "moment";
import 'moment/locale/ru';

import { push } from "react-router-redux";
import { connect } from "react-redux";

import CustomizableDatagrid from "../components/ra-customizable-datagrid";

import { events, actions } from "../consts";

const renderEvent = record => {
  let name = record.event;
  events.forEach(item => {
    const event = find(item.choices, {
      id: name
    });
    if (event) {
      name = event.name;
    }
  
  });
  return name;
}

const renderAction = record => {
  let _actions = record.action;

  if (typeof _actions === "string") {
    _actions = JSON.parse(_actions);
  }
  let strActions = '';
  if (_actions && _actions.length) {
    _actions.forEach((item, key) => {
      const action = find(actions[record.section], {
        id: item
      });
  
      if (action) {
        if (key === _actions.length - 1) {
          strActions += action.name;
        } else {
          strActions += action.name + ", ";
        }
      }
      
    });
  }
  
  return strActions;
}

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.updatedAt)
  const m2 = moment(record.updatedAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}


const EventActionTable = props => (
  <CustomizableDatagrid {...props} showGroup={true}>
    <FunctionField source="updatedAt" style={{ fontStyle: "italic" }} render={renderDate} />
    <TextField source="name" />
    <FunctionField source="event" render={renderEvent} />
    <FunctionField source="action" render={renderAction} />
    <EditButton label="" source="edit" />
  </CustomizableDatagrid>
);

const EventActionPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10]} {...props} />
);

class EventActionList extends Component {

  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<EventActionPagination />}
          perPage={5}
          sort={{ field: "updatedAt", order: "DESC" }}
        >
          <EventActionTable location={props.location} />
        </List>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { push }
)(EventActionList);
