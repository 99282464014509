import React from "react";
import {
  Edit,
  SimpleForm,
  translate,
  CardActions,
  ListButton,
  TextInput,
  SelectInput
} from "react-admin";
import PhoneInput from "../components/input/PhoneInput";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({});

const PosterTitle = ({ record }) =>
  record && record.name ? record.name : null;

const PosterActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const PosterEdit = ({ classes, ...props }) => {
  const { translate } = props;

  return (
    <Edit
      {...props}
      title={<PosterTitle />}
      classes={{ card: classes.card }}
      actions={<PosterActions />}
    >
      <SimpleForm validate={validate(translate)}>
        <PhoneInput
          label="resources.posters.fields.phone"
          source="phone"
        />
        <TextInput source="login" />
        <TextInput source="password" />
        <TextInput source="name" />
        <SelectInput
          allowEmpty
          // emptyValue={null}
          optionText="name"
          label="resources.posters.fields.statusAccount"
          source="statusAccount"
          choices={[
            { id: 0, name: "Нормальный" },
            { id: 1, name: "Бан" },
            { id: 2, name: "Не подтвержден" },
            { id: 3, name: "Не зарегистрирован" },
            { id: 4, name: "Телефон не подтвержден" },
            { id: 10, name: "Изменение контента" },
            { id: 30, name: "Размещен 1 мес" },
            // { id: "50", name: "Архив" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(PosterEdit);
