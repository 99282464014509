import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { change, formValueSelector, getFormMeta } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import IconSearch from "@material-ui/icons/Search";
import IconCheck from "@material-ui/icons/PlaylistAddCheck";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SearchInput from "../components/input/SearchInput";
import dataProviderFactory from "../dataProvider";
import get from "lodash/get";

import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  GET_LIST,
  REDUX_FORM_NAME,
  DateField,
  TextField,
  FunctionField,
  translate
} from "react-admin";

const renderAddress = record => {
  const street = get(record, "street.name");
  const house = get(record, "house");

  if (street && house) {
    return (
      <span>
        <strong>{street}</strong> {house}
      </span>
    );
  }
};

export class SearchSimilarObjectsButton extends Component {
  static propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    source: PropTypes.string,
    change: PropTypes.func.isRequired
  };

  static source = "";

  state = {
    error: false,
    showDialog: false,
    data: [],
    page: 1,
    rowsPerPage: 5,
    total: 0,
    order: "desc",
    orderBy: "createdAt",
    q: "",
    minHeight: 0,
    selectedId: null
  };

  constructor(props) {
    super(props);

    SearchSimilarObjectsButton.source = props.source;
  }

  getNbPages = () => Math.ceil(this.state.total / this.state.rowsPerPage) || 1;

  fetchData = (props, page, rowsPerPage, order, orderBy) => {
    const { fetchStart, fetchEnd, showNotification } = props;

    let street = props.street;

    if(street){
      if (street.name) {
        street = street.name;
      }
    }

    fetchStart();
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(GET_LIST, "objects", {
          filter: { q: street },
          sort: { field: orderBy, order: order.toUpperCase() },
          pagination: { page: page, perPage: rowsPerPage }
        })
          .then(({ data, total }) => {
            if (data.length > 0) {
              const newData = [];

              data.forEach((item, key) => {
                newData[item.id] = item;
              });

              this.setState({
                data: newData,
                q: street,
                total,
                selectedId: null
              });
            } else {
              this.setState({
                minHeight: 0,
                data: [],
                q: street,
                selectedId: null
              });
            }
          })
          .catch(error => {
            showNotification(error.message, "error");
          })
          .finally(() => {
            fetchEnd();
          });
      }
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { source } = this.props;
    const { street, fields } = nextProps;

    if (street && this.state.showDialog) {
      const meta = fields[source];
      const { page, rowsPerPage, order, orderBy } = this.state;
      if (
        street.length >= 3 &&
        street !== this.props.street &&
        street !== this.state.q &&
        (this.state.isEnterPress ||
          (!meta.active && meta.visited && meta.touched))
      ) {
        this.fetchData(nextProps, page, rowsPerPage, order, orderBy);
      }
    }

    if (!street) {
      this.setState({
        data: [],
        minHeight: 0,
        q: "",
        selectedId: null
      });
    }
  }

  labelDisplayedRows = ({ from, to, count }) => {
    const { translate } = this.props;
    return translate("ra.navigation.page_range_info", {
      offsetBegin: from,
      offsetEnd: to,
      total: count
    });
  };

  handleClick = () => {
    const { street } = this.props;
    const { data, q, page, rowsPerPage, order, orderBy } = this.state;

    let selectedId = this.state.selectedId;

    if (street && (street !== q || data.length === 0)) {
      this.fetchData(this.props, page, rowsPerPage, order, orderBy);
      selectedId = null;
    }

    if (!street || data.length === 0) {
      selectedId = null;
    }

    this.setState({
      showDialog: true,
      selectedId
    });
  };

  handleSaveClick = () => {
    if (this.state.selectedId) {
      const { change, images } = this.props;
      const data = this.state.data[this.state.selectedId];

      if(data.images){
        if(images){
          change(REDUX_FORM_NAME, "images", {
            ...data.images,
            ...images,
            all: [...data.images.all, ...images.all]
          });
        }else{
          change(REDUX_FORM_NAME, "images", data.images);
        }
      }

      change(REDUX_FORM_NAME, "house", data.house);
      change(REDUX_FORM_NAME, "entrance", data.entrance);
      change(REDUX_FORM_NAME, "entrance", data.entrance);
      change(REDUX_FORM_NAME, "region_id", data.region.id);
      change(REDUX_FORM_NAME, "district_id", data.district_id);
      change(REDUX_FORM_NAME, "category_id", data.category_id);
      change(REDUX_FORM_NAME, "type_house_id", data.type_house.id);
      change(REDUX_FORM_NAME, "series_id", data.series.id);
      change(REDUX_FORM_NAME, "floor_house", data.floor_house);
      change(REDUX_FORM_NAME, "lat", data.lat);
      change(REDUX_FORM_NAME, "lon", data.lon);

      this.setState({
        showDialog: false
      });
    }
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleChange = (event, newValue, name) => {
    if (event.type === "click") {
      this.setState({
        minHeight: 0
      });
    }
  };

  handleChangePage = (event, page) => {
    event && event.stopPropagation();
    if (page < 0 || page > this.getNbPages() - 1) {
      throw new Error(
        this.props.translate("ra.navigation.page_out_of_boundaries", {
          page: page + 1
        })
      );
    }

    this.setState({
      page: page + 1
    });

    const { rowsPerPage, order, orderBy } = this.state;

    this.fetchData(this.props, page + 1, rowsPerPage, order, orderBy);
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value
    });

    const { page, order, orderBy } = this.state;

    this.fetchData(this.props, page, event.target.value, order, orderBy);
  };

  handleRequestSort = property => event => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });

    const { page, rowsPerPage } = this.state;

    this.fetchData(this.props, page, rowsPerPage, order, orderBy);
  };

  getCountSuggestions = count => {
    if (count >= 1) {
      this.setState({
        minHeight: count * 48 + 60
      });
    } else {
      this.setState({
        minHeight: 1
      });
    }
  };

  handleRowClick = row => event => {
    this.setState({
      selectedId: row.id
    });
  };

  render() {
    const {
      showDialog,
      data,
      page,
      rowsPerPage,
      order,
      orderBy,
      total
    } = this.state;
    const { fullScreen, classes, translate, source, reference } = this.props;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label="resources.objects.fields.search_object"
        >
          <IconSearch />
        </Button>
        <Dialog
          scroll="body"
          fullScreen={fullScreen}
          maxWidth="md"
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Найти объект"
        >
          <DialogActions>
            <Button
              label="resources.action.check"
              variant="contained"
              color="primary"
              onClick={this.handleSaveClick}
              disabled={!this.state.selectedId}
            >
              <IconCheck />
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
          <DialogContent style={{ minHeight: this.state.minHeight }}>
            <SearchInput
              label="resources.objects.input.street"
              source={source}
              reference={reference}
              classes={{ root: classes.searchRoot }}
              getCountSuggestions={this.getCountSuggestions}
              showErrors={false}
              handleChange={this.handleChange}
            />
            {data.length > 0 && (
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sortDirection={orderBy === "createdAt" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "createdAt"}
                          direction={order}
                          onClick={this.handleRequestSort("createdAt")}
                        >
                          {translate("resources.objects.fields.createdAt")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "house"}
                          direction={order}
                          onClick={this.handleRequestSort("house")}
                        >
                          {translate("resources.objects.fields.street.name")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "series.name"}
                          direction={order}
                          onClick={this.handleRequestSort("series.name")}
                        >
                          {translate("resources.objects.fields.series.name")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "type_house.name"}
                          direction={order}
                          onClick={this.handleRequestSort("type_house.name")}
                        >
                          {translate(
                            "resources.objects.fields.type_house.name"
                          )}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "floor_house"}
                          direction={order}
                          onClick={this.handleRequestSort("floor_house")}
                        >
                          {translate("resources.objects.fields.floor_house")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "entrance"}
                          direction={order}
                          onClick={this.handleRequestSort("entrance")}
                        >
                          {translate("resources.objects.fields.entrance")}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map(row => {
                      return (
                        <TableRow
                          key={row.id}
                          onClick={this.handleRowClick(row)}
                          selected={this.state.selectedId === row.id}
                          hover
                          classes={{ root: classes.tableRow }}
                        >
                          <TableCell>
                            <DateField
                              record={row}
                              source="createdAt"
                              style={{ fontStyle: "italic" }}
                            />
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              record={row}
                              source="street.name"
                              render={renderAddress}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField record={row} source="series.name" />
                          </TableCell>
                          <TableCell>
                            <TextField record={row} source="type_house.name" />
                          </TableCell>
                          <TableCell>
                            <TextField record={row} source="floor_house" />
                          </TableCell>
                          <TableCell>
                            <TextField record={row} source="entrance" />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  count={total}
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  labelDisplayedRows={this.labelDisplayedRows}
                  labelRowsPerPage={translate(
                    "ra.navigation.page_rows_per_page"
                  )}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  fields: getFormMeta(REDUX_FORM_NAME)(state),
  images: formValueSelector(REDUX_FORM_NAME)(state, "images"),
  [SearchSimilarObjectsButton.source]: formValueSelector(REDUX_FORM_NAME)(
    state,
    SearchSimilarObjectsButton.source
  )
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification
};

const styles = {
  dialogPaper: {
    minHeight: "80vh"
  },
  searchRoot: {
    width: "100%"
  },
  tableRow: {
    cursor: "pointer"
  }
};

export default compose(
  withStyles(styles),
  withMobileDialog(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate
)(SearchSimilarObjectsButton);
