import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  RichTextField,
  CardActions,
  CreateButton,
  ListButton,
  FunctionField
} from "react-admin";

import Checklist from '../components/list/Checklist';
import { getNumberWithLabel } from "../utils/formatter";

const userRenderer = record => {
  return `${record.name !== null ? record.name : ''} ${record.last_name !== null ? record.last_name : ''}`;
}

const renderDeadline = record => {
  let deadline = '';

  if (record.deadline_d) {
    deadline += `${getNumberWithLabel(record.deadline_d, 'день', 'дня', 'дней')} `;
  }

  if (record.deadline_h) {
    deadline += `${getNumberWithLabel(record.deadline_h, 'час', 'часа', 'часов')} `;
  }

  if (record.deadline_m) {
    deadline += `${getNumberWithLabel(record.deadline_m, 'минута', 'минуты', 'минут')} `;
  }

  return deadline;
}

const TaskActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <ListButton basePath={basePath} />
  </CardActions>
);

const TaskTitle = ({ record }) => record.title || "";

const TaskShow = props => (
  <Show {...props} title={<TaskTitle />} actions={<TaskActions />}>
    <SimpleShowLayout>
      <TextField source="title" />
      <ReferenceField
          source="object_id"
          reference="objects"
          label="resources.tasks.input.object_id"
          allowEmpty
        >
          <TextField source="id" />
      </ReferenceField>
      <ReferenceField
          source="client_id"
          reference="clients"
          label="resources.tasks.input.client_id"
          allowEmpty
        >
          <TextField source="id" />
      </ReferenceField>
      <ReferenceField
          source="to_id"
          reference="users"
          label="resources.tasks.input.to_id"
          allowEmpty
        >
        <FunctionField render={userRenderer} />
      </ReferenceField>
      <ReferenceField
          source="script_id"
          reference="scripts"
          label="resources.tasks.input.script_id"
          allowEmpty={true}
        >
          <TextField source="name" />
      </ReferenceField>
      <FunctionField source="deadline" render={renderDeadline} />
      <Checklist label="resources.tasks.input.checklist" source="checklist" />
      <RichTextField
        label="resources.tasks.input.description"
        source="description"
      />
    </SimpleShowLayout>
  </Show>
);

export default TaskShow;
