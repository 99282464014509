import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import ContentAdd from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Button,
  SimpleForm,
  translate,
  SaveButton,
  TextInput,
  showNotification,
  CREATE
} from "react-admin";
import dataProviderFactory from "../dataProvider";

class AddStreetButton extends Component {
  state = {
    open: false,
    saving: false,
    street: ""
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const { showNotification } = this.props;

    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(CREATE, "streets", {
          data: {
            name: this.state.street
          }
        })
          .then(({ data }) => {
            this.setState({
              saving: false,
              open: false
            });

            showNotification("Street added", "info");
          })
          .catch(e => {
            this.setState({
              saving: false,
              open: false
            });
            showNotification(e, "warning");
          });
      }
    );
  };

  handleChangeStreet = ev => {
    this.setState({
      street: ev.target.value
    });
  };

  render() {
    const { translate } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClickOpen}>
          <ContentAdd />
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {translate("Add address")}
          </DialogTitle>
          <DialogContent>
            <SimpleForm resource="streets" toolbar={null}>
              <TextInput source="street_custom" label="resources.objects.input.street" onChange={this.handleChangeStreet} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              basePath=""
              onClick={this.handleSubmit}
              saving={this.state.saving}
              disabled={this.state.street === ""}
            />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = { showNotification };

export default compose(
  translate,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddStreetButton);
