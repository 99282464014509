import React, { Component } from "react";
import { Admin, Resource, createAdminStore } from "react-admin";
import { createHashHistory } from 'history';
import { Provider } from "react-redux";
// import { Admin, Resource, EditGuesser } from "react-admin";
import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import Login from "./Login";
import Layout from "./Layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import russianMessages from "./i18n/ru";

import ObjectsList from "./objects/ObjectsList";
import ObjectEdit from "./objects/ObjectEdit";
import ObjectCreate from "./objects/ObjectCreate";

import UsersList from "./users/UsersList";
import UserCreate from "./users/UserCreate";
import UserEdit from "./users/UserEdit";

import TasksList from "./tasks/TasksList";
import TaskEdit from "./tasks/TaskEdit";
import TaskCreate from "./tasks/TaskCreate";
import TaskShow from "./tasks/TaskShow";

import TasksTemplatesList from "./tasks-templates/TasksTemplatesList";
import TaskTemplateEdit from "./tasks-templates/TaskTemplateEdit";
import TaskTemplateCreate from "./tasks-templates/TaskTemplateCreate";
import TaskTemplateShow from "./tasks-templates/TaskTemplateShow";

import EventActionList from "./event-actions/EventActionList";
import EventActionCreate from "./event-actions/EventActionCreate";
import EventActionEdit from "./event-actions/EventActionEdit";

import RolesList from "./roles/RolesList";
import RoleEdit from "./roles/RoleEdit";
import RoleCreate from "./roles/RoleCreate";

import ContractorsList from "./contractors/ContractorsList";
import ContractorCreate from "./contractors/ContractorCreate";
import ContractorEdit from "./contractors/ContractorEdit";

import ClientsList from "./clients/ClientsList";
import ClientCreate from "./clients/ClientCreate";
import ClientEdit from "./clients/ClientEdit";

import DistrictsList from "./districts/DistrictsList";
import DistrictCreate from "./districts/DistrictCreate";
import DistrictEdit from "./districts/DistrictEdit";

import SeriesList from "./series/SeriesList";
import SeriesCreate from "./series/SeriesCreate";
import SeriesEdit from "./series/SeriesEdit";

import PostersList from "./posters/PostersList";
import PosterCreate from "./posters/PosterCreate";
import PosterEdit from "./posters/PosterEdit";

import PbxPhonesList from "./pbx/phones/PbxPhonesList";
import PbxPhonesCreate from "./pbx/phones/PbxPhonesCreate";
import PbxAudioList from "./pbx/audio/PbxAudioList";
import PBXBlList from "./pbx/bl/PBXBlList";
import PBXBlCreate from "./pbx/bl/PBXBlCreate";
import PBXCallsList from "./pbx/calls/PbxCallsList";

import ScriptCreate from "./scripts/ScriptCreate";
import ScriptEdit from "./scripts/ScriptEdit";
import ScriptsList from "./scripts/ScriptsList";

import ContentBarChat from "./ContentBarChat";

import Menu from "./Menu";

import addUploadFeature from "./objects/addUploadFeature";
import dataProviderFactory from "./dataProvider";

import findIndex from "lodash/findIndex";

import ContentBarBp from "./ContentBarBp";
import contentBarReducer from "./customReducers/contentBarReducer";
import bpReducer from "./customReducers/bpReducer";
import pbxReducer from "./customReducers/pbxReducer";
import chatReducer from "./customReducers/ChatReducer";

import { setPbxEvent } from "./actions/pbxActions";

import openSocket from "socket.io-client";

import ScrollToTop from "./components/ScrollToTop";
import { CHAT_URL, socket } from "./consts";

const i18nProvider = locale => {
  if (locale === "en") {
    return import("./i18n/en").then(messages => messages.default);
  }

  return russianMessages;
};

const SecureResource = ({
  name,
  list,
  edit,
  icon,
  create,
  show,
  permissions,
  ...props
}) => {
  const { perms } = permissions;
  const index = findIndex(perms, { slug: name });

  if (index >= 0) {
    const { r, c, u, d } = perms[index].access;

    return (
      r &&
      r === 1 && (
        <Resource
          name={name}
          list={r === 1 ? list : null}
          icon={icon}
          show={r === 1 ? show : null}
          create={c === 1 ? create : null}
          edit={u === 1 ? edit : null}
          hasdelete={d === 1}
          {...props}
        />
      )
    );
  }

  return null;
};

const initialState = {};

const history = createHashHistory();

socket.instance = openSocket(CHAT_URL);
Object.freeze(socket)

const authProviderActions = authProvider(socket.instance);

class App extends Component {
  state = { dataProvider: null };

  handlePbxEvent = event => data => {
    setPbxEvent(event);
  };

  async componentWillMount() {
    const dataProvider = await dataProviderFactory(
      process.env.REACT_APP_DATA_PROVIDER
    );

    const uploadCapableDataProvider = addUploadFeature(dataProvider);

    this.setState({ dataProvider: uploadCapableDataProvider });

    socket.instance.on("pbx_dialing", this.handlePbxEvent("pbx_dialing"));
    socket.instance.on("pbx_talk", this.handlePbxEvent("pbx_talk"));
  }

  componentWillUnmount() {
    socket.instance.off("pbx_dialing", data => this.handlePbxEvent("pbx_dialing", data));
    socket.instance.off("pbx_talk", data => this.handlePbxEvent("pbx_talk", data));
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return (
        <div className="loader-container">
          <div className="loader">Loading...</div>
        </div>
      );
    }

    return (
      <Provider
        store={createAdminStore({
          authProvider: authProviderActions,
          customReducers: {
            theme: themeReducer,
            contentBar: contentBarReducer,
            bp: bpReducer,
            pbx: pbxReducer,
            chat: chatReducer
          },
          dataProvider: dataProvider,
          i18nProvider: i18nProvider,
          initialState: initialState,
          locale: "ru",
          history: history
        })}
      >
        <ScrollToTop>
          <div className="admin-wrap">
            <Admin
              title="Megadom"
              dataProvider={dataProvider}
              customRoutes={customRoutes}
              authProvider={authProviderActions}
              dashboard={Dashboard}
              loginPage={Login}
              appLayout={Layout}
              locale="ru"
              menu={Menu}
              i18nProvider={i18nProvider}
              style={{ minWidth: "100%" }}
              history={history}
            >
              {permissions => {
                return [
                  <SecureResource
                    name="objects"
                    list={ObjectsList}
                    edit={ObjectEdit}
                    create={ObjectCreate}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="users"
                    list={UsersList}
                    create={UserCreate}
                    edit={UserEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="roles"
                    list={RolesList}
                    edit={RoleEdit}
                    create={RoleCreate}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="tasks"
                    list={TasksList}
                    create={TaskCreate}
                    show={TaskShow}
                    edit={TaskEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="tasks-templates"
                    list={TasksTemplatesList}
                    create={TaskTemplateCreate}
                    show={TaskTemplateShow}
                    edit={TaskTemplateEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="event-actions"
                    list={EventActionList}
                    create={EventActionCreate}
                    edit={EventActionEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="contractors"
                    list={ContractorsList}
                    create={ContractorCreate}
                    edit={ContractorEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="clients"
                    list={ClientsList}
                    create={ClientCreate}
                    edit={ClientEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="districts"
                    list={DistrictsList}
                    create={DistrictCreate}
                    edit={DistrictEdit}
                    permissions={permissions}
                  />,
                  <Resource
                    name="series"
                    list={SeriesList}
                    create={SeriesCreate}
                    edit={SeriesEdit}
                  />,
                  <SecureResource
                    name="posters"
                    list={PostersList}
                    create={PosterCreate}
                    edit={PosterEdit}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="pbx/phones"
                    list={PbxPhonesList}
                    create={PbxPhonesCreate}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="pbx/audio"
                    list={PbxAudioList}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="pbx/bl"
                    list={PBXBlList}
                    create={PBXBlCreate}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="pbx/calls"
                    list={PBXCallsList}
                    permissions={permissions}
                  />,
                  <SecureResource
                    name="scripts"
                    create={ScriptCreate}
                    edit={ScriptEdit}
                    list={ScriptsList}
                    permissions={permissions}
                  />,
                  <Resource name="cmi" />,
                  <Resource name="chat" />,
                  <Resource name="proptypes" />,
                  <Resource name="series" />,
                  <Resource name="typehouse" />,
                  <Resource name="regions" />,
                  <Resource name="districts" />,
                  <Resource name="posters" />,
                  <Resource name="categories" />,
                  <Resource name="conditions" />,
                  <Resource name="sales_status" />,
                  <Resource name="targets" />,
                  <Resource name="additionals" />,
                  <Resource name="roles" />,
                  <Resource name="developers" />,
                  <Resource name="streets/search" />,
                  <Resource name="floors" />,
                  <Resource name="chat_rooms" />,
                  <Resource name="filters" />,
                  <Resource name="filters/categories" />
                ];
              }}
            </Admin>
            <ContentBarBp
              name="bp"
              style={{
                maxWidth: 360,
                minWidth: 320,
                position: "relative"
              }}
            />
            <ContentBarChat
              name="messages"
              style={{
                maxWidth: 360,
                minWidth: 320
              }}
            />
          </div>
        </ScrollToTop>
      </Provider>
    );
  }
}

export default App;
