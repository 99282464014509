import React, { Component } from "react";
import Search from "@material-ui/icons/Search";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";

import { compose } from "redux";
import { connect } from "react-redux";

class ChatRooms extends Component {
  state = {
    search: ""
  };

  handleChangeSearchRoom = ev => {
    if (ev.target.value === "") {
      this.props.handleClickSearchRoom(null);
    }

    this.setState({
      search: ev.target.value
    });
  };

  handleClickSearchRoom = () => {
    this.props.handleClickSearchRoom(this.state.search);
  };

  render() {
    const { classes, handleClickRoom, rooms, roomsNewMessages } = this.props;

    return (
      <Grid item xs={12}>
        <Card className={classes.chatCard}>
          <CardContent className={classes.chatroomSearchContent}>
            <TextField
              id="outlined-chatroom-search"
              variant="outlined"
              type="search"
              label="Поиск по названию комнаты"
              style={{
                width: '100%'
              }}
              value={this.state.search}
              onChange={this.handleChangeSearchRoom}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Найти комнату"
                      onClick={this.handleClickSearchRoom}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {roomsNewMessages.map(item => {
              if (item.count) {
                return (
                  <Chip
                    key={item.id}
                    className={classes.chatroomChip}
                    avatar={<Avatar>{item.count}</Avatar>}
                    label={item.name}
                    clickable
                    color="primary"
                    variant="outlined"
                    onClick={handleClickRoom(item.id, item.name, item.image)}
                  />
                );
              }

              return null;
            })}
            {rooms.map(item => {
              if (!item.count) {
                const avatar = item.image && item.image.src? (
                  <Avatar alt={item.name} src={item.image.src || item.image} />
                ) : null;
                return (
                  <Chip
                    key={item.id + 10}
                    className={classes.chatroomChip}
                    avatar={avatar}
                    label={item.name}
                    clickable
                    color="primary"
                    variant="outlined"
                    onClick={handleClickRoom(item.id, item.name, item.image)}
                  />
                );
              }

              return null;
            })}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  roomsNewMessages: state.chat.rooms
});

export default compose(
  connect(
    mapStateToProps,
    {}
  )
)(ChatRooms);
