import {
  NEW_MESSAGES_IN_ROOM,
  CLEAR_NEW_MESSAGES_IN_ROOM,
  SET_ROOMS
} from "../actions/ChatActions";
import findIndex from "lodash/findIndex";

const defaultState = {
  rooms: []
};

const removeDublicateRooms = (state, payload) => {
  let rooms = [];
  if (payload.type === "s") {
    rooms = payload.rooms;
  } else {
    rooms = [...state.rooms, ...payload.rooms];
  }

  const rooms2 = [];
  rooms.forEach(item => {
    const index = findIndex(rooms2, { id: item.id });
    if (index === -1) {
      rooms2.push(item);
    } else {
      if (rooms2[index].count && item.count) {
        rooms2[index].count =
          rooms2[index].count + (item.count - rooms2[index].count);
      }
      if (!rooms2[index].count && item.count) {
        rooms2[index].count = item.count;
      }
    }
  });
  return {
    rooms: rooms2
  };
};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case NEW_MESSAGES_IN_ROOM:
      return removeDublicateRooms(previousState, payload);
    case SET_ROOMS:
      return removeDublicateRooms(previousState, payload);

    case CLEAR_NEW_MESSAGES_IN_ROOM:
      const newRooms = previousState.rooms;
      const index = findIndex(newRooms, { id: payload });
      if (index !== -1) {
        const leave = {
          id: payload,
          count: newRooms[index].count
        };

        delete newRooms[index].count;
        const newState = { rooms: newRooms, leave };

        return newState;
      }

      return previousState;
    default:
      return previousState;
  }
};
