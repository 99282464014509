import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { Grid } from "@material-ui/core";

import ChatRooms from "./ChatRooms";
import ChatMessages from "./ChatMessages";
import { setRooms } from "../../actions/ChatActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { socket } from "../../consts";

import {
  newMessagesInRoom
} from "../../actions/ChatActions";

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  chatCard: {
    margin: 0,
    padding: 0
  },
  chatroomChip: {
    marginTop: 8,
    width: "100%",
    justifyContent: "flex-start"
  },
  chatroomSearchContent: {
    maxHeight: "82vh",
    overflowY: "auto",
    paddingLeft: 8,
    paddingRight: 8
  },
  inline: {
    display: "inline",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap"
  },
  meAvatar: {
    backgroundColor: "primary",
    height: 45,
    width: 45
  },
  messages: {
    paddingLeft: 8,
    paddingRight: 8
  },
  messagesContent: {
    overflowY: "auto",
    maxHeight: "53vh",
    minHeight: "53vh"
  },
  messagesDate: {
    color: grey[500],
    textAlign: "center"
  },
  messagesList: {
    margin: 0,
    padding: 0
  },
  messagesListItem: {
    padding: 0,
    paddingTop: 8,
    alignItems: "flex-start"
  },
  root: {
    margin: 0,
    maxWidth: "350px",
    paddingTop: 8,
    padding: 4,
    maxHeight: "75vh"
  },
  sendMessageContent: {
    maxHeight: "12vh",
    overflowY: "hidden",
    padding: 8,
    background: "#f5f5f5"
  },
  userAvatar: {
    backgroundColor: "lightgreen",
    height: 45,
    width: 45
  },
  cardActions: {},
  chatTitle: {
    fontWeight: "bold"
  }
});

class Chat extends Component {
  state = {
    room: null,
    loading: false,
    message: null
  };

  componentWillMount() {
    const bp = this.props.bp;
    const object = this.props.object;

    socket.instance.emit(
      "get new messages in room",
      this.props.newMessagesInRoom
    );

    if (bp) {
      if (bp.object) {
        const image =
          bp.object.images && bp.object.images.main
            ? bp.object.images.main.thumb
            : null;
        const name = `${(bp.object.street && bp.object.street.name) || ""} ${
          bp.object.house
        }`;

        socket.instance.emit("enter the room", {
          object_id: bp.object.id
        });

        this.setState({
          room: bp.object.id,
          name: name.trim(),
          image
        });
      }
    }

    if (object) {
      const image =
        object.images && object.images.main
          ? object.images.main.thumb
          : null;
      const name = `${(object.street && object.street.name) || ""} ${
        object.house
      }`;

      socket.instance.emit("enter the room", {
        object_id: object.id
      });

      this.setState({
        room: object.id,
        name: name.trim(),
        image
      });
    }

    socket.instance.on("chat rooms", this.handleReceiveRooms);
    this.fetchRooms(null, null);
  }

  componentWillUnmount() {
    socket.instance.off("chat rooms", this.handleReceiveRooms);
  }

  fetchRooms = (street, type) => {
    this.setState({
      loading: true
    });
    socket.instance.emit("chat rooms", {street, type});
    socket.instance.emit(
      "get new messages in room",
      this.props.newMessagesInRoom
    );
  };

  handleReceiveRooms = data => {
    if (data) {
      this.props.setRooms(data);
      this.setState({
        loading: false
      });
    }
  };

  handleClickSearchRoom = street => {
    this.fetchRooms(street, 's');
  };

  handleClickRoom = (id, name, image) => () => {
    socket.instance.emit("enter the room", {
      object_id: id
    });

    this.setState({
      room: id,
      name,
      image
    });
  };

  handleClickBack = room => () => {
    socket.instance.emit("leave the room", {
      object_id: room
    });
    this.setState({
      room: null
    });
  };

  render() {
    const { classes, rooms } = this.props;
    return (
      <div className={classes.root}>
        {this.state.loading && <LinearProgress variant="query" />}
        <Grid container spacing={8} className={classes.container}>
          {!this.state.room &&
            rooms && (
              <ChatRooms
                handleClickRoom={this.handleClickRoom}
                handleClickSearchRoom={this.handleClickSearchRoom}
                rooms={rooms}
                {...this.props}
              />
            )}
          {this.state.room && (
            <ChatMessages
              handleClickBack={this.handleClickBack}
              room={this.state.room}
              name={this.state.name}
              image={this.state.image}
              {...this.props}
            />
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rooms: state.chat.rooms,
  bp: state.bp,
  object: state.contentBar.object
});

export default compose(
  connect(
    mapStateToProps,
    { setRooms, newMessagesInRoom }
  ),
  withStyles(styles)
)(Chat);
