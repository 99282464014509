import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import moment from "moment";
import 'moment/locale/ru';

import {
  List,
  Pagination,
  FunctionField,
  ReferenceField,
  Link,
  SelectInput,
  Filter,
  AutocompleteArrayInput,
  ReferenceInput,
  EditButton,
  TextField
} from "react-admin";

import GroupDatagrid from "../../components/ra-customizable-datagrid/GroupDatagrid";
import ChipInput from "../../components/input/ChipInput";

import { parse, stringify } from "query-string";
import { push } from "react-router-redux";
import { Route } from "react-router";

import NameEdit from "./NameEdit";
import { Drawer } from "@material-ui/core";

import FullNameField from "../../components/field/FullNameField";
import UserQuickPreview from "../../users/UserQuickPreview";
import ObjectModal from "../../objects/ObjectModal";
import Avatar from "@material-ui/core/Avatar";

import AudioPlayer from "react-h5-audio-player";
import get from "lodash/get";

const PBX_URL = "http://78.29.44.162";

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id
    })}`;
    return (
      <div>
        <Link
          to={`/pbx/calls${query}`}
          onClick={ev => {
            ev.stopPropagation();
          }}
        >
          <FullNameField record={record} size={32} />
        </Link>
      </div>
    );
  }
};

const renderPlayer = record => {
  return record.audio_records ? <AudioPlayer src={`${PBX_URL}/?record=${record.audio_records}`} /> : '';
};

const renderObject = location => record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const params = parse(location.search);
  const query = `?${stringify({
    ...params,
    object: record.id
  })}`;
  if (record.images) {
    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");
    return (
      <Link
        to={`/pbx/calls${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          <Avatar alt={street + " " + house} src={src} />
          <span
            style={{
              marginLeft: 3
            }}
          >
            {street} {house}
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/pbx/calls/?object=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span>
          {street} {house}
        </span>
      </Link>
    );
  }
};

const renderContactName = location => record => {
  if (record.section) {
    if (record.section === "objects" && record.object) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FullNameField record={{
            fio: record.object.contact_people
          }} size={32} />
        </div>
      );
    }

    if (record.section === "clients" && record.client) {
      const params = parse(location.search);
      const query = `?${stringify({
        ...params,
        user: record.client.id,
        resource: "clients"
      })}`;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            to={`/pbx/calls${query}`}
            onClick={ev => {
              ev.stopPropagation();
            }}
          >
            <FullNameField record={record.client} size={32} />
          </Link>
          <EditButton label="" to={`/pbx/calls/${record.client.id}`} style={{marginLeft: '8px'}} />
        </div>
      );
    }

    if (record.section === "users" && record.user2) {
      const params = parse(location.search);
      const query = `?${stringify({
        ...params,
        user: record.user2.id,
        resource: "users"
      })}`;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            to={`/pbx/calls${query}`}
            onClick={ev => {
              ev.stopPropagation();
            }}
          >
            <FullNameField record={record.user2} size={32} />
          </Link>
        </div>
      );
    }

    if (record.section === "contractors" && record.contractor) {
      const params = parse(location.search);
      const query = `?${stringify({
        ...params,
        user: record.contractor.id,
        resource: "contractors"
      })}`;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            to={`/pbx/calls${query}`}
            onClick={ev => {
              ev.stopPropagation();
            }}
          >
            <FullNameField record={record.contractor} size={32} />
          </Link>
        </div>
      );
    }
  }

  return "";
};

const renderSection = record => {
  if (record.section) {
    if (record.section === "objects") {
      return "Объекты";
    }

    if (record.section === "clients") {
      return "Клиенты";
    }

    if (record.section === "users") {
      return "Пользователи";
    }

    if (record.section === "contractors") {
      return "Подрядчики";
    }
  }
  return "";
};

const renderCall = record => {
  if (record._call) {
    if (record._call === 1) {
      return "Входящий";
    }

    if (record._call === 2) {
      return "Исходящий";
    }
  }
  return "";
};

const renderStatus = record => {
  if (record.status) {
    if (record.status === "end") {
      return "Успешено";
    }

    if (record.status === "busy") {
      return "Пропущен";
    }
  }
  return null;
};

const renderUserOption = choice => {
  return `${choice.name !== null ? choice.name : ""} ${
    choice.last_name !== null ? choice.last_name : ""
  }`;
};

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.updatedAt)
  const m2 = moment(record.updatedAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const PBXCallsFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="_call"
      choices={[
        { id: "1", name: "Входящий" },
        { id: "2", name: "Исходящий" }
      ]}
    />
    <SelectInput
      source="section"
      choices={[
        { id: "clients", name: "Клиенты" },
        { id: "objects", name: "Объекты" },
        { id: "users", name: "Пользователи" },
        { id: "contractors", name: "Подрядчики" }
      ]}
    />
    <SelectInput
      source="status"
      choices={[
        { id: "end", name: "Успешно" },
        { id: "busy", name: "Пропущен" }
      ]}
    />
    <ReferenceInput
      source="client_id"
      label="resources.objects.input.clients"
      reference="clients"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput />
      </ChipInput>
    </ReferenceInput>
    <ReferenceInput
      source="user_id"
      label="resources.objects.input.manager"
      reference="users"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput optionText={renderUserOption} />
      </ChipInput>
    </ReferenceInput>
  </Filter>
);

const PbxCallsTable = props => (
  <GroupDatagrid {...props} rowClick={null}>
    <FunctionField source="updatedAt" style={{ fontStyle: "italic" }} render={renderDate} />
    <FunctionField source="_call" render={renderCall} />
    <FunctionField source="section" render={renderSection} />
    <FunctionField source="id" render={renderContactName(props.location)} />
    <ReferenceField source="user_id" reference="users" linkType={false}>
      <FunctionField source="user" render={renderName(props.location)} />
    </ReferenceField>
    <ReferenceField
      source="object_id"
      reference="objects"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="object" render={renderObject(props.location)} />
    </ReferenceField>
    <ReferenceField
      source="task_id"
      reference="tasks"
      linkType={false}
      allowEmpty
    >
      <TextField source="title" />
    </ReferenceField>
    <FunctionField
      source="record"
      render={renderPlayer}
      style={{ width: 280 }}
    />
    <FunctionField source="status" render={renderStatus} />
  </GroupDatagrid>
);

const PbxCallsPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

class PBXCallsList extends Component {
  handleCloseEdit = () => {
    this.props.push("/pbx/calls");
  }
  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.user;
    delete params.object;
    delete params.resource;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };
  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<PbxCallsPagination />}
          perPage={25}
          sort={{ field: "createdAt", order: "DESC" }}
          filters={<PBXCallsFilter />}
        >
          <PbxCallsTable location={props.location} />
        </List>
        <Route path="/pbx/calls">
          {route => {
            const values = parse(route.location.search);
            let id = null;
            let resource = null;

            if (values.object) {
              const object = values.object;
              return (
                <ObjectModal
                  id={object}
                  open={!!object}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            if (values.resource) {
              resource = values.resource;
            }

            if (values.user) {
              id = values.user;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                resource={resource}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
        <Route path="/pbx/calls/:id">
          {({ match }) => {
            const isMatch =  match && match.params && match.params.id;
            return (
              <Drawer
                open={isMatch ? true : false}
                anchor="right"
                onClose={this.handleCloseEdit}
              >
                {isMatch && (
                  <NameEdit
                    id={isMatch ? match.params.id : null}
                    onCancel={this.handleCloseEdit}
                    {...props}
                  />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(PBXCallsList);
