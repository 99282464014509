import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push as pushAction } from "react-router-redux";
import { parse, stringify } from "query-string";
import IconButton from "@material-ui/core/IconButton";
import HeadlineIcon from "@material-ui/icons/ViewHeadline";
import PlaceIcon from "@material-ui/icons/Place";
import ModuleIcon from "@material-ui/icons/ViewModule";

import { changeListParams as changeListParamsAction } from "react-admin";

const inactiveColor = { opacity: 0.54 };
const activeColor = { opacity: 1 };

export class ObjectsToolbar extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired
  };

  state = {
    mode: "table"
  };

  componentDidMount() {
    const { location, page, perPage, sort, resource } = this.props;
    const params = parse(location.search);
    if (params.mode) {
      params.page = page;
      params.perPage = perPage;

      if (!params.sort) {
        params.sort = sort.field;
      }

      if (!params.order) {
        params.order = sort.order;
      }

      if (!params.filter) {
        params.filter = {};
      } else {
        params.filter = JSON.parse(params.filter);
      }

      const query = `?${stringify({
        ...params,
        filter: JSON.stringify(params.filter)
      })}`;

      this.props.push({
        ...this.props.location,
        search: query
      });

      this.props.changeListParams(resource, params);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parse(nextProps.location.search);
    if (params.mode) {
      this.setState({
        mode: params.mode
      });
    } else {
      this.setState({
        mode: "table"
      });
    }
  }

  changeParam = param => {
    const { location, page, perPage, sort, resource } = this.props;
    const params = parse(location.search);
    const newParams = { ...params };

    if (param) {
      params.mode = param;
      newParams.mode = param;
    }

    newParams.page = page;
    newParams.perPage = perPage;

    if (!newParams.sort) {
      newParams.sort = sort.field;
    }

    if (!newParams.order) {
      newParams.order = sort.order;
    }

    if (!newParams.filter) {
      newParams.filter = {};
    } else {
      newParams.filter = JSON.parse(params.filter);
    }

    const query = `?${stringify({
      ...params
    })}`;

    this.props.push({
      ...this.props.location,
      search: query
    });

    this.props.changeListParams(resource, newParams);
  };

  switchTo = to => () => {
    this.changeParam(to);
  };

  render() {
    const { mode } = this.state;
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton
            color="primary"
            style={mode === "table" ? activeColor : inactiveColor}
            onClick={this.switchTo("table")}
          >
            <HeadlineIcon />
          </IconButton>
          <IconButton
            color="primary"
            style={mode === "mapgrid" ? activeColor : inactiveColor}
            onClick={this.switchTo("mapgrid")}
          >
            <PlaceIcon />
          </IconButton>
          <IconButton
            color="primary"
            style={mode === "grid" ? activeColor : inactiveColor}
            onClick={this.switchTo("grid")}
          >
            <ModuleIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  {
    changeListParams: changeListParamsAction,
    push: pushAction
  }
)(ObjectsToolbar);
