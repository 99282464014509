import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  AutocompleteInput,
  translate,
  CardActions,
  ListButton,
  CreateButton,
  CheckboxGroupInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import PhoneInput from "../components/input/PhoneInput";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  contact: {
    marginTop: 16
  },
  checkbox: {
    width: "initial"
  }
});

const renderOptionText = record => {
  if (record.fio) {
    return record.fio;
  }

  return `${record.name !== null ? record.name : ""} ${
    record.last_name ? record.last_name : ""
  }`;
};

const ContractorTitle = ({ record }) =>  record && record.name ? record.name : null;

const ContractorActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <ListButton basePath={basePath} />
  </CardActions>
);

const ContractorEdit = ({ classes, ...props }) => {
  const { translate } = props;

  return (
    <Edit
      {...props}
      title={<ContractorTitle />}
      classes={{ card: classes.card }}
      actions={<ContractorActions />}
    >
      <SimpleForm validate={validate(translate)}>
        <DateInput source="date_contact" />
        <TextInput source="name" />
        <ReferenceInput
          source="user_id"
          reference="users"
          label="resources.contractors.input.user"
        >
          <AutocompleteInput optionText={renderOptionText} />
        </ReferenceInput>
        <ReferenceArrayInput
          source="district_id"
          reference="districts"
          label="resources.contractors.input.districts"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="developer_id"
          reference="developers"
          label="resources.contractors.input.developers"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="inn" label="resources.contractors.input.inn" />
        <SelectInput
          source="state_contractor"
          label="resources.contractors.input.state_contractor"
          choices={[
            { id: 1, name: "Инвестор" },
            { id: 2, name: "Подрядчик" },
            { id: 3, name: "Риэлтор" }
          ]}
        />
        <SelectInput
          source="status_contact"
          label="resources.contractors.input.status_contact"
          choices={[
            { id: 1, name: "Открыть доступ к контактам" },
            { id: 2, name: "Контакты проходят через меня" }
          ]}
        />
        <ArrayInput source="contacts" label="resources.contractors.input.contacts">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource, ...rest }) => {
                return (
                  <Fragment>
                    <TextInput
                      source={getSource("name")}
                      label="resources.contractors.input.name"
                    />
                    <PhoneInput
                      source={getSource("phone")}
                      label="resources.contractors.input.phone"
                    />
                    <TextInput
                      source={getSource("email")}
                      label="resources.contractors.input.email"
                      type="email"
                    />
                    <CheckboxGroupInput
                      source={getSource("type_contact")}
                      choices={[
                        { id: "email", name: "E-mail", value: "1" },
                        { id: "viber", name: "Viber", value: "2" }
                      ]}
                      label="resources.contractors.input.type_contact"
                      id="type_contact"
                      optionValue="value"
                    />
                    <CheckboxGroupInput
                      source={getSource("priority")}
                      choices={[{ id: "ismain", name: "Да", value: "1" }]}
                      label="resources.contractors.input.ismain"
                      id="priority"
                      optionValue="value"
                    />
                  </Fragment>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(ContractorEdit);
