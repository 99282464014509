import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { push as pushAction } from "react-router-redux";
import { parse, stringify } from "query-string";
import {
  YMaps,
  Map,
  Clusterer,
  Placemark,
  Polygon,
  Button
} from "react-yandex-maps";
import { changeListParams as changeListParamsAction } from "react-admin";
import get from "lodash/get";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden"
  }
});

const getPointData = data => {
  const street = get(data, "street.name") || "";
  const house = get(data, "house") || "";

  return {
    balloonContentBody: `<span>${street} ${house}</span>, Цена/м2:<strong>${data.cost_of_owner} / ${(data.cost_of_owner / data.area).toFixed(2)}</strong>`,
    clusterCaption: `<span>${street} ${house}</span>, Цена/м2:<strong>${data.cost_of_owner} / ${(data.cost_of_owner / data.area).toFixed(2)}</strong>`
  };
};

export class MapObjects extends Component {
  static propTypes = {
    ids: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    resource: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired
  };

  state = {
    center: [55.174962, 61.416323],
    zoom: 9,
    behaviors: ["default", "scrollZoom"],
    controls: ["fullscreenControl"],
    isEditPoly: false,
    geometry: [],
    mapFixed: null,
    offsetMap: null,
    sidebarOpen: true,
    mapInstance: null
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.mapInstance) {
      setTimeout(() => {
        if (this.state.mapFixed) {
          const {
            width,
            height
          } = this.mapRoot.parentNode.getBoundingClientRect();
          this.setState(state => ({
            mapFixed: {
              ...state.mapFixed,
              width: width - 8,
              height
            }
          }));
        }

        this.state.mapInstance.container.getElement().style.width = "100%";
        this.state.mapInstance.container.fitToViewport();
      }, 200);
    }
  }

  componentWillMount() {
    const params = parse(this.props.location.search);
    if (params.filter) {
      const filter = JSON.parse(params.filter);
      if (filter.polygon) {
        this.setState({
          geometry: filter.polygon
        });
      }
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = ev => {
    if (!this.state.offsetMap) {
      const mapRect = this.mapRoot.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const offset = mapRect.top - bodyRect.top;

      this.setState({
        offsetMap: offset,
        mapRect
      });
    }

    if (this.state.offsetMap) {
      const scrollY = window.scrollY;

      if (scrollY + 48 >= this.state.offsetMap && !this.state.mapFixed) {
        const { width, height } = this.mapRoot.getBoundingClientRect();
        this.setState({
          mapFixed: {
            position: "fixed",
            top: 48,
            width,
            height
          }
        });
      }

      if (scrollY + 48 < this.state.offsetMap && this.state.mapFixed) {
        this.setState({
          mapFixed: null
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  changeFilter = param => {
    const params = parse(this.props.location.search);

    if (!params.sort) {
      params.sort = "createdAt";

      params.order = "DESC";
    }

    if (!params.page && !params.perPage) {
      params.page = this.props.page;
      params.perPage = this.props.perPage;
    }

    let filter = {};

    if (params.filter) {
      filter = JSON.parse(params.filter);
    }

    filter = {
      ...filter,
      ...param
    };

    params.filter = JSON.stringify(filter);

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });

    params.filter = filter;

    this.props.changeListParams(this.props.resource, params);
  };

  togglePolyEdit = () => {
    this.setState({
      isEditPoly: !this.state.isEditPoly
    });
  };

  clearPolygon = () => {
    this.setState({
      geometry: []
    });

    this.changeFilter({
      polygon: []
    });
  };

  handleClick = e => {
    if (this.state.isEditPoly) {
      this.setState({
        geometry: [...this.state.geometry, e.get("coords")]
      });

      if (this.state.geometry.length >= 3) {
        this.changeFilter({
          polygon: this.state.geometry
        });
      }
    }
  };

  createMapRootRef = node => (this.mapRoot = node);

  createMapInstanceRef = instance => {
    if (!this.state.mapInstance) {
      this.setState({
        mapInstance: instance
      });
    }
  };

  render() {
    const { ids, data, classes } = this.props;
    return (
      <div
        ref={this.createMapRootRef}
        className={classes.root}
        style={this.state.mapFixed || null}
      >
        <YMaps
          query={{
            ns: "use-load-option",
            load:
              "control.FullscreenControl,geoObject.addon.balloon,geometryEditor.Polygon"
          }}
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          <Map
            state={this.state}
            onClick={this.handleClick}
            style={{
              width: "100%",
              height: "100%"
            }}
            instanceRef={this.createMapInstanceRef}
          >
            {this.state.geometry.length > 0 &&
              this.state.isEditPoly && (
                <Button
                  data={{ content: '<i class="material-icons">clear</i>' }}
                  onClick={this.clearPolygon}
                  options={{
                    selectOnClick: false
                  }}
                />
              )}
            <Button
              data={{ content: '<i class="material-icons">timeline</i>' }}
              onClick={this.togglePolyEdit}
            />
            <Clusterer
              options={{
                preset: "islands#invertedVioletClusterIcons",
                groupByCoordinates: false,
                clusterDisableClickZoom: true,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false
              }}
            >
              {ids.map(id => {
                return (
                  <Placemark
                    key={id}
                    geometry={{
                      type: "Point",
                      coordinates: [data[id].lat, data[id].lon]
                    }}
                    properties={getPointData(data[id])}
                    options={{
                      preset: !data[id].deletedAt ? "islands#darkBlueDotIcon" : "islands#redDotIcon"
                    }}
                  />
                );
              })}
            </Clusterer>
            {this.state.isEditPoly && (
              <Polygon
                geometry={[this.state.geometry]}
                options={{
                  fillColor: "#283593",
                  strokeColor: "#001cb9",
                  opacity: 0.5,
                  strokeWidth: 3,
                  strokeStyle: "shortdash",
                  editorDrawingCursor: "crosshair",
                  editorMaxPoints: 9
                }}
              />
            )}
          </Map>
        </YMaps>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location,
  sidebarOpen: state.admin.ui.sidebarOpen
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeListParams: changeListParamsAction,
      push: pushAction
    }
  ),
  withStyles(styles)
)(MapObjects);
