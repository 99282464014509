import React, { Component } from "react";

import {
  List,
  Pagination,
  TextField,
  DateField,
  FunctionField,
  Filter,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput
} from "react-admin";
import { connect } from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";

import CustomizableDatagrid from "../components/ra-customizable-datagrid";

import { sections } from "../consts";
import { find } from "lodash";

import ChipInput from "../components/input/ChipInput";

const filterStyles = {
};

const renderSection = record => {
  let name = record.section;

  const section = find(sections, {
    id: name
  });
  if (section) {
    name = section.name;
  }

  return name;
}

export const ScriptsFilter = withStyles(filterStyles)(
  ({ classes, ...props }) => {
    return (
      <Filter {...props}>
        <ReferenceArrayInput
          source="cat_ids"
          label="resources.objects.input.categories"
          reference="categories"
          allowEmpty={false}
        >
          <ChipInput>
            <AutocompleteArrayInput />
          </ChipInput>
        </ReferenceArrayInput>
        <ChipInput
          source="section"
          label="resources.tasks.input.section"
          isTranslated={false}
        >
          <SelectInput
            choices={sections}
          />
        </ChipInput>
      </Filter>);
  }
);

const ScriptsTable = props => (
  <CustomizableDatagrid {...props} rowClick="show" showGroup={false}>
    <DateField source="createdAt" />
    <TextField source="name" />
    <FunctionField source="section" render={renderSection} />
  </CustomizableDatagrid>
);

const ScriptsPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10]} {...props} />
);

class ScriptsList extends Component {

  render() {
    const props = this.props;
    return (
        <List
          {...props}
          filters={<ScriptsFilter />}
          pagination={<ScriptsPagination />}
          perPage={5}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <ScriptsTable location={props.location} />
        </List>
    );
  }
}

const mapStateToProps = state => ({ });

export default connect(
  mapStateToProps,
  { }
)(ScriptsList);
