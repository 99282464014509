import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Route } from "react-router";
import { Button } from "react-admin";
import isEmpty from "lodash/isEmpty";
import IconClose from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { parse, stringify } from "query-string";

import ObjectContent from "./ObjectContent";
import ObjectAnalysis from "./ObjectAnalysis";
import ObjectStats from "./ObjectStats";
import ObjectClients from "./ObjectClients";

const styles = theme => ({
  left: {
    display: "flex",
    justifyContent: "flex-start"
  },
  right: {
    display: "flex",
    justifyContent: "flex-end"
  },
  active: {
    borderBottom: "1px solid",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
});

class ObjectModal extends Component {
  static propTypes = {
    data: PropTypes.object,
    fullScreen: PropTypes.bool.isRequired
  };

  handleClickTab = name => () => {
    const { push, location } = this.props;
    const params = parse(location.search);

    push(
      `?${stringify({
        ...params,
        tab: name
      })}`
    );
  };

  render() {
    const { fullScreen, open, id, handleClose, data, classes, location, permissions } = this.props;

    let tab = null;
    if(location){
      const params = parse(location.search);
      tab = params.tab;
    }

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        fullScreen={fullScreen}
        maxWidth="md"
      >
        <DialogActions>
          <Grid container spacing={0}>
            <Grid item xs={8} className={classes.left}>
              <Button
                color="primary"
                label="resources.objects.button.preview"
                onClick={this.handleClickTab()}
                className={!tab ? classes.active : null}
              />
              <Button
                color="primary"
                label="resources.objects.button.legal_analysis"
                onClick={this.handleClickTab("analysis")}
                className={tab === "analysis" ? classes.active : null}
              />
              <Button
                color="primary"
                label="resources.objects.button.stats"
                onClick={this.handleClickTab("stats")}
                className={tab === "stats" ? classes.active : null}
              />
              <Button
                color="primary"
                label="resources.objects.button.clients"
                onClick={this.handleClickTab("clients")}
                className={tab === "clients" ? classes.active : null}
              />
            </Grid>
            <Grid item xs={4} className={classes.right}>
              <Button
                onClick={handleClose}
                color="primary"
                label="ra.action.cancel"
              >
                <IconClose />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {open &&
          data &&
          !isEmpty(data) && (
            <Route path="/objects">
              {route => {
                const params = parse(route.location.search);

                if (params.tab === "analysis") {
                  return <ObjectAnalysis data={data[id]} />;
                }

                if (params.tab === "stats") {
                  return <ObjectStats data={data[id]} />;
                }

                if (params.tab === "clients") {
                  return <ObjectClients />;
                }

                return (
                  <ObjectContent data={data[id]} permissions={permissions} />
                );
              }}
            </Route>
          )}
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  data: state.admin.resources.objects.data
});

export default compose(
  withMobileDialog(),
  withStyles(styles),
  connect(
    mapStateToProps,
    { push }
  )
)(ObjectModal);
