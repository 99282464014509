import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import TasksIcon from "@material-ui/icons/Work";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';

import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
        marginLeft: '0.5em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const OverdueTasksCount = ({ value, translate, classes, style }) => (
    <div className={classes.main} style={style}>
        <CardIcon Icon={TasksIcon} bgColor="#f44336" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                {translate('pos.dashboard.overdue_tasks_count')}
            </Typography>
            <Typography variant="headline" component="h2">
                {value}
            </Typography>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(OverdueTasksCount);
