import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import { API_URL } from "./consts";
import decodeJwt from "jwt-decode";

export default socket => (type, params) => {
  let isAddedUserToChat = false;
  if (type === AUTH_LOGIN) {
    const { login, password } = params;

    const request = new Request(`${API_URL}/users/login`, {
      method: "POST",
      body: JSON.stringify({ login, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem("token", token);

        return Promise.resolve();
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = decodeJwt(token);
      if (!isAddedUserToChat) {
        socket.emit("add user", {
          username: decodedToken.username,
          photo: decodedToken.photo,
          user_id: decodedToken.user_id
        });

        isAddedUserToChat = true;
      }

      return Promise.resolve(token);
    } else {
      return Promise.reject();
    }
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = decodeJwt(token);
      if (!isAddedUserToChat) {
        socket.emit("add user", {
          username: decodedToken.username,
          photo: decodedToken.photo,
          user_id: decodedToken.user_id
        });

        isAddedUserToChat = true;
      }
      return Promise.resolve(decodedToken.userData);
    } else {
      return Promise.reject();
    }
  }
  return Promise.reject("Unkown method");
};
