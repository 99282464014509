import React, { Fragment } from "react";
import { AppBar, UserMenu, MenuItemLink, translate } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import PhoneButton from "./components/button/PhoneButton";
import NotificationsButton from "./components/button/NotificationsButton";
import ChatButton from "./components/button/ChatButton";

const CustomUserMenu = translate(({ translate, ...props }) => (
  <Fragment>
    <ChatButton label="Chat" showBadge={true} />
    <NotificationsButton label="Notifications" />
    <PhoneButton label="Missed Calls" />
    <UserMenu {...props}>
      <MenuItemLink
        to="/configuration"
        primaryText={translate("pos.configuration")}
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  </Fragment>
));

const CustomAppBar = props => (
  <AppBar {...props} userMenu={<CustomUserMenu />} />
);

export default CustomAppBar;
