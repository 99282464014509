import React, { Component } from "react";

export default class Sticky extends Component {
    state = {
      offsetTop: null,
      fixed: false
    };

    stickyElement = () => {
      if (!this.state.offsetTop) {
        const barRect = this.bar.getBoundingClientRect();
        const bodyRect = document.body.getBoundingClientRect();
        const offsetTop = barRect.top - bodyRect.top;
  
        this.setState({
          offsetTop: offsetTop
        });
      }

      const scrollY = window.scrollY;
  
      if (scrollY && scrollY > this.state.offsetTop - 48) {
        this.setState({
          top: scrollY + 48,
          position: "absolute",
          fixed: true
        });
      } else {
        this.setState({
          fixed: false
        });
      }
    }
  
    componentDidMount() {
      this.stickyElement();
      window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
    }
  
    handleScroll = ev => {
      this.stickyElement();
    };
  
    createBarRef = node => (this.bar = node);
  
    render() {
      return (
        <div
          ref={this.createBarRef}
          style={
            this.state.fixed
              ? {
                  top: this.state.top,
                  position: this.state.position,
                  width: "calc(100% - 24px)"
                }
              : null
          }
        >
          {this.props.children}
        </div>
      );
    }
  }