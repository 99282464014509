import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  translate,
  CardActions,
  ListButton,
  SelectInput,
  FormDataConsumer
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import { sections } from "../consts";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  }
});


const TaskActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const optionRenderer = choice => `${choice.name !== null ? choice.name : ''} ${choice.last_name !== null ? choice.last_name : ''}`;

const TaskTemplateCreate = ({ classes, ...props }) => {
  const { translate } = props;

  return (
    <Create
      {...props}
      classes={{ card: classes.card }}
      actions={<TaskActions />}
    >
      <SimpleForm validate={validate(translate)}>
        <TextInput source="title" label="resources.tasks.input.title" />
        <SelectInput
          source="section"
          label="resources.tasks.input.section"
          resettable={true}
          choices={sections}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { section } = formData;

            if (section === "objects") {
              return <ReferenceInput
                label="resources.objects.input.categories"
                source="category_id"
                reference="categories"
                resource="categories"
                {...rest}
              >
                <SelectInput
                  resettable={true}
                  optionText="name"
                />
              </ReferenceInput>
            }

            return null
          }}
        </FormDataConsumer>
        <ReferenceInput
          source="to_id"
          reference="users"
          label="resources.tasks.input.to_id"
          allowEmpty={true}
        >
          <AutocompleteInput optionText={optionRenderer} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return <ReferenceInput
              source="script_id"
              reference="scripts"
              label="resources.tasks.input.script_id"
              filter={{ cat_ids: formData.category_id ? [formData.category_id] : null }}
              allowEmpty={true}
              {...rest}
            >
              <AutocompleteInput />
            </ReferenceInput>
          }}
        </FormDataConsumer>
        <NumberInput source="deadline_d" label="resources.tasks-templates.input.deadline_d" />
        <NumberInput source="deadline_h" label="resources.tasks-templates.input.deadline_h" />
        <NumberInput source="deadline_m" label="resources.tasks-templates.input.deadline_m" />
        <RichTextInput
          source="description"
          label="resources.tasks.input.description"
        />
      </SimpleForm>
    </Create>
  );
};

export default compose(
  withStyles(styles),
  translate
)(TaskTemplateCreate);
