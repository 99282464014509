import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ResizeObserver from "resize-observer-polyfill";
import Masonry from "react-masonry-component";
import MobileStepper from "@material-ui/core/MobileStepper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const styles = theme => ({
  root: {
    position: "relative"
  },
  image: {
    objectFit: "cover",
    height: 160,
    width: "auto",
    cursor: "pointer",
    margin: 2,
    zIndex: 1
  },
  mobileStepper: {
    position: "absolute",
    background: "transparent",
    top: "calc(50% - 32px)",
    width: "98%",
    left: 0
  },
  button: {
    zIndex: 2
  },
  arrowIcon: {
    fontSize: 40
  }
});

const masonryOptions = {
  transitionDuration: 0
};

class GallerySlider extends Component {
  static propTypes = {
    photos: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    classes: PropTypes.object.isRequired
  };

  state = {
    containerWidth: 0,
    activeStep: 0,
    slides: [],
    count: 0
  };

  componentDidMount() {
    this.animationFrameID = null;
    this.observer = new ResizeObserver(entries => {
      const newWidth = entries[0].contentRect.width;
      if (this.state.containerWidth !== newWidth) {
        this.animationFrameID = window.requestAnimationFrame(() => {
          this.setState({ containerWidth: Math.floor(newWidth) });
        });
      }
    });
    this.observer.observe(this._gallery);

    const { slides, count } = this.generateSlides(
      this.props.photos,
      this.props.classes
    );

    this.setState({ slides, count });
  }

  componentWillUnmount() {
    this.observer.disconnect();
    window.cancelAnimationFrame(this.animationFrameID);
  }

  handleClick = (photo, index) => event => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event, { photo, index });
    }
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  };

  generateSlides = (photos, classes) => {
    let width = 0;
    let slides = [];
    let count = 0;
    const small_images = [];

    for(let i = 0; i < photos.length; i++){
      width += photos[i].w;

      if (!slides[count]) {
        slides[count] = [];
      }

      slides[count][i] = (
        <img
          src={photos[i].src}
          alt={photos[i].alt}
          onClick={this.handleClick(photos[i], i)}
          key={i}
          className={classes.image}
        />
      );

      if(photos[i].w === 110){
        small_images.push(i);
      }

      // Переносим лишний в конец
      if(width > 1792){
        const s_img_key = small_images[small_images.length - 1];
        photos.push(photos[s_img_key]);
        slides[count].splice(s_img_key, 1);
      }

      if (width >= 1760) {
        count++;
        width = 0;
      }
    };

    return { slides, count };
  };

  createGalleryRef = c => {
    this._gallery = c;
  };

  render() {
    const { classes } = this.props;
    const { containerWidth, slides, count } = this.state;
    if (!containerWidth) return <div ref={this.createGalleryRef}>&nbsp;</div>;

    return (
      <div className={classes.root} style={{ width: containerWidth }}>
        <Masonry
          className={"my-gallery-class"}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
          options={masonryOptions}
          key={this.state.activeStep}
        >
          {slides[this.state.activeStep]}
        </Masonry>
        {count > 0 && (
          <MobileStepper
            steps={count}
            position="static"
            activeStep={this.state.activeStep}
            className={classes.mobileStepper}
            variant="text"
            nextButton={
              <IconButton
                color="primary"
                onClick={this.handleNext}
                disabled={this.state.activeStep === count}
                className={classes.button}
              >
                <KeyboardArrowRight className={classes.arrowIcon} />
              </IconButton>
            }
            backButton={
              <IconButton
                color="primary"
                onClick={this.handleBack}
                disabled={this.state.activeStep === 0}
                className={classes.button}
              >
                <KeyboardArrowLeft className={classes.arrowIcon} />
              </IconButton>
            }
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(GallerySlider);
