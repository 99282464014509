import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { change, getFormValues } from "redux-form";
import { REDUX_FORM_NAME, GET_LIST } from "react-admin";
import { CheckboxGroupInput } from "react-admin";
import dataProviderFactory from "../dataProvider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: 16
  },
  ProgressRoot: {
    display: "flex",
    justifyContent: "center"
  },
  column: {
    flexBasis: "50%",
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    alignItems: "center",
    display: "flex"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  }
});

class RoleAccesses extends Component {
  fetchRoleAccesses = (role_id, perm_id) => {
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(GET_LIST, `perms/role/${role_id}`, {
          filter: { perm_id },
          sort: {},
          pagination: {}
        }).then(({ data }) => {
          this.changeCheckboxes(data);
        });
      }
    );
  };

  changeCheckboxes = data => {
    const { change } = this.props;

    if (data && data.length > 0) {
      data.forEach(item => {
        const id = item.permissions.id;
        const access = [];
        delete item.permissions;

        for (let flag in item) {
          if (item[flag] === 1) {
            access.push(flag);
          }
        }

        change(REDUX_FORM_NAME, `accesses.id_${id}`, { access });
      });
    }
  };

  handleExpChange = perm_id => (ev, expanded) => {
    const { record, values } = this.props;

    if (record.id && expanded) {
      if (
        !values.accesses ||
        (values.accesses && !values.accesses[`id_${perm_id}`])
      ) {
        this.fetchRoleAccesses(record.id, perm_id);
      }
    }
  };

  render() {
    const { classes, perms } = this.props;

    return (
      <div className={classes.root}>
        {perms.map((perm, key) => {
          const choices = [
            {
              id: "r",
              name: "ra.input.checkbox.read"
            },
            {
              id: "c",
              name: "ra.input.checkbox.create"
            },
            {
              id: "u",
              name: "ra.input.checkbox.update"
            },
            {
              id: "d",
              name: "ra.input.checkbox.delete"
            }
          ];

          return (
            <ExpansionPanel key={key} onChange={this.handleExpChange(perm.id)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{perm.name}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.column}>
                  <CheckboxGroupInput
                    source={`accesses.id_${perm.id}.access`}
                    id={perm.slug}
                    label=""
                    choices={choices}
                  />
                </div>
                <div className={classNames(classes.column, classes.helper)}>
                  <Typography className={classes.heading}>
                    {perm.description}
                  </Typography>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  values: getFormValues(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RoleAccesses);
