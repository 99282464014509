export default function ObjectStatuses (record) {
  return (
    // Продано
    record.autocallStatus &&
    record.autocallStatus.toString().includes('button=2') &&
    1
  ) || (
    // Аванс / задаток (под авансом 3 / снято 4)
    record.autocallStatus &&
    (
      record.autocallStatus.toString().includes('button=3') ||
      record.autocallStatus.toString().includes('button=4')
    ) &&
    2
  ) || (
    // Запощено
    record.objposters &&
    null !== record.objposters.url &&
    '' !== record.objposters.url &&
    1 === record.objposters.statusConfirmed &&
    4
  ) || (
    // Отредактировать
    (
      (
        record.objposters &&
        null !== record.objposters.url &&
        2 === record.objposters.statusConfirmed &&
        (null === record.objposters.statusAccount || 0 === record.objposters.statusAccount)
      // ) || (
      //   2 === record.statusConfirmed
      ) || (
        record.objposters &&
        record.attempts > 2
      ) || (
        record.autocallStatus &&
        (
          record.autocallStatus.toString().includes('button=1')
        ) && (
          !record.objposters ||
          (record.objposters &&
          null === record.objposters.statusConfirmed)
        ) && !(
          (
            record.objcmi &&
            record.objcmi.length
          ) || 
          (
            record.cmi_id &&
            record.cmi_id.length
          )
        )
      )
    ) &&
    6
  ) || (
    // Попытки закончились или завершен без действия клиента
    (
      (
        record.autocallStatus &&
        (
          record.autocallStatus.toString().includes('status=compl_finished') ||
          record.autocallStatus.toString().includes('status=attempts_exc')
        ) &&
        (
          !record.objposters ||
          (record.objposters &&
          null === record.objposters.statusConfirmed)
        )
      )
    ) &&
    8
  ) || (
    // Отпраавлено на постинг
    (
      (
        (
          record.objcmi &&
          record.objcmi.length
        ) || 
        (
          record.cmi_id &&
          record.cmi_id.length
        )
      ) || (
        record.objposters &&
        // record.objposters.postedAt &&
        1 !== record.objposters.statusConfirmed
      )
    ) &&
    3
  ) || (
    // Ушло на автообзвон
    (
      2 === record.statusConfirmed ||
      null !== record.autocallAt
    ) &&
    7
  ) || (
    // Упало
    (
      20 === record.statusConfirmed
    ) &&
    5
  ) || null;
}
