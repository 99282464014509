import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "redux-form";
import { translate } from "react-admin";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8
  }
});

function TextMaskCustom(props) {
  const { inputRef, value, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        "+",
        "7",
        "(",
        /\S/,
        /\S/,
        /\S/,
        ")",
        " ",
        /\S/,
        /\S/,
        /\S/,
        "-",
        /\S/,
        /\S/,
        /\S/,
        /\S/
      ]}
      placeholderChar={"\u2000"}
      value={value}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

class TextMaskInput extends React.Component {
  state = {
    textmask: "+7(   )   -  -  "
  };

  handleChange = name => event => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }

    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, label, source, translate, meta, value, style } = this.props;
    const { textmask } = this.state;

    const { touched, error } = meta;

    const placeholder = translate(label || source);

    return (
      <FormControl className={classes.formControl} style={style}>
        <TextField
          value={textmask}
          onChange={this.handleChange("textmask")}
          id="formatted-text-mask-input"
          InputProps={{
            inputComponent: TextMaskCustom,
            value: value || textmask
          }}
          label={placeholder}
          error={!!(touched && error)}
          helperText={touched && error}
          disabled={value.indexOf('*') > -1}
        />
      </FormControl>
    );
  }
}

TextMaskInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool
};

const InputMask = props => {
  const { input, meta, ...custom } = props;

  return <TextMaskInput {...props} {...input} {...custom} />;
};

InputMask.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

const PhoneInput = props => {
  const { source } = props;
  return <Field component={InputMask} name={source} {...props} />;
};

const enhance = compose(
  withStyles(styles),
  translate
);

export default enhance(PhoneInput);
