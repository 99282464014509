import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { GET_LIST, translate, REDUX_FORM_NAME } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import { Field, formValueSelector, getFormMeta, change } from "redux-form";
import { FieldTitle } from "ra-core";
import dataProviderFactory from "../../dataProvider";

const renderTextField = ({ input, inputProps, meta }) => {
  const {
    classes,
    onChange,
    onBlur,
    onFocus,
    inputRef = () => {},
    name,
    ref,
    showErrors,
    ...other
  } = inputProps;
  const { touched, error } = meta;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      error={!!(showErrors && touched && error)}
      helperText={!!(showErrors && touched && error) ? error : ""}
      {...input}
      {...other}
    />
  );
};

const renderInputComponent = inputProps => {
  const { source, label, onChange, onBlur, onFocus } = inputProps;
  return (
    <Field
      inputProps={inputProps}
      name={source}
      label={label}
      component={renderTextField}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
};

const getSuggestionValue = suggestion => {
  return suggestion.name;
};

const styles = theme => ({
  root: {
    marginTop: 16,
    marginBottom: 8,
    width: 256
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
});

class SearchInput extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    lable: PropTypes.string,
    handleChange: PropTypes.func,
    getCountSuggestions: PropTypes.func,
    showErrors: PropTypes.bool
  };

  static defaultProps = {
    showErrors: true
  };

  static source = "";

  state = {
    value: "",
    suggestions: [],
    isLoading: false,
    height: 0
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { source } = this.props;
    const { fields } = nextProps;

    let street = nextProps.street;

    if (street) {

      if (street.name) {
        street = street.name;
      }

      const meta = fields[source];

      if (
        meta &&
        street.length >= 3 &&
        !meta.active &&
        meta.visited &&
        meta.touched &&
        street !== this.state.value
      ) {
        this.setState({
          value: street
        });
      }
    } else {
      this.setState({
        value: ""
      });
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { getCountSuggestions, reference } = this.props;

    let street = this.props.street;

    if (street) {
      if (street.name) {
        street = street.name;
      }
    }

    let length = value.length;

    if (value.indexOf(";") > 0) {
      const values = value.split(";");
      length = values[values.length - 1].length;
    }

    if (length >= 3 && value !== street) {
      this.setState({
        isLoading: true
      });
      setTimeout(
        function() {
          dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
            dataProvider => {
              dataProvider(GET_LIST, reference.replace(".", "/"), {
                filter: { q: value },
                sort: { field: "createdAt", order: "DESC" },
                pagination: { page: 1, perPage: 25 }
              }).then(({ data, total }) => {
                this.setState({
                  suggestions: data,
                  isLoading: false
                });

                if (getCountSuggestions) {
                  getCountSuggestions(data.length);
                }
              });
            }
          );
        }.bind(this),
        500
      );
    } else {
      if (getCountSuggestions) {
        getCountSuggestions(0);
      }
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (method === "click") {
      const { change, source } = this.props;
      change(REDUX_FORM_NAME, source + "_id", suggestion.id);
    }
  };

  handleChange = name => (event, { newValue }) => {
    if (this.props.handleChange) {
      this.props.handleChange(event, newValue, name);
    }

    if (event.type === "click") {
      const value = this.state[name];
      if (value.indexOf(";") > 0) {
        const values = value.split(";");

        values[values.length - 1] = newValue;

        newValue = values.join(";");
      }
    }

    this.setState({
      [name]: newValue
    });
  };

  render() {
    const {
      classes,
      label,
      source,
      translate,
      showErrors,
      street
    } = this.props;
    const { isLoading } = this.state;

    let value = "";

    if (street) {
      if (street.name) {
        value = street.name;
      } else {
        value = street;
      }
    }

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <FormControl className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: <FieldTitle label={translate(label)} source={source} />,
            value: this.state.value || value,
            source: source,
            onChange: this.handleChange("value"),
            showErrors
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => {
            return (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            );
          }}
        />
        {isLoading && <LinearProgress />}
      </FormControl>
    );
  }
}

const mapStateToProps = (state, props) => ({
  fields: getFormMeta(REDUX_FORM_NAME)(state),
  [props.source]: formValueSelector(REDUX_FORM_NAME)(
    state,
    props.source
  )
});

const mapDispatchToProps = {
  change
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  translate
);

export default enhance(SearchInput);
