import React, { Component } from 'react';
import T from 'prop-types';

import { FieldTitle } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import FormGroup from '@material-ui/core/FormGroup';

class SelectionDialog extends Component {
  onColumnClicked = ({ target: { value: columnName } }) => {
    this.props.onColumnClicked(columnName);
  };

  render() {
    const { columns, selection, open, handleClose, resource, anchorEl } = this.props;

    return (
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <FormGroup style={{paddingLeft: 10, paddingRight: 10}}>
          {columns.map(({ source, label }) => (

            <FormControlLabel
              key={source}
              control={
                <Checkbox
                  checked={!!selection[source]}
                  onChange={this.onColumnClicked}
                  value={source}
                />
              }
              label={<FieldTitle label={`table.filter.selection.${label}`} source={source} resource={resource} />}
            />

          ))}
        </FormGroup>
      </Menu>
    );
  }
}

SelectionDialog.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    }),
  ).isRequired,
  selection: T.object,
};

SelectionDialog.defaultProps = {
  columns: []
};

export default SelectionDialog;

