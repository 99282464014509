import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  translate,
  CardActions,
  CreateButton,
  ListButton,
  ShowButton,
  SelectInput,
  FormDataConsumer
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import { sections } from "../consts";

const validateObjectCreation = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  }
});

const optionRenderer = choice => `${choice.name !== null ? choice.name : ''} ${choice.last_name !== null ? choice.last_name : ''}`;
const optionRenderer2 = object => {
  const name = [];

  if (object.street) name.push(object.street.name.replace("ул ", ""))
  if (object.house) name.push(object.house)
  if (object.flat) name.push(object.flat)
  if (object.entrance) name.push("п. " + object.entrance)

  return name.join(" ")
};

const TaskActions = ({ basePath, data }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
  </CardActions>
);

const TaskTitle = ({ record }) => record.title || "";

const TaskEdit = ({ classes, ...props }) => {
  const { translate } = props;

  return (
    <Edit {...props} classes={{ card: classes.card }} title={<TaskTitle />} actions={<TaskActions />}>
      <SimpleForm validate={validateObjectCreation(translate)}>
        <TextInput source="title" label="resources.tasks.input.title" />
        <SelectInput
          source="section"
          label="resources.tasks.input.section"
          resettable={true}
          choices={sections}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { section } = formData;

            if (section === "objects") {
              return <ReferenceInput
                label="resources.objects.input.categories"
                source="category_id"
                reference="categories"
                resource="categories"
                {...rest}
              >
                <SelectInput
                  resettable={true}
                  optionText="name"
                />
              </ReferenceInput>
            }

            return null
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return <ReferenceInput
              source="script_id"
              reference="scripts"
              label="resources.tasks.input.script_id"
              filter={{ cat_ids: formData.category_id ? [formData.category_id] : null }}
              allowEmpty={true}
              {...rest}
            >
              <AutocompleteInput />
            </ReferenceInput>
          }}
        </FormDataConsumer>
        <ReferenceInput
          source="to_id"
          reference="users"
          label="resources.tasks.input.to_id"
        >
          <AutocompleteInput optionText={optionRenderer} />
        </ReferenceInput>
        <ReferenceInput
          source="client_id"
          reference="clients"
          label="resources.tasks.input.client_id"
          allowEmpty={true}
        >
          <AutocompleteInput />
        </ReferenceInput>
        <ReferenceInput
          source="object_id"
          reference="objects"
          label="resources.tasks.input.object_id"
          allowEmpty={true}
          filter={{ deletedAt: 0}}
        >
          <AutocompleteInput optionText={optionRenderer2} />
        </ReferenceInput>
        <NumberInput source="deadline_d" label="resources.tasks.input.deadline_d" />
        <NumberInput source="deadline_h" label="resources.tasks.input.deadline_h" />
        <NumberInput source="deadline_m" label="resources.tasks.input.deadline_m" />
        <RichTextInput
          source="description"
          label="resources.tasks.input.description"
        />
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(TaskEdit);
