import React from "react";
import {
  Edit,
  SimpleForm,
  translate,
  CardActions,
  ListButton,
  TextInput,
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({});

const SeriesTitle = ({ record }) =>
  record && record.name ? record.name : null;

const SeriesActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const SeriesEdit = ({ classes, ...props }) => {
  const { translate } = props;

  return (
    <Edit
      {...props}
      title={<SeriesTitle />}
      classes={{ card: classes.card }}
      actions={<SeriesActions />}
    >
      <SimpleForm validate={validate(translate)}>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(SeriesEdit);
