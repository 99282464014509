import React from "react";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import SearchInput from "../components/input/SearchInput";
import SearchSimilarObjectsButton from "./SearchSimilarObjectsButton";
import AddStreetButton from "./AddStreetButton";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  }
});

const ObjectReferenceInput = ({ classes, ...props }) => (
  <div className={classes.root}>
    <SearchInput
      source="street"
      label="resources.objects.input.q"
      reference="streets.search"
    />
    <AddStreetButton />
    <SearchSimilarObjectsButton
      source="street"
      label="resources.objects.input.q"
      reference="streets.search"
    />
  </div>
);

export default compose(withStyles(styles))(ObjectReferenceInput);
