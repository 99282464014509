import React, { Component } from "react";

import {
  Button,
  withDataProvider,
  showNotification,
  GET_ONE,
  GET_LIST,
  Datagrid,
  FunctionField
} from "react-admin";

import { connect } from "react-redux";
import { compose } from "redux";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  withStyles
} from "@material-ui/core";

import SaleScriptHistory from "../components/bp/SaleScriptHistory";

import AudioPlayer from "react-h5-audio-player";

import keyBy from "lodash/keyBy";
import moment from "moment";
import 'moment/locale/ru';

const styles = theme => ({
  title: {
    fontSize: "1.2rem"
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0,
    ['@media (max-width:475px)']: {
      maxWidth: 272,
      overflow: 'auto'
    },
  },
  callsGrid: {
    marginTop: 16
  }
});

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.updatedAt)
  const m2 = moment(record.updatedAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const PBX_URL = "http://78.29.44.162";
const renderPlayer = record => {
  return record.audio_records ? <AudioPlayer src={`${PBX_URL}/?record=${record.audio_records}`} /> : '';
};

const renderClientName= record => {
  if (record.client && record.client.fio) {
    return record.client.fio;
  } else {
    return "";
  }
};

class Aside extends Component {
  state = {
    loadingScript: false,
    loadingTask: false,
    recent_script: null,
    task: null
  };

  async fetchTask () {
    const { dataProvider, id } = this.props;

    this.setState({
      loadingTask: true
    });

    dataProvider(GET_ONE, "tasks", {
      id
    }).then(({ data }) => {
      this.setState({
        loadingTask: false
      });

      if (data) {
        this.setState({
          task: data
        });
      }
    });
  }

  async fetchCalls () {
    const { dataProvider, id } = this.props;

    const { data, total } = await dataProvider(GET_LIST, 'pbx/calls', {
      filter: { task_id: id },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 1000 },
    });

    if (data && data.length) {
      this.setState({
        calls: data
      });
    }
  }

  async fetchScript() {
    const { dataProvider, id } = this.props;

    this.setState({
      loadingScript: true
    });

    dataProvider("GET", "sale-scripts-history/bp", {
      resource: "",
      id
    }).then(({ data }) => {
      this.setState({
        loadingScript: false
      });

      if (data.success) {
        if (data.recent_script) {
          this.setState({
            recent_script: data.recent_script,
            name: data.sale_scripts.name
          });
        }
      }
    });
  }

  componentDidMount () {
    this.fetchTask();
    this.fetchScript();
    this.fetchCalls();
  }

  render () {
    const { classes, handleClose } = this.props;

    return (
      <Drawer
        open={true}
        anchor="right"
        onClose={handleClose}
        >
        <div>
          <Button label="ra.action.close" onClick={handleClose}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <CardHeader
          title="Задача"
          subheader={this.state.task && this.state.task.title}
        />
        <DialogContent>
          {this.state.recent_script &&
            <SaleScriptHistory
              script={this.state.recent_script}
              name={this.state.name}
            />
          }
          {this.state.calls &&
            <Grid item xs={12} className={classes.callsGrid}>
              <Card className={classes.scriptResult}>
                <CardHeader 
                  title="Звонки"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent className={classes.cardContent}>
                  <Datagrid
                    data={keyBy(this.state.calls, 'id')}
                    ids={this.state.calls.map(({ id }) => id)}
                    currentSort={{ field: 'id', order: 'ASC' }}
                  >
                    <FunctionField
                      source="updatedAt"
                      style={{ fontStyle: "italic" }}
                      render={renderDate}
                      label="Звонок был в"
                    />
                    <FunctionField
                      source="client"
                      render={renderClientName}
                      style={{ width: 280 }}
                      label="Клиент"
                    />
                    <FunctionField
                      source="record"
                      render={renderPlayer}
                      style={{ width: 280 }}
                      label="Запись"
                    />
                  </Datagrid>
                </CardContent>
              </Card>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { showNotification }
  ),
  withDataProvider
)(Aside);