import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import SettingsIcon from "@material-ui/icons/Settings";
import RolesIcon from "@material-ui/icons/Group";
import { withRouter } from "react-router-dom";
import {
  translate,
  DashboardMenuItem,
  MenuItemLink,
  Responsive,
  WithPermissions
} from "react-admin";
import findIndex from "lodash/findIndex";

import UsersIcon from "@material-ui/icons/Person";
import ObjectsIcon from "@material-ui/icons/Home";
import ClientsListIcon from "@material-ui/icons/AssignmentInd";
import TasksIcon from "@material-ui/icons/Work";
import TasksListIcon from "@material-ui/icons/List";
import TasksCreateIcon from "@material-ui/icons/Add";
import ContractorsListIcon from "@material-ui/icons/Face";
import HandbooksIcon from "@material-ui/icons/Book";
import PhoneIcon from "@material-ui/icons/Phone";
import DialerIcon from "@material-ui/icons/DialerSip";
// import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import PhoneLockedIcon from "@material-ui/icons/PhoneLocked";
import PostersIcon from "@material-ui/icons/Input";

import SubMenu from "./components/layout/SubMenu";

const checkPermissions = (slug, perms, access) => {
  const index = findIndex(perms, { slug });

  if (index === -1) {
    return false;
  }

  if (perms[index].access[access] !== 1) {
    return false;
  }

  return true;
};

class Menu extends Component {
  state = {
    menuUsers: false,
    menuTasks: false,
    menuHandBooks: false,
    menuPbx: false
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object
  };

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, logout, translate } = this.props;
    return (
      <div>
        {" "}
        <DashboardMenuItem onClick={onMenuClick} />
        <WithPermissions>
          {permissions => {
            let perms = [];
            if (permissions.permissions) {
              perms = permissions.permissions.perms;
            }

            return [
              checkPermissions("objects", perms, "r") && (
                <MenuItemLink
                  key="objects"
                  to={`/objects`}
                  primaryText={translate(`resources.objects.name`, {
                    smart_count: 2
                  })}
                  leftIcon={<ObjectsIcon />}
                  onClick={onMenuClick}
                />
              ),
              checkPermissions("pbx", perms, "r") && (
                <SubMenu
                  key="pbx"
                  handleToggle={() => this.handleToggle("menuPbx")}
                  isOpen={this.state.menuPbx}
                  sidebarIsOpen={open}
                  name="pos.menu.pbx"
                  icon={<PhoneIcon />}
                >
                  {checkPermissions("pbx/phones", perms, "r") ? (
                    <MenuItemLink
                      key="pbx-phones"
                      to={`/pbx/phones`}
                      primaryText={translate(`resources.pbx/phones.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<DialerIcon />}
                      onClick={onMenuClick}
                    />
                  ) : null}
                  {checkPermissions("pbx/bl", perms, "r") ? (
                    <MenuItemLink
                      key="pbx-bl"
                      to={`/pbx/bl`}
                      primaryText={translate(`resources.pbx/bl.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<PhoneLockedIcon />}
                      onClick={onMenuClick}
                    />
                  ) : null}
                  {checkPermissions("pbx/calls", perms, "r") ? (
                    <MenuItemLink
                      key="pbx-calls"
                      to={`/pbx/calls`}
                      primaryText={translate(`resources.pbx/calls.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<PhoneIcon />}
                      onClick={onMenuClick}
                    />
                  ) : null}
                </SubMenu>
              ),
              checkPermissions("users", perms, "r") ||
              checkPermissions("roles", perms, "r") ? (
                <SubMenu
                  key="users"
                  handleToggle={() => this.handleToggle("menuUsers")}
                  isOpen={this.state.menuUsers}
                  sidebarIsOpen={open}
                  name="pos.menu.users"
                  icon={<UsersIcon />}
                >
                  {checkPermissions("users", perms, "r") ? (
                    <MenuItemLink
                      to={`/users`}
                      primaryText={translate(`resources.users.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<UsersIcon />}
                      onClick={onMenuClick}
                    />
                  ) : null}
                  {checkPermissions("roles", perms, "r") ? (
                    <MenuItemLink
                      to={`/roles`}
                      primaryText={translate(`resources.roles.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<RolesIcon />}
                      onClick={onMenuClick}
                    />
                  ) : null}
                </SubMenu>
              ) : null,
              checkPermissions("tasks", perms, "r") && (
                <SubMenu
                  key="tasks"
                  handleToggle={() => this.handleToggle("menuTasks")}
                  isOpen={this.state.menuTasks}
                  sidebarIsOpen={open}
                  name="pos.menu.tasks"
                  icon={<TasksIcon />}
                >
                  <MenuItemLink
                    key="tasks_list"
                    to={`/tasks`}
                    primaryText={translate(`resources.tasks.list`, {
                      smart_count: 2
                    })}
                    leftIcon={<TasksListIcon />}
                    onClick={onMenuClick}
                  />
                  {checkPermissions("tasks-templates", perms, "r") && (
                    <MenuItemLink
                      key="tasks_templates"
                      to={`/tasks-templates`}
                      primaryText={translate(`resources.tasks.templates`, {
                        smart_count: 2
                      })}
                      leftIcon={<TasksListIcon />}
                      onClick={onMenuClick}
                    />
                  )}
                  <MenuItemLink
                    key="tasks_create"
                    to={`/tasks/create`}
                    primaryText={translate(`ra.action.create`)}
                    leftIcon={<TasksCreateIcon />}
                    onClick={onMenuClick}
                  />
                </SubMenu>
              ),
              checkPermissions("event-actions", perms, "r") && (
                <MenuItemLink
                  key="event_actions"
                  to={`/event-actions`}
                  primaryText={translate(`resources.event-actions.name`, {
                    smart_count: 2
                  })}
                  leftIcon={<TasksListIcon />}
                  onClick={onMenuClick}
                />
              ),
              checkPermissions("contractors", perms, "r") && (
                <MenuItemLink
                  key="contractors_list"
                  to={`/contractors`}
                  primaryText={translate(`resources.contractors.name`, {
                    smart_count: 2
                  })}
                  leftIcon={<ContractorsListIcon />}
                  onClick={onMenuClick}
                />
              ),
              checkPermissions("clients", perms, "r") && (
                <MenuItemLink
                  key="clients_list"
                  to={`/clients`}
                  primaryText={translate(`resources.clients.name`, {
                    smart_count: 2
                  })}
                  leftIcon={<ClientsListIcon />}
                  onClick={onMenuClick}
                />
              ),
              checkPermissions("handbooks", perms, "r") && (
                <SubMenu
                  key="handbooks"
                  handleToggle={() => this.handleToggle("menuHandBooks")}
                  isOpen={this.state.menuHandBooks}
                  sidebarIsOpen={open}
                  name="pos.menu.handbooks"
                  icon={<HandbooksIcon />}
                >
                  {checkPermissions("districts", perms, "r") ? (
                    <MenuItemLink
                      key="districts"
                      to={`/districts`}
                      primaryText={translate(`resources.districts.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<HandbooksIcon />}
                      onClick={onMenuClick}
                    />
                  ) : null}
                  {checkPermissions("series", perms, "r") ? (
                    <MenuItemLink
                      key="series"
                      to={`/series`}
                      primaryText={translate(`resources.series.name`, {
                        smart_count: 2
                      })}
                      leftIcon={<HandbooksIcon />}
                      onClick={onMenuClick}
                    />) : null
                  } 
                </SubMenu>
              ),
              checkPermissions("scripts", perms, "r") && (
                <MenuItemLink
                  key="scripts"
                  to={`/scripts`}
                  primaryText={translate(`resources.scripts.name`, {
                    smart_count: 2
                  })}
                  leftIcon={<SettingsIcon />}
                />
              ),
              checkPermissions("posters", perms, "r") &&
              (
                <MenuItemLink
                  key="posters"
                  to={`/posters`}
                  primaryText={translate(`resources.posters.name`, {
                    smart_count: 2
                  })}
                  leftIcon={<PostersIcon />}
                  onClick={onMenuClick}
                />
              )
            ];
          }}
        </WithPermissions>
        <Responsive
          xsmall={
            <MenuItemLink
              to="/configuration"
              primaryText={translate("pos.configuration")}
              leftIcon={<SettingsIcon />}
              onClick={onMenuClick}
            />
          }
          medium={null}
        />
        <Responsive small={logout} medium={null} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  locale: state.i18n.locale
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
  ),
  translate
);

export default enhance(Menu);
