import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { translate } from "react-admin";
import { contentBarOpen } from "../../actions/contentBarActions";

import { compose } from "redux";
import { connect } from "react-redux";

const styles = theme => ({
  lineThrough: {
    textDecoration: "line-through"
  }
});

class BpStartButton extends Component {
  handleClick = () => {
    const { contentBarOpen, handleClick } = this.props;
    handleClick();
    contentBarOpen("bp");
  }
  render() {
    const { label, translate, disabled, classes, status } = this.props;
    return (
        <Button
          color="inherit"
          onClick={this.handleClick}
          disabled={disabled}
          className={status ? classes.lineThrough : ''}
        >
          {label && translate(label, { _: label })}
        </Button>
    );
  }
}

const mapStateToProps = state => ({});

export default compose(
  connect(
    mapStateToProps,
    { contentBarOpen }
  ),
  translate,
  withStyles(styles)
)(BpStartButton);
