import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  translate,
  CardActions,
  ListButton,
  ReferenceArrayInput,
  FormDataConsumer
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import { events, actions, sections } from "../consts";
import { findIndex } from "lodash";

const EventActionTitle = ({ record }) => record.name || "";

const validate = translate => values => {
  const errors = {};

  return errors;
};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  input: {
    maxWidth: "260px",
  }
});

const EventActionActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const EventActionEdit = ({ classes, ...props }) => {
  const { translate } = props;

  return (
    <Edit
      {...props}
      classes={{ card: classes.card }}
      actions={<EventActionActions />}
      title={<EventActionTitle />}
    >
      <SimpleForm validate={validate(translate)}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let showTaskTemplatesInput = false;
            let sectionIndex = null;
            let cat_ids = null;

            if (formData.action && (
              formData.action.includes("CREATE_TASK_OBJECTS") ||
              formData.action.includes("CREATE_TASK_CLIENTS") ||
              formData.action.includes("CREATE_TASK_PBX") ||
              formData.action.includes("CREATE_TASK_TASKS")
            )) {
              showTaskTemplatesInput = true
            }
            
            if (formData.section) {
              sectionIndex = findIndex(events, {
                section: formData.section
              });
            }

            if (formData.section === "objects") {
              if (events[sectionIndex] && events[sectionIndex].choices) {
                let cat_id = findIndex(events[sectionIndex].choices, {
                  id: formData.event
                });
                cat_id += 1;
                if (cat_id > 6) {
                  cat_ids = null;
                } else {
                  cat_ids = [cat_id];
                }
              }
            } else {
              cat_ids = null;
            }

            return (
              <div>
                <div className={classes.input}>
                  <TextInput
                    source="name"
                    resettable={true}
                    label="resources.event-actions.input.name"
                    {...rest}
                  />
                </div>
                <div className={classes.input}>
                  <SelectInput
                    source="section"
                    label="resources.event-actions.input.section"
                    choices={sections}
                    resettable={true}
                    {...rest}
                  />
                </div>
                <div className={classes.input}>
                  <SelectInput
                    source="event"
                    label="resources.event-actions.input.event"
                    resettable={true}
                    {...rest}
                    choices={events[sectionIndex] && events[sectionIndex].choices}
                  />
                </div>
                <div className={classes.input}>
                  <SelectArrayInput
                    source="action"
                    label="resources.event-actions.input.action"
                    fullWidth={true}
                    choices={actions[formData.section]}
                  />
                </div>
                {showTaskTemplatesInput && <div className={classes.input}>
                  <ReferenceArrayInput
                    source="template_id"
                    reference="tasks-templates"
                    resource="tasks-templates"
                    label="resources.event-actions.input.task"
                    filter={{ cat_ids }}
                    {...rest}
                  >
                    <AutocompleteArrayInput
                      optionText="title"
                      optionValue="id"
                      fullWidth={true}
                      source="template_id"
                    />
                  </ReferenceArrayInput>
                </div>}
              </div>
            )}}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(EventActionEdit);
