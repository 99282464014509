import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { compose } from "redux";
import { translate } from "react-admin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GalleryLightbox from "../components/media/GalleryLightbox";
import classnames from "classnames";
import get from "lodash/get";

const styles = theme => ({
  objectParams: {
    display: "flex",
    alignItems: "center",
    minHeight: 110
  },
  objectParamsItem: {
    marginRight: 20,
    textAlign: "center"
  },
  avatar: {
    width: 110,
    height: 110,
    marginTop: 10
  },
  types: {
    textTransform: "uppercase",
    fontSize: 28
  },
  title: {
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: "solid 2px #c4c4c4",
    marginBottom: 12
  },
  infoItem: {
    marginBottom: 12
  }
});

const ObjectAnalysis = ({ data, translate, classes }) => {
  let galleryImages = null;
  let lightboxImages = null;

  let images = get(data, "legal_info.documents");

  if (images) {
    lightboxImages = images.map(image => {
      return {
        src: image.src
      };
    });

    galleryImages = images.map(image => {
      return {
        src: image.src
      };
    });
  }

  return (
    <Fragment>
      <DialogTitle id="object-dialog-title" className={classes.title}>
        <Grid container spacing={0}>
          <Grid item xs={7}>
            <strong>
              {data.address_for_sorting ? (
                <span>{data.address_for_sorting}</span>
              ) : (
                data.street && (
                  <span>
                    {data.street.name}
                    {data.house}
                  </span>
                )
              )}
            </strong>
            <span> {data.flat}</span>{" "}
            {data.floor ? <strong>п{data.floor}</strong> : ""}
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right" }}>
            {data.types && (
              <strong className={classes.types}>{data.types.name}</strong>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.objectParams}>
              {get(data, "images.main.thumb.src") && (
                <Avatar
                  src={get(data, "images.main.thumb.src")}
                  size={110}
                  className={classnames([
                    classes.objectParamsItem,
                    classes.avatar
                  ])}
                />
              )}
              <div className={classes.objectParamsItem}>
                {data.floor &&
                  data.floor_house && (
                    <div>
                      {data.floor}/{data.floor_house}
                    </div>
                  )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.area + translate("measure.m2")
                  }}
                />
              </div>
              <div className={classes.objectParamsItem}>
                {data.series && <div>{data.series.name}</div>}
                {data.type_house && <div>{data.type_house.name}</div>}
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={8} className={classes.details}>
          {galleryImages &&
            lightboxImages && (
              <Grid item xs={2}>
                <GalleryLightbox
                  galleryImages={galleryImages}
                  lightboxImages={lightboxImages}
                  isSliderVertical
                />
              </Grid>
            )}
          <Grid item xs={galleryImages && lightboxImages ? 10 : 12}>
            <div className={classes.infoList}>
              <div className={classes.infoItem}>
                Цель продажи: {data.target ? <strong>{data.target.name}</strong> : ""}
              </div>
              <div className={classes.infoItem}>
                Лет в собственности:{" "}
                {data.legal_info && data.legal_info.years_old ? (
                  <strong>{data.legal_info.years_old}</strong>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.infoItem}>
                Год выпуска зеленки:{" "}
                {data.legal_info && data.legal_info.year ? (
                  <strong>{data.legal_info.year}</strong>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.infoItem}>
                Перепланировка:{" "}
                {data.legal_info && data.legal_info.redevelopment ? (
                  <strong>
                    {data.legal_info.redevelopment === 1 ? "Да" : "Нет"}
                  </strong>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.infoItem}>
                Собственников:{" "}
                {data.legal_info && data.legal_info.owners ? (
                  <strong>{data.legal_info.owners}</strong>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.infoItem}>
                Происанных:{" "}
                {data.legal_info && data.legal_info.prescription ? (
                  <strong>{data.legal_info.prescription}</strong>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.infoItem}>
                В ипотеке:{" "}
                {data.legal_info && data.legal_info.mortgage ? (
                  <strong>
                    {data.legal_info.mortgage === 1 ? "Да" : "Нет"}
                  </strong>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment>
  );
};

export default compose(
  withStyles(styles),
  translate
)(ObjectAnalysis);
