import React, { Component } from "react";
import { translate, withDataProvider, GET_LIST } from "react-admin";
// import IconPhoneMissed from "@material-ui/icons/PhoneMissed";
import IconPhone from "@material-ui/icons/Phone";
import UsersIcon from "@material-ui/icons/Person";
import ObjectsIcon from "@material-ui/icons/Home";
import ClientsListIcon from "@material-ui/icons/AssignmentInd";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-admin";
import red from "@material-ui/core/colors/red";

import { compose } from "redux";
import { connect } from "react-redux";

import { socket } from "../../consts";

import groupBy from "lodash/groupBy";
import { stringify } from "query-string";

import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

const styles = theme => ({
  paper: {
    height: 600,
    overflow: "auto",
    width: 320,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  red: {
    color: red[300],
  },
  listIcon: {
    marginRight: 0,
  },
  link: {
    color: '#3f51b5',
  },
});


class PhoneButton extends Component {
  state = {
    loading: false,
    open: false,
    page: 1,
    perPage: 25,
    count: 0,
    anchorRef: null,
    data: [],
    list: null,
  };

  handleClick = ev => {
    this.setState({
      open: true,
      anchorRef: ev.currentTarget,
    })
  };

  handleClose = () => {
    this.setState({
      open: false,
      anchorRef: null,
      count: 0,
    })
  }

  handlePbxEvent = event => data => {
    const { classes } = this.props;
    const mergedData = [
      data,
      ...this.state.data,
    ];

    this.generateList(mergedData, classes);

    this.setState({
      count: this.state.count + 1,
      data: mergedData,
    });
  };

  generateList = (data, classes) => {
    const groupedData = groupBy(data, item => moment(item.updatedAt).fromNow());
    const list = [];

    for (const prop in groupedData) {
      if (groupedData[prop] && groupedData[prop].length) {
        groupedData[prop].forEach((item, key) => {
          let fio = item.client && item.client.fio;
          let icon = <ClientsListIcon style={{ fontSize: 40 }} />;
          let secondaryText = "";
          const datetime = moment(item.createdAt).format('D MMMM HH:mm');
          let object = null;
          let to = "";

          if (item.object && item.object.street) {
            object = `${item.object.street.name} ${item.object.house}`
          }

          if (item.section === "clients") {
            if (object) {
              secondaryText = <span> — Пропущенный звонок по объекту <span className={classes.link}>{object}</span></span>;
            }

            to = `/pbx/calls/?${stringify({
              filter: JSON.stringify({ client_id: [item.client.id], status: "busy" })
            })}`
          }

          if (item.section === "objects") {
            icon = <ObjectsIcon style={{ fontSize: 40 }} />;
            fio = item.object.contact_people;

            if (object) {
              secondaryText = <span> — Пропущенный звонок от собственника объекта <span className={classes.link}>{object}</span></span>;
            }

            to = `/pbx/calls/?${stringify({
              filter: JSON.stringify({ object_id: [item.object.id], status: "busy" })
            })}`
          }

          if (item.section === "users") {
            icon = <UsersIcon style={{ fontSize: 40 }} />;

            secondaryText = <span> — Пропущенный звонок от агента</span>;
            
            const f = item.last_name ? item.last_name : "";
            const i = item.name ? item.name : "";
            const o = item.middle_name ? item.middle_name : "";

            fio = `${f} ${i} ${o}`;

            to = `/pbx/calls/?${stringify({
              filter: JSON.stringify({ user_id: [item.user.id], status: "busy" })
            })}`
          }

          list.push(
            <React.Fragment key={item.id}>
              {key === 0 && <ListSubheader className={classes.subheader}>{prop}</ListSubheader>}
              <ListItem
                to={to}
                button
                component={Link}
              >
                <ListItemIcon className={classes.listIcon}>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={fio} secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {datetime}
                    </Typography>
                    {secondaryText}
                  </React.Fragment>
                } />
              </ListItem>
            </React.Fragment>
          );
        });
      }
    }

    this.setState({
      list,
    });
  }

  async fetchMore() {
    if (!this.loading) {
      this.setState({
        loading: true,
      });
  
      const { dataProvider, classes } = this.props;
      const { data } = await dataProvider(GET_LIST, "pbx/calls", {
        filter: { status: "busy", mine: "1" },
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: this.state.page + 1, perPage: this.state.perPage },
      });
  
      const mergedData = [
        ...this.state.data,
        ...data,
      ];
  
      this.generateList(mergedData, classes);

      this.setState({
        page: this.state.page + 1,
        data: mergedData,
        loading: false,
      });
    }
  }

  handleScroll = ev => {
    if (ev.target.scrollTop + ev.target.clientHeight >= ev.target.scrollHeight) {
      this.fetchMore();
    }
  }

  async fetchMissedCalls() {
    const { dataProvider, classes } = this.props;
    const { data } = await dataProvider(GET_LIST, "pbx/calls", {
      filter: { status: "busy", mine: "1" },
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: this.state.page, perPage: this.state.perPage },
    });

    this.setState({
      data,
    });

    this.generateList(data, classes);
  }

  componentWillMount() {
    socket.instance.on("pbx_busy", this.handlePbxEvent("pbx_busy"));
    this.fetchMissedCalls();
  }
  
  componentWillUnmount() {
    socket.instance.off("pbx_busy", data => this.handlePbxEvent("pbx_busy", data));
  }

  render() {
    const { label, translate, bp, classes } = this.props;
    return (
      <div>
        <Tooltip title={label ? translate(label, { _: label }) : ""}>
          <IconButton color="inherit" onClick={this.handleClick}>
            {this.state.count > 0 ? (
              <Badge badgeContent={this.state.count} color="primary">
                <IconPhone />
              </Badge>
            ) : (
              <IconPhone />
            )}
          </IconButton>
        </Tooltip>
        <Popper
          open={this.state.open}
          anchorEl={this.state.anchorRef}
          role={undefined}
          transition
          disablePortal
          placement={"bottom-end"}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper className={classes.paper} onScroll={this.handleScroll}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <List className={classes.list}>
                    {this.state.list}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bp: state.bp
});

export default compose(
  connect(
    mapStateToProps,
  ),
  translate,
  withStyles(styles),
  withDataProvider,
)(PhoneButton);
