import React, { Component, Fragment } from "react";

import {
  Button,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  REDUX_FORM_NAME,
  UPDATE,
  showNotification,
  translate,
  setListSelectedIds
} from "react-admin";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { compose } from "redux";
import dataProviderFactory from "../dataProvider";

const phoneRenderer = choice => {
  // return (
  //   <span>
  //     {choice.phone !== null ? choice.phone : ""}{" "}
  //     <strong>
  //       {choice.object && choice.object.street && choice.object.street.name
  //         ? choice.object.street.name
  //         : ""}{" "}
  //       {choice.object && choice.object.house ? choice.object.house : ""}
  //     </strong>
  //   </span>
  // );

  return `
  ${choice.phone !== null ? choice.phone : ""}
  ${choice.object && choice.object.street && choice.object.street.name ? ' (' + choice.object.street.name : ""}
  ${choice.object && choice.object.house ? choice.object.house + ')' : ""}
  `
};

export class AssignPhone extends Component {
  state = {
    open: false,
    saving: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const {
      selectedIds,
      showNotification,
      setListSelectedIds,
      user_id,
      phone_id
    } = this.props;

    this.setState({
      saving: true
    });
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(UPDATE, "pbx/phones/assign", {
          id: selectedIds[0],
          data: {
            user_id: user_id,
            phone_id: phone_id
          }
        })
          .then(({ data }) => {
            this.setState({
              saving: false,
              open: false
            });

            if (data) {
              showNotification("Phone assigned", "info");
            }

            setListSelectedIds("objects", []);
          })
          .catch(e => {
            this.setState({
              saving: false,
              open: false
            });
            showNotification(e, "warning");
          });
      }
    );
  };

  handleChangeManager = (ev, value) => {
    this.setState({
      user_id: null
    });

    setTimeout(() => {
      this.setState({
        user_id: value
      });
    }, 100);
  };

  renderOptionText = record => {
    if (record.fio) {
      return record.fio;
    }
    return `${record.name !== null ? record.name : ""} ${
      record.last_name !== null ? record.last_name : ""
    }`;
  };

  render() {
    const { translate, user_id, phone_id } = this.props;
    return (
      <Fragment>
        <Button
          label={translate("resources.pbx/phones.assign")}
          onClick={this.handleClickOpen}
        />
        <Drawer
          open={this.state.open}
          onClose={this.handleClose}
          anchor="right"
          aria-labelledby="form-dialog-title"
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle id="form-dialog-title">
            {translate("resources.pbx/phones.assign")}
          </DialogTitle>
          <DialogContent>
            <SimpleForm resource="pbx/phones/assign" toolbar={null}>
              <ReferenceInput
                label="resources.objects.input.manager"
                source="user_id"
                reference="users"
                perPage={25}
                onChange={this.handleChangeManager}
              >
                <AutocompleteInput optionText={this.renderOptionText} />
              </ReferenceInput>
              {this.state.user_id && (
                <ReferenceInput
                  label="resources.pbx/phones.input.phone"
                  source="phone_id"
                  reference="pbx/phones"
                  perPage={100}
                  filter={{ user_id: this.state.user_id, object_id: null }}
                >
                  <AutocompleteInput optionText={phoneRenderer} />
                </ReferenceInput>
              )}
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              basePath=""
              onClick={this.handleSubmit}
              saving={this.state.saving}
              disabled={!user_id || !phone_id}
            />
          </DialogActions>
        </Drawer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user_id: formValueSelector(REDUX_FORM_NAME)(state, "user_id"),
  phone_id: formValueSelector(REDUX_FORM_NAME)(state, "phone_id")
});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { showNotification, setListSelectedIds }
  )
)(AssignPhone);
