import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import {
  newMessagesInRoom,
  clearNewMessagesInRoom
} from "./actions/ChatActions";
import { compose } from "redux";
import { connect } from "react-redux";

import Sticky from "./components/Sticky";
import Chat from "./components/chat/Chat";
import { socket } from "./consts";

const styles = theme => ({
  content: {
    display: "flex",
    padding: 4,
    [theme.breakpoints.down("sm")]: {
      padding: 0
    },
    paddingTop: 72,
    position: "relative"
  },
  cardRoot: {
    width: "100%"
  },
  cardContent: {
    padding: 8
  }
});

class ContentBarChat extends Component {
  state = {
    newMessages: null,
  };

  componentWillMount() {
    socket.instance.emit(
      "get new messages in room",
      this.props.newMessagesInRoom
    );
    socket.instance.on("new messages in room", this.props.newMessagesInRoom);
    socket.instance.on(
      "clear new messages in room",
      this.props.clearNewMessagesInRoom
    );
  }

  componentWillUnmount() {
    socket.instance.off("new messages in room", this.handleReceiveRooms);
    socket.instance.off(
      "clear new messages in room",
      this.props.clearNewMessagesInRoom
    );
  }

  render() {
    const { classes, contentBar, style } = this.props;
    const isShow = contentBar.chat;

    return isShow ? (
      <div className={classes.content} style={style}>
        <Card className={classes.cardRoot}>
          <CardContent className={classes.cardContent}>
            <Sticky>
              <Chat />
            </Sticky>
          </CardContent>
        </Card>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => ({
  contentBar: state.contentBar,
  chat: state.chat
});

export default compose(
  connect(
    mapStateToProps,
    { newMessagesInRoom, clearNewMessagesInRoom }
  ),
  withStyles(styles)
)(ContentBarChat);
