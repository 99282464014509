import React, { Fragment, Component } from "react";

import moment from "moment";
import 'moment/locale/ru';

import {
  List,
  EmailField,
  FunctionField,
  Pagination,
  Filter,
  ReferenceInput,
  AutocompleteArrayInput
} from "react-admin";

import ChipInput from "../components/input/ChipInput";

import { Link } from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Route } from "react-router";
import queryString from "query-string";

import CustomizableDatagrid from "../components/ra-customizable-datagrid";
import FullNameField from "../components/field/FullNameField";
import UserQuickPreview from "./UserQuickPreview";

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.createdAt)
  const m2 = moment(record.createdAt).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const renderName = record => {
  if (record) {
    return (
      <Link
        to={`/users?show=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const numRender = record => {
  if (
    record.phone &&
    record["pbx-manager"] &&
    record["pbx-manager"][0] &&
    record["pbx-manager"][0].id
  ) {
    return (
      <span>
        {record.phone}&nbsp;
        <strong>{record["pbx-manager"][0].id}</strong>
      </span>
    );
  }

  if (record.phone) {
    return <span>{record.phone}</span>;
  }
};

const renderRole = record => {
  if (record && record.roles && record.roles.length > 0) {
    if (record.roles.length > 1) {
      let roles = "";
      record.roles.forEach((item, key) => {
        if (key !== record.roles.length - 1) {
          roles += item.role.name + ", ";
        } else {
          roles += item.role.name;
        }
      });

      return roles;
    }

    return record.roles[0].role.name;
  }

  return "";
};

const UsersTable = props => (
  <CustomizableDatagrid {...props} rowClick="edit" showGroup={false}>
    <FunctionField source="createdAt" style={{ fontStyle: "italic" }} render={renderDate} />
    <FunctionField source="name" label="User" render={renderName} />
    <EmailField source="email" />
    <FunctionField source="phone" render={numRender} />
    <FunctionField source="roles" render={renderRole} />
  </CustomizableDatagrid>
);

const renderUserOption = choice => {
  return `${choice.name !== null ? choice.name : ""} ${
    choice.last_name !== null ? choice.last_name : ""
  }`;
};

const UsersFilter = props => (  
  <Filter {...props}>
    <ReferenceInput
      source="id"
      label="resources.objects.input.manager"
      reference="users"
    >
      <ChipInput isTranslated={false}>
        <AutocompleteArrayInput optionText={renderUserOption} />
      </ChipInput>
    </ReferenceInput>
  </Filter>
);

const UsersPagination = props => (
  <Pagination rowsPerPageOptions={[5, 15, 30, 50]} {...props} />
);

class UsersList extends Component {
  handleClose = ev => {
    this.props.push("/users");
  };

  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<UsersPagination />}
          perPage={15}
          sort={{ field: "createdAt", order: "DESC" }}
          filters={<UsersFilter />}
        >
          <UsersTable />
        </List>
        <Route path="/users">
          {route => {
            const values = queryString.parse(route.location.search);
            let id = null;

            if (values.show) {
              id = values.show;
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;
          }}
        </Route>
      </Fragment>
    );
  }
}

export default connect(
  null,
  { push }
)(UsersList);
