import React, { Component, Fragment } from "react";
import { Responsive, Title, withDataProvider, GET_LIST } from "react-admin";

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import NewClients from "./NewClients";
import TasksCount from './TasksCount';
import OverdueTasksCount from './OverdueTasksCount';
import PendingOrders from './PendingOrders';
import MissedСalls from './MissedСalls';

const styles = {
  root: {
    minWidth: 320
  },
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  full: {
    marginTop: "2em", marginBottom: "2em",
    marginLeft: "1em", marginRight: "1em"
  }
};

class Dashboard extends Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
        this.fetchData();
    }
  }

  fetchData() {
    this.fetchTasks();
    this.fetchOverdueTasks();
    this.fetchClients();
    this.fetchMissedCalls();
  }

  async fetchClients() {
    const { dataProvider } = this.props;
    const { data: newClients, total: nbNewClients } = await dataProvider(GET_LIST, 'clients', {
      filter: { spam: "не спам", fio: "новый клиент" },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 10 },
    });

    this.setState({
      newClients,
      nbNewClients,
    });
  }

  async fetchMissedCalls() {
    const { dataProvider } = this.props;
    const { data: missedСalls, total: nbMissedСalls } = await dataProvider(GET_LIST, 'pbx/calls', {
      filter: { status: "busy" },
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 10 },
    });

    this.setState({
      missedСalls,
      nbMissedСalls,
    });
  }

  async fetchTasks() {
    const { dataProvider } = this.props;
    const { total: tasksCount } = await dataProvider(GET_LIST, 'tasks', {
      filter: { status: 0, active: 1},
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 1 },
    });

    this.setState({
      tasksCount
    });
  }

  async fetchOverdueTasks() {
    const { dataProvider } = this.props;
    const d = new Date();
    const { total: overdueTasksCount } = await dataProvider(GET_LIST, 'tasks', {
      filter: { status: 0, active: 1, updatedAt: new Date(d.setDate(d.getDate() - 2))},
      sort: { field: 'createdAt', order: 'DESC' },
      pagination: { page: 1, perPage: 1 },
    });

    this.setState({
      overdueTasksCount
    });
  }

  render() {
    return (
      <Fragment>
        <Title title="Главная" />
        <div style={styles.root}>
          <Responsive
            medium={
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <div style={styles.flex}>
                    <TasksCount value={this.state.tasksCount} />
                    <OverdueTasksCount value={this.state.overdueTasksCount} />
                  </div>
                  <div style={styles.singleCol}>
                    <PendingOrders
                      orders={[]}
                      customers={[]}
                    />
                  </div>
                </div>
                  <div style={styles.rightCol}>
                    <div style={styles.flex}>
                      <MissedСalls
                        nb={this.state.nbMissedСalls}
                        clients={this.state.missedСalls}
                      />
                      <NewClients
                        nb={this.state.nbNewClients}
                        clients={this.state.newClients}
                      />
                    </div>
                  </div>
              </div>
            }
            small={
              <div>
                <TasksCount
                  value={this.state.tasksCount}
                  style={styles.full}
                />
                <OverdueTasksCount
                  value={this.state.overdueTasksCount}
                  style={styles.full}
                />
                <MissedСalls
                  nb={this.state.nbMissedСalls}
                  clients={this.state.missedСalls}
                  style={styles.full}
                />
                <NewClients
                  nb={this.state.nbNewClients}
                  clients={this.state.newClients}
                  style={styles.full}
                />
                <PendingOrders
                  orders={[]}
                  customers={[]}
                  style={styles.full}
                />
              </div>
            }
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion,
});

export default compose(
  connect(mapStateToProps),
  withDataProvider
)(Dashboard);
