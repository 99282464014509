import get from "lodash/get";

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const addUploadFeature = requestHandler => (type, resource, params) => {
  if ((type === "CREATE" || type === "UPDATE") && resource === "objects") {
    if (params.data && get(params.data, "legal_info.documents")) {
      const documents = get(params.data, "legal_info.documents");

      const newFile = documents.filter(p => p.rawFile instanceof File);
      const oldFile = documents.filter(p => !p.rawFile);

      return Promise.all(newFile.map(convertFileToBase64))
        .then(base64File =>
          base64File.map((file64, index) => ({
            base64: file64,
            title: `${newFile[index].title}`
          }))
        )
        .then(transformedNewFile =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              legal_info: {
                ...params.data.legal_info,
                documents: [
                  ...oldFile,
                  ...transformedNewFile
                ]
              }
            }
          })
        );
    }
  }
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
