import React, { Component } from "react";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class AgentCard extends Component {
  state = {
    loading: false,
    user: null
  };

  componentWillMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        user
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, user } = this.state;

    return (
      <Card style={{ height: "100%" }}>
        {user && (
          <CardContent className={classes.cardContent}>
            <Typography color="textSecondary" component="h3">
              Агент
            </Typography>
            <Typography color="textSecondary" component="h2">
              {`${user.name} ${user.last_name}`}
            </Typography>
            {user.photo ? (
              <Avatar
                src={user.photo}
                aria-label={`${user.name} ${user.last_name}`}
                className={classes.sellerAvatar}
              />
            ) : (
              <Avatar
                aria-label={`${user.name} ${user.last_name}`}
                className={classes.sellerAvatar}
              >
                {`${user.name[0]}${user.last_name[0]}`}
              </Avatar>
            )}
          </CardContent>
        )}
        {loading && <LinearProgress variant="query" />}
      </Card>
    );
  }
}
