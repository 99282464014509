import { SET_PBX_EVENT } from "../actions/pbxActions";

const defaultState = {
};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case SET_PBX_EVENT:
      return payload;
    default:
      return previousState;
  }
};
