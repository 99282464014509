import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ResizeObserver from "resize-observer-polyfill";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  listTile: {
    backgroundColor: "#999",
    borderRadius: 6,
    textAlign: "center"
  },
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  gridList: {
      width: "100%"
  }
});

class GalleryGrid extends Component {
  static propTypes = {
    photos: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    ImageComponent: PropTypes.func,
    classes: PropTypes.object.isRequired
  };

  state = {
    containerWidth: 0
  };

  componentDidMount() {
    this.animationFrameID = null;
    this.observer = new ResizeObserver(entries => {
      const newWidth = entries[0].contentRect.width;
      if (this.state.containerWidth !== newWidth) {
        this.animationFrameID = window.requestAnimationFrame(() => {
          this.setState({ containerWidth: Math.floor(newWidth) });
        });
      }
    });
    this.observer.observe(this._gallery);
  }

  componentWillUnmount() {
    this.observer.disconnect();
    window.cancelAnimationFrame(this.animationFrameID);
  }

  handleClick = (photo, index) => event => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event, { photo, index });
    }
  };

  createGalleryRef = c => {
    this._gallery = c;
  };

  render() {
    const { photos, ImageComponent, classes } = this.props;
    const { containerWidth } = this.state;
    if (!containerWidth) return <div ref={this.createGalleryRef}>&nbsp;</div>;

    return (
      <div className={classes.root} style={{ width: containerWidth }}>
        <GridList cellHeight={160} className={classes.gridList} cols={6}>
          {photos.map((photo, key) => {
            return (
              <GridListTile
                classes={{ tile: classes.listTile }}
                key={key}
                cols={1}
              >
                {ImageComponent ? (
                  <ImageComponent
                    src={photo.src}
                    alt={photo.alt}
                    onClick={this.handleClick(photo, key)}
                    photo={photo}
                    index={key}
                    key={key}
                    className={classes.image}
                  />
                ) : (
                  <img
                    src={photo.src}
                    alt={photo.alt}
                    key={key}
                    onClick={this.handleClick(photo, key)}
                    className={classes.image}
                  />
                )}
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  }
}

export default withStyles(styles)(GalleryGrid);
