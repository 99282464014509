import React, { Component, Fragment } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  Button,
  translate,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SaveButton,
  showNotification,
  CREATE
} from "react-admin";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { parse } from "query-string";

import ContentFilter from "@material-ui/icons/FilterList";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import dataProviderFactory from "../dataProvider";

class SaveFilterButton extends Component {
  state = {
    open: false,
    value: "",
    filter_cat_id: null,
    client_id: null,
    saving: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      value: "",
      filter_cat_id: null,
      client_id: null,
      saving: false
    });
  };

  handleChangeCat = (ev, value) => {
    this.setState({
      filter_cat_id: value
    });
  };

  handleChangeClient = (ev, value) => {
    this.setState({
      client_id: value
    });
  };

  handleChange = ev => {
    this.setState({
      value: ev.target.value
    });
  };

  handleSubmit = () => {
    const { showNotification, location } = this.props;
    this.setState({
      saving: true
    });
    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      dataProvider => {
        dataProvider(CREATE, "filters", {
          data: {
            name: this.state.value,
            filter_cat_id: this.state.filter_cat_id,
            filter: location.search,
            client_id: this.state.client_id
          },
          redirectTo: false
        })
          .then(({ data }) => {
            this.setState({
              saving: false,
              open: false
            });

            if (data) {
              showNotification("Filter saved", "info");
            }
          })
          .catch(e => {
            this.setState({
              saving: false,
              open: false
            });
            showNotification(e, "warning");
          });
      }
    );
  };

  renderOptionsClients = record => {
    if (record.fio) {
      return record.fio;
    }

    return `${record.name !== null ? record.name : ""} ${
      record.last_name ? record.last_name : ""
    }`;
  };

  render() {
    const { translate, location } = this.props;
    const search = parse(location.search);

    return search.filter && Object.keys(search.filter).length > 2 ? (
      <Fragment>
        <Button
          label="resources.objects.action.save_filter"
          onClick={this.handleClickOpen}
        >
          <ContentFilter />
        </Button>
        <Drawer
          open={this.state.open}
          anchor="right"
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle id="form-dialog-title">
            {translate("Save filter")}
          </DialogTitle>
          <DialogContent>
            <SimpleForm resource="filters" toolbar={null}>
              <ReferenceInput
                label="resources.objects.input.filter_cat"
                source="filter_cat_id"
                reference="filters/categories"
                perPage={25}
                onChange={this.handleChangeCat}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              {this.state.filter_cat_id === 2 && (
                <ReferenceInput
                  label="resources.objects.input.clients"
                  source="clients"
                  reference="clients"
                  perPage={25}
                  sort={{ field: 'fio', order: 'ASC' }}
                  onChange={this.handleChangeClient}
                >
                  <AutocompleteInput optionText={this.renderOptionsClients} />
                </ReferenceInput>
              )}
              {this.state.filter_cat_id && (
                <TextInput
                  source="filter_name"
                  label="Название фильтра"
                  onChange={this.handleChange}
                  disabled={
                    this.state.filter_cat_id === 2 && !this.state.client_id
                  }
                />
              )}
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              basePath=""
              onClick={this.handleSubmit}
              disabled={this.state.value === ""}
              saving={this.state.saving}
            />
          </DialogActions>
        </Drawer>
      </Fragment>
    ) : null;
  }
}

const mapStateToProps = (state, props) => ({
  location: state.router.location
});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { showNotification }
  )
)(SaveFilterButton);
