import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { clientConfirm } from "./ClientsActions";
import {
  Button,
  setListSelectedIds,
  SaveButton,
  translate,
  withDataProvider,
  showNotification
} from "react-admin";

import { Button as MuiButton } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  textField: {
    margin: 8,
    width: "100%"
  }
});

class ConfirmButton extends Component {
  state = {
    open: false,
    isSendSms: false,
    loadingSend: false,
    loadingGet: false,
    showInput: false,
    url: ""
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = () => {
    this.setState(state => ({ isSendSms: !state.isSendSms }));
  };

  handleClick = () => {
    const { clientConfirm, selectedIds, clients, setListSelectedIds } = this.props;
    if (clients && selectedIds) {
      clientConfirm(selectedIds, this.state.isSendSms);
    }
  };

  sendLink = () => {
    const { dataProvider, selectedIds, clients, showNotification } = this.props;

    this.setState({
      loadingSend: true
    });

    dataProvider("GET", "clients", {
      resource: "link/send",
      id: clients[selectedIds[0]].auth_id
    }).then(({ data }) => {
      this.setState({
        loadingSend: false
      });
      if (data.success) {
        showNotification("СМС отправлена", "info");
      } else {
        showNotification(data.error, "warning");
      }
    });
  }

  getLink = () => {
    const { dataProvider, selectedIds, clients, showNotification } = this.props;

    this.setState({
      loadingGet: true
    });

    dataProvider("GET", "clients", {
      resource: "link",
      id: clients[selectedIds[0]].auth_id
    }).then(({ data }) => {
      this.setState({
        loadingGet: false
      });
      if (data.success) {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(data.url).then(() => {
            showNotification("Ссылка скопирована", "info");
          }, function(err) {
            showNotification("Невозможно скопировать", "warning");
          });
        } else {
          try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
          } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            showNotification("Невозможно скопировать", "warning");
          }
          showNotification("Невозможно скопировать", "warning");
          this.setState({
            showInput: true,
            url: data.url
          });
        }
      } else {
        showNotification(data.error, "warning");
      }
    });
  }

  render() {
    const { selectedIds, clients, classes } = this.props;

    const confirmed = clients[selectedIds[0]] && clients[selectedIds[0]].status ? true : false;

    return (
      <Fragment>
        <Button label={"resources.clients.actions.confirm"} onClick={this.handleClickOpen} />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle>
            {confirmed && <span>Клиент подтвержден</span>}
            {!confirmed && <span>Подтвердить</span>}
          </DialogTitle>
          <DialogContent>
            {!confirmed && <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isSendSms}
                    onChange={this.handleChange}
                  />
                }
                label={"Отправить СМС с ссылкой доступа?"}
              />
            </FormControl>}
            {confirmed && <div style={{
              maxWidth: "320px"
            }}>
              <MuiButton
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.sendLink}
              >
                {this.state.loadingSend ? 
                  <CircularProgress color="inherit" size={24} className={classes.leftIcon}/> :
                  <Icon className={classes.leftIcon}>send</Icon>
                }
                Отправить СМС с доступом
              </MuiButton>
              <MuiButton
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.getLink}
              >
              {this.state.loadingGet ? 
                  <CircularProgress color="inherit" size={24} className={classes.leftIcon}/> :
                  <Icon className={classes.leftIcon}>content_copy</Icon>
                }
                Копировать ссылку доступа
              </MuiButton>
            </div>}
            {this.state.showInput && <div>
              <TextField
                label="Ссылка доступа"
                className={classes.textField}
                defaultValue={this.state.url}
              />
            </div>}
          </DialogContent>
          <DialogActions>
            {!confirmed && <SaveButton
              basePath=""
              onClick={this.handleClick}
            />}
            {confirmed &&
              <MuiButton variant="contained" color="primary" className={classes.button} onClick={this.handleClose}>
                Закрыть
              </MuiButton>
            }
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.admin.resources.clients.data
});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { clientConfirm, setListSelectedIds, showNotification }
  ),
  withStyles(styles),
  withDataProvider
)(ConfirmButton);
