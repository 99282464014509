import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { translate } from "react-admin";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import findIndex from "lodash/findIndex";

class ChipInput extends Component {
  static propTypes = {
    isTranslated: PropTypes.bool
  };

  static defaultProps = {
    isTranslated: true
  };

  state = {
    isFocused: false,
    value: ""
  };

  handleClick = () => {
    this.setState({
      isFocused: true
    });
  };

  handleBlur = () => {
    this.setState({
      isFocused: false
    });
  };

  render() {
    const {
      label,
      children,
      translate,
      source,
      isTranslated,
      filterForm,
      choices
    } = this.props;
    const otherProps = Object.assign({}, this.props);

    delete otherProps.children;
    delete otherProps.isTranslated;
    delete otherProps.filterForm;

    otherProps.onBlur = this.handleBlur;
    otherProps.autoFocus = true;

    let chipLabel = !isTranslated ? translate(label) : label;

    if (filterForm.values) {
      if (filterForm.values[source]) {
        const value = filterForm.values[source];
        
        if (typeof value === "string" || typeof value === "number") {
          if (choices) {
            if (choices.length > 0) {
              const choiceIndex = findIndex(choices, { id: value });
              chipLabel += !isTranslated ? ": " + translate(choices[choiceIndex].name) : ": " + choices[choiceIndex].name;
            }
          } else {
            chipLabel += ": ";
            chipLabel += value;
          }
        } else {
          if (value.from || value.to) {
            chipLabel += ": ";
            const from =
              value.from > 0
                ? translate("from") + " " + value.from
                : translate("from") + " " + 0;
            const to =
              value.to > 0
                ? translate("to") + " " + value.to
                : translate("to") + "...";
            chipLabel += from + " " + to;
          } else if (Array.isArray(value)) {
            chipLabel += ": ";
            value.forEach((id, key) => {
              if (choices && choices.length > 0) {
                const choiceIndex = findIndex(choices, { id: id });

                if (key === value.length - 1) {
                  chipLabel += (choices[choiceIndex] && choices[choiceIndex].name) || "";
                } else {
                  chipLabel += (choices[choiceIndex] && choices[choiceIndex].name) ? choices[choiceIndex].name + ", " : "";
                }
              }
            });
          }
        }
      }
    }

    return this.state.isFocused ? (
      React.cloneElement(children, otherProps)
    ) : (
      <Chip
        label={chipLabel}
        onClick={this.handleClick}
        onBlur={this.handleBlur}
      />
    );
  }
}

const mapStateToProps = state => ({
  filterForm: state.form.filterForm
});

const mapDispatchToProps = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate
)(ChipInput);
