import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { bpStart } from "../../actions/bpActions";

import BpStartButton from "../../components/button/BpStartButton";
import dataProviderFactory from "../../dataProvider";

class TasksPanel extends Component {
  state = {
    tasks: null
  };

  componentWillMount() {
    const { record, resource } = this.props;
    const { id, user_id } = record;
    let object_id, client_id;

    if (resource === "objects") {
      object_id = id;
    }

    if (resource === "clients") {
      client_id = id;
    }

    this.fetchTasks(object_id, client_id, user_id);
  }

  fetchTasks = (object_id, client_id, user_id) => {
    if ((object_id || client_id) && user_id) {
      dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
        dataProvider => {
          dataProvider("GET_TASKS", "tasks", {
            object_id,
            client_id,
            to_id: user_id
          }).then(({ data }) => {
            if (data) {
              this.setState({
                tasks: data
              });
            }
          });
        }
      );
    }
  };

  handleClickTask = (record, task) => () => {
    this.props.bpStart({
      object: record,
      user: record.user,
      bp: task,
      section: 'objects'
    });
  };

  render() {
    const { record } = this.props;
    const { tasks } = this.state;
    
    return tasks ? (
      <div>
        {
          tasks.map(task => {
            return <div key={task.id}>
              <BpStartButton
                label={task.title}
                disabled={task.active === 0 || task.status === 1}
                status={task.status}
                handleClick={this.handleClickTask(record, task)}
              />
            </div>
          })
        }
      </div>
    ) : null;
  }
}

const mapStateToProps = state => ({});

export default compose(
  connect(
    mapStateToProps,
    { bpStart }
  )
)(TasksPanel);
