export default {
  "Failed to fetch": "Не удалось получить данные",
  "Grouped:": "Сгруппировано:",
  "Number of objects:": "Количество объектов:",
  "Object sold out": "Объекты отправлены на актуализацию",
  "Object restored": "Объекты отправлены на восстановление",
  "Discontinued objects found": "Поиск упавших объектов",
  "Discontinued objects send to autocall": "Объекты отправлены на автообзвон",
  "Go to": "Перейти",
  Add: "Добавить",
  "Not Found": "Не найден",
  "Objects transferred":
    "Передано объектов: %{countAffected}, не передано: %{countNotAaffected}, т.к. они заняты.",
  "Removed from contractors": "Удалено %{countAffected} объектов",
  "Transfer or delete": "Передать или удалить",
  "Transfer to": "Передать",
  Delete: "Удалить",
  "Customer calls": "Звонки клиента",
  Messages: "Сообщения",
  Notifications: "Уведомления",
  "Missed Calls": "Пропущенные звонки",
  Chat: "Чат",
  "Saved filters": "Сохраненные фильтры",
  "Save filter": "Сохраненить фильтр",
  "Filter saved": "Фильтр сохранени",
  "Add address": "Добавить адрес",
  "Street added": "Адрес добавлен",
  "Delete?": "Снять объект?",
  Yes: "Да",
  "Phone assigned": "Телефон привязан",
  "Posting objects": "Постинг объявлений",
  ra: {
    media: {
      imageCountSeparator: " из ",
      fileManagerDialog: "Менеджер файлов"
    },
    action: {
      add_filter: "Добавить фильтр",
      add: "Добавить",
      back: "Вернуться",
      bulk_actions:
        "%{smart_count} элементов выбрано |||| %{smart_count} элемента выбрано",
      cancel: "Закрыть",
      clear_input_value: "Очистить",
      clone: "Дублировать",
      create: "Создать",
      delete: "Удалить",
      edit: "Редактировать",
      export: "Экспорт",
      list: "Список",
      refresh: "Обновить",
      remove_filter: "Удалить фильтр",
      remove: "Удалить",
      save: "Сохранить",
      apple: "Применить",
      search: "Поиск",
      show: "Показать",
      sort: "Сортировать",
      undo: "Отменить",
      complete: "Завершить",
      undo_complete: "Отменить завершение",
      close: "Закрыть"
    },
    status: {
      completed: "Завершена",
      processing: "В процессе"
    },
    boolean: {
      true: "Да",
      false: "Нет"
    },
    page: {
      create: "Создать %{name}",
      dashboard: "Главная",
      edit: "%{name} #%{id}",
      error: "Что-то пошло не так",
      list: "%{name} Список",
      loading: "Загрузка",
      not_found: "Не найден",
      show: "%{name} #%{id}"
    },
    input: {
      file: {
        upload_several:
          "Перетащите файлы для загрузки или нажмите, чтобы выбрать их.",
        upload_single:
          "Перетащите файл для загрузки или нажмите, чтобы выбрать его."
      },
      image: {
        upload_several:
          "Перетащите изображения для загрузки или нажмите, чтобы выбрать их.",
        upload_single:
          "Перетащите изображение для загрузки или нажмите, чтобы выбрать его."
      },
      references: {
        all_missing: "Не удалось найти ссылки на данные.",
        many_missing:
          "По крайней мере, одна из связанных ссылок больше не доступна.",
        single_missing: "Связанная ссылка больше не доступна."
      },
      checkbox: {
        read: "Чтение",
        create: "Создание",
        update: "Редактирование",
        delete: "Удаление"
      },
      label: {
        users: "Пользователи",
        objects: "Объекты"
      }
    },
    message: {
      about: "Об",
      are_you_sure: "Вы уверены?",
      bulk_delete_content:
        "Вы действительно хотите удалить - %{name}? |||| Вы действительно хотите удалить эти элементы: %{smart_count}?",
      bulk_delete_title:
        "Удалить %{name} |||| Удалить %{smart_count} %{name} элементов",
      delete_content: "Вы уверены, что хотите удалить этот элемент?",
      delete_title: "Удалить %{name} #%{id}",
      details: "Детали",
      error: "Произошла ошибка клиента, и ваш запрос не был выполнен.",
      invalid_form: "Форма недействительна. Проверьте ошибки",
      loading: "Страница загружается, подождите пожалуйста",
      no: "Нет",
      not_found:
        "Либо вы набрали неверный URL-адрес, либо столкнулись с плохой ссылкой.",
      yes: "Да"
    },
    navigation: {
      no_results: "Результаты не найдены",
      no_more_results:
        "The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_out_from_end: "Cannot go after last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} из %{total}",
      page_rows_per_page: "Строк на страницу:",
      next: "Далее",
      prev: "Назад"
    },
    auth: {
      user_menu: "Профиль",
      login: "Логин",
      password: "Пароль",
      sign_in: "Войти",
      sign_in_error: "Ошибка аутентификации, повторите попытку",
      logout: "Выйти",
      hint: "Введите логин и пароль"
    },
    profile: {
      last_name: "Фамилия",
      name: "Имя",
      middle_name: "Отчество",
      phone: "Телефон",
      line: "Корпоративный",
      email: "E-mail"
    },
    notification: {
      updated: "Элемент обновлен |||| Обновлено %{smart_count} элементов",
      created: "Элемент создан",
      deleted: "Элемент удален |||| Удалены элементы %{smart_count}",
      bad_item: "Неправильный элемент",
      item_doesnt_exist: "Элемент не существует",
      http_error: "Ошибка связи с сервером",
      data_provider_error: "dataProvider error. Подробнее см. В консоли.",
      canceled: "Действие отменено"
    },
    validation: {
      required: "Обязателен",
      minLength: "Должно быть %{min} символов как минимум",
      maxLength: "Должно быть %{max} символов или меньше",
      minValue: "Должно быть не менее %{min}",
      maxValue: "Должно быть %{max} или меньше",
      number: "Должено быть числом",
      email: "Должно быть действительным электронное адресом",
      oneOf: "Должено быть одним из: %{options}",
      regex:
        "Должено соответствовать определенному формату (регулярное выражение): %{pattern}"
    }
  },
  pos: {
    search: "Поиск",
    profile: "Профиль",
    configuration: "Настройки",
    language: "Язык",
    theme: {
      name: "Тема",
      light: "Светлая",
      dark: "Темная"
    },
    dashboard: {
      new_clients: "Новые клиенты",
      missed_calls: "Пропущенные звонки",
      tasks_count: "Количество задач",
      overdue_tasks_count: "Просроченных задач",
      pending_orders: "Проданные объекты"
    },
    menu: {
      users: "Пользователи",
      tasks: "Задачи",
      handbooks: "Справочники",
      pbx: "Телефония"
    }
  },
  measure: {
    m2: "м<sup>2</sup>"
  },
  from: "от",
  to: "до",
  more: "еще",
  "Read more": "Читать еще",
  User: "Пользователь",
  resources: {
    scripts: {
      name: "Скрипты",
      fields: {
        name: "Название",
        createdAt: "Дата",
        section: "Раздел"
      },
      input: {
        answer: "Ответ",
        answers: "Ответы",
        question: "Вопрос",
        questions: "Вопросы",
        script: "Скрипт",
        section: "Раздел"
      }
    },
    "pbx/phones": {
      name: "Пул номеров",
      phones: "Пул номеров",
      audio: "Записи",
      bl: "ЧС",
      assign: "Привязать телефон",
      fields: {
        user_id: "Пользователь",
        phone: "Телефон",
        createdAt: "Дата"
      },
      input: {
        phones: "Телефоны",
        phone: "Телефон"
      },
      group: {
        user_id: "Пользователь",
        createdAt: "Дата",
        phone: "Телефон"
      }
    },
    "pbx/audio": {
      name: "Записи разговоров",
      fields: {
        calldate: "Дата",
        record: "Запись",
        user_id: "Агент",
        object_id: "Объект",
        client: {
          name: "Клиент",
          id: "Клиент"
        }
      },
      group: {
        user_id: "Агент",
        object_id: "Объект",
        createdAt: "Дата"
      },
      table: {
        filter: {
          date: "Дата"
        }
      }
    },
    "pbx/bl": {
      name: "Черный список",
      input: {
        phonefrom: "Номер от",
        phoneto: "Номер до",
        phonetype: "Тип номера"
      },
      fields: {
        phones: "Маска",
        user_id: "Пользователь",
        createdAt: "Дата"
      },
      group: {
        phones: "Маска",
        user_id: "Пользователь",
        createdAt: "Дата"
      }
    },
    "pbx/calls": {
      name: "Звонок |||| Звонки",
      fields: {
        updatedAt: "Звонок был в",
        id: "Контакт",
        client: "Клиент",
        user_id: "Агент",
        object_id: "Объект",
        _call: "Звонок",
        status: "Статус",
        task_id: "По задаче",
        section: "Раздел",
        record: "Запись"
      },
      input: {},
      filter: {},
      group: {
        updatedAt: "Звонок был в",
        client: {
          name: "Клиент"
        },
        user_id: "Агент",
        object_id: "Объект",
        status: "Статус",
        updatedAt: "Звонок был в",
        id: "Контакт",
        _call: "Звонок",
        task_id: "По задаче",
        section: "Раздел",
        record: "Запись"
      }
    },
    action: {
      check: "Выбрать"
    },
    objects: {
      name: "Объект |||| Объекты",
      sold_out: "Актуализировать",
      restore: "Восстановить выбранные",
      fields: {
        createdAt: "Дата",
        updatedAt: "Обновлен/Создан",
        sold_out: "Актуализация",
        "user.name": "Агент",
        "types.name": "К",
        "region.name": "Р-н/мкр",
        "street.name": "Адрес",
        area: "Площадь",
        "series.name": "Серия",
        "type_house.name": "Тип",
        floor: "Этаж",
        cost_of_owner: "Цена вх/м2",
        cost_of_cmi: "Цена сми/м2",
        floor_house: "Этажность",
        search_object: "Выбрать из поиска",
        house: "Дом",
        entrance: "Подъезд",
        type_house: "Тип",
        User: "Агент",
        agent_parse: "Хозяин",
        "agent_parse.agent": "Агент",
        parse_source_status: "Снято с публикации",
        "parse_source_status.11_about_month": "Месяц и более",
        "parse_source_status.12_less_then_month": "Меньше месяца",
        "parse_source_status.21_about_month_confirmed": "Месяц и более (Подтверждено)",
        "parse_source_status.22_less_then_month_confirmed": "Меньше месяца (Подтверждено)",
        "agent_parse.owner": "Собственник",
        sale: "Продается",
        deletedAt: "Удален"
      },
      group: {
        createdAt: "дата",
        "user.name": "Агент",
        "types.name": "к",
        "region.name": "Р-н/мкр",
        "street.name": "",
        area: "кв",
        "series.name": "серия",
        "type_house.name": "тип",
        floor: "этаж",
        cost_of_owner: "цена вх/м2",
        cost_of_cmi: "цена сми/м2",
        floor_house: "этажность",
        house: "д",
        entrance: "п",
        type_house: "тип",
        user_id: "Агент",
        sold_out: "Актуализация",
      },
      action: {
        saved_filter: "Сохраненные",
        save_filter: "Сохранить",
        find_discontinued: "Найти упавшие",
        send_to_auto_call: "Отправить на автообзвон",
        posting_export_xml: "Экспорт XML",
        posting_import_xml: "Импорт XML"
      },
      input: {
        no: "Нет",
        yes: "Да",
        sold_out: "Актуализация",
        restore: "Восстановление",
        clients: "Клиенты",
        filters: "Фильтры",
        filter_cat: "Категория фильтра",
        q: "Адрес",
        street: "Улица",
        streetr: "Улица*",
        manager: "Агент",
        cmi: "Сми",
        cost: "Цена",
        "cost.from": "Цена от",
        "cost.to": "до",
        floor: "Этаж",
        floorr: "Этаж*",
        "floor.from": "Этаж от",
        "floor.to": "до",
        floor_house: "Этажность",
        floor_houser: "Этажность*",
        "floor_house.from": "Этажность от",
        "floor_house.to": "до",
        area: "Площадь",
        arear: "Площадь*",
        "area.from": "Площадь от",
        "area.to": "до",
        pt: "Помещение",
        series: "Серия",
        seriesr: "Серия*",
        th: "Тип дома",
        regions: "Район",
        regionsr: "Район*",
        districts: "Микрорайон, Площадка, ЖК",
        categories: "Категория",
        categoriesr: "Категория*",
        house: "Дом",
        houser: "Дом*",
        entrance: "Подъезд",
        entrancer: "Подъезд*",
        flat: "Квартира",
        flatr: "Квартира*",
        type_house: "Тип дома",
        type_houser: "Тип дома*",
        transaction_type: "Вид сделки",
        conditions: "Состояние объекта",
        completed_year: "Год сдачи",
        photos: "Фотографии",
        squared_area: "Площадь участка без огранич.",
        area_without_balcony: "Площадь без балкона",
        area_with_balcony: "Площадь с балконом",
        balcony_coef: "Площадь с коэф-том балкона",
        area_commercial: "Площадь для рекламы",
        cost_of_in: "Входящая (руб.)",
        cost_of_out: "Исходящая (руб.)",
        cost_of_recommended: "Рекомендованная (руб.)",
        cost_of_owner: "Стоимость собственника",
        cost_of_ownerr: "Стоимость собственника*",
        cost_of_cmi: "Стоимость реклама",
        contact_people: "Контактное лицо",
        type_contact_phone: "Тип",
        contact_info: "Телефон",
        contact_infor: "Телефон*",
        contact_info_2: "Телефон 2",
        contact_info_3: "Телефон 3",
        status_dog: "Статус договора",
        sales_status: "Статус ликвидности",
        targets: "Цель",
        using: "С использованием",
        years_old: "Кол-во лет в собственности",
        year: "Год выпуска",
        additional: "Дополнительно",
        redevelopment: "Наличие перепланировки",
        owners: "Кол-во собственников",
        prescription: "Кол-во прописанных",
        mortgage: "Объект в ипотеке",
        arrest: "Под арестом",
        files: "Правоустанавливающий документ, Наличие зеленки",
        proptypes: "Тип недвижимости",
        images: "Изображения",
        coords: "Координаты",
        developer: "Застройщик",
        developerr: "Застройщик*",
        house_area: "Площадь дома",
        land_area: "Площадь участка",
        client: "Клиент",
        contractors: "Подрядчики",
        parse_source_status: "Снятые с публ.",
        agent_parse: "Хозяин"
      },
      button: {
        select_image: "Выбрать изображения",
        legal_analysis: "Правовой анализ",
        stats: "Статистика",
        preview: "Предпросмотр",
        clients: "Клиенты",
        posting: "Постинг"
      },
      notification: {
        approved_success: "Объект одобрен",
        approved_error: "Ошибка: Объект не одобрен",
        rejected_success: "Объект отклонен",
        rejected_error: "Ошибка: Объект не отклонен"
      },
      tabs: {
        address: "Адрес",
        cmi: "Примечания для СМИ",
        property_info: "О недвижимости",
        additional_info: "Дополнительно",
        area: "Площадь",
        prices: "Стоимость",
        contacts: "Контактная информация",
        analysis: "Правовой анализ объекта",

        label: {
          cmi: "Текст объявления оригинальный",
          cmi_posting: "Текст объявления на постинг"
        }
      }
    },
    users: {
      name: "Пользователь |||| Пользователи",
      fields: {
        createdAt: "Дата регистрации",
        user: "Пользователь",
        email: "E-mail",
        roles: "Роль",
        fio: "ФИО",
        phone: "Телефон"
      },
      tabs: {
        main: "Основные",
        profile: "Профиль",
        accesses: "Доступы"
      },
      input: {
        role: "Роль"
      }
    },
    roles: {
      name: "Роль |||| Роли",
      fields: {
        role_title: "Название роли",
        slug: "Идентификатор"
      },
      tabs: {
        main: "Основные",
        accesses: "Доступы"
      }
    },
    tasks: {
      name: "Задача |||| Задачи",
      list: "Список",
      templates: 'Шаблоны',
      fields: {
        createdAt: "Дата создания",
        deadline: "Время выполнения",
        title: "Название",
        to_id: "Исполнитель",
        from_id: "Заказчик",
        status: "Статус",
        percent: "Выполнено %",
        script_id: "Скрипт",
        object_id: "Объект",
        client_id: "Клиент",
        object: "Объект",
        client: "Клиент",
        section: "Раздел"
      },
      input: {
        title: "Название",
        section: "Раздел",
        type: "Тональность",
        description: "Описание задачи",
        files: "Файлы",
        to_id: "Исполнитель",
        deadline_d: "Время выполнения(дни)",
        deadline_h: "Время выполнения(часы)",
        deadline_m: "Время выполнения(минуты)",
        checklist: "Чек лист",
        script_id: "Скрипт",
        object_id: "Объект",
        client_id: "Клиент",
        object: "Объект",
        client: "Клиент"
      }
    },
    "tasks-templates": {
      name: "Шаблон зачи |||| Шаблоны задач",
      list: "Список",
      templates: 'Шаблоны',
      fields: {
        createdAt: "Дата создания",
        deadline: "Время выполнения",
        title: "Название",
        to_id: "Исполнитель",
        from_id: "Заказчик",
        status: "Статус",
        percent: "Выполнено %",
        script_id: "Скрипт",
        section: "Раздел"
      },
      input: {
        title: "Название",
        description: "Описание задачи",
        files: "Файлы",
        to_id: "Исполнитель",
        deadline_d: "Время выполнения(дни)",
        deadline_h: "Время выполнения(часы)",
        deadline_m: "Время выполнения(минуты)",
        checklist: "Чек лист",
        script_id: "Скрипт",
        section: "Раздел"
      },
      filter: {
        categories: "Категория"
      }
    },
    "event-actions": {
      name: "Действие |||| Действия",
      fields: {
        name: "Название",
        event: "Событие",
        action: "Действие",
        updatedAt: "Обновлено"
      },
      input: {
        name: "Название",
        event: "Событие",
        action: "Действие",
        section: "Раздел",
        task: "Шаблон задачи",
        call_to: "Позвонить...",
      }
    },
    contractors: {
      name: "Подрядчик |||| Подрядчики",
      fields: {
        name: "Наименование",
        manager: "Менеджер",
        district: "Перечень площадок",
        developer: "Перечень застройщиков",
        date_contact: "Контакт(послед)"
      },
      input: {
        districts: "Микрорайон, Площадка, ЖК",
        developers: "Застройщик",
        contractors: "Подрядчик",
        user: "Менеджер",
        district: "Микрорайоны, Площадки, ЖК",
        developer: "Застройщики",
        inn: "ИНН",
        state_contractor: "Статус подрядчика",
        status_contact: "Статус контакта",
        name: "Имя",
        phone: "Телефон",
        email: "Email",
        type_contact: "Способ связи",
        ismain: "Основной?",
        contacts: "Контакты"
      }
    },
    clients: {
      name: "Клиент |||| Клиенты",
      fields: {
        manager: "Менеджер",
        fio: "ФИО",
        data_recipe: "Дата поступления",
        last_contact: "Контакт(послед)",
        categories: "Категория",
        area_from: "S от/до",
        area_to: "Площадь до",
        cost_to: "Цена до",
        name: "ФИО",
        object: "Объект"
      },
      input: {
        spam: "Спам",
        phoner_1: "Телефон 1 *",
        phone_2: "Телефон 2",
        phone_3: "Телефон 3",
        manager: "Менеджер",
        fio: "ФИО",
        last_contact: "Контакт(послед)",
        data_recipe: "Дата поступления *",
        categories: "Категория",
        area_from: "S от/до *",
        area_to: "Площадь до *",
        cost_to: "Цена до",
        proptypes: "Объект",
        type_house: "Тип дома",
        series: "Серия",
        floors: "Этаж",
        street: "Улица",
        regions: "Район",
        districts: "Микрорайон, Площадка, ЖК",
        house: "Дом (Через точку с запятой)",
        activity_status: "Статус активности"
      },
      tabs: {
        general_info: "Общая инфо клиента",
        object: "Объект",
        location: "Локация",
        additional: "Доп параметры"
      },
      notification: {
        confirm_success: "Клиент подтвержден",
        confirm_error: "Клиент не подтвержден"
      },
      actions: {
        confirm: "Подтвердить"
      }
    },
    districts: {
      name: "М., П., ЖК |||| М., П., ЖК",
      title: "Микрорайон, Площадка, ЖК",
      fields: {
        name: "Название",
        id_cian: "ID ЖК в базе Циан",
        geo_coordinates: "Координаты",
        updatedAt: "Обновлен/Создан"
      }
    },
    series: {
      name: "Серия |||| Серии",
      fields: {
        name: "Название",
        updatedAt: "Обновлен/Создан"
      }
    },
    bp: {
      name: "БП |||| БП"
    },
    posters: {
      name: "Аккаунт |||| Аккаунты",
      list: "Список",
      fields: {
        statusAccount: "Статус",
        phone: "Телефон",
        login: "Логин (email)",
        password: "Пароль",
        name: "Имя",
        url: "URL объявления",
        attempts: "Попытки",
        status: "Статус",
        statusConfirmed: "Статус подтвержденный",
        postedAt: "Запощен",
        createdAt: "Создан",
        updatedAt: "Обновлен/Создан"
      }
    },
  },
  table: {
    filter: {
      selection: {
        section: "Раздел",
        sold_out: "Актуализация",
        client_id: "Клиент",
        createdAt: "Дата",
        "user.name": "Агент",
        "types.name": "К",
        "region.name": "Р-н/мкр",
        "street.name": "Адрес",
        area: "Площадь",
        "series.name": "Серия",
        "type_house.name": "Тип",
        floor: "Этаж",
        cost_of_owner: "Цена вх/м2",
        cost_of_cmi: "Цена сми/м2",
        edit: "Редактирование",
        show: "Просмотр",
        name: "ФИО",
        email: "E-mail",
        roles: "Роль",
        User: "Пользователь",
        deadline: "Время выполнения",
        title: "Название",
        to_id: "Исполнитель",
        from_id: "Заказчик",
        status: "Статус",
        percent: "Выполнено %",
        data_recipe: "Дата поступления",
        last_contact: "Контакт(послед)",
        categories: "Категория",
        fio: "ФИО",
        area_from: "S от/до",
        area_to: "Площадь до",
        user_id: "Агент",
        phone: "Телефон",
        record: "Запись",
        user: "Агент",
        calldate: "Звонок был в",
        object_id: "Объект",
        phones: "Маска",
        updatedAt: "Звонок был в",
        client: {
          name: "Клиент",
          id: "Клиент"
        },
        id: "Контакт",
        _call: "Звонок",
        task_id: "По задаче",
        resources: {
          roles: {
            fields: {
              role_title: "Название роли"
            }
          },
          objects: {
            fields: {
              User: "Агент"
            }
          },
          contractors: {
            fields: {
              manager: "Менеджер",
              name: "Наименование"
            }
          },
          clients: {
            fields: {
              manager: "Менеджер",
              object: "Объект"
            }
          },
          tasks: {
            fields: {
              object: "Объект",
              client: "Клиент"
            }
          }
        },
        district: "Перечень площадок",
        developer: "Перечень застройщиков",
        slug: "Идентификатор",
        date_contact: "Контакт(послед)"
      }
    }
  },
  validation: {
    required: "Поле %{name} обязательно для ввода"
  }
};
