import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import moment from "moment";
import 'moment/locale/ru';

import {
  List,
  FunctionField,
  Link,
  Filter,
  DateInput,
  ReferenceField,
  Pagination
} from "react-admin";

import { PBX_URL } from "../../consts";

import GroupDatagrid from "../../components/ra-customizable-datagrid/GroupDatagrid";

import ObjectModal from "../../objects/ObjectModal";
import { parse, stringify } from "query-string";
import { push } from "react-router-redux";
import { Route } from "react-router";
import get from "lodash/get";

import FullNameField from "../../components/field/FullNameField";
import UserQuickPreview from "../../users/UserQuickPreview";
import AudioPlayer from "react-h5-audio-player";
import Avatar from "@material-ui/core/Avatar";

const PbxAudioFilter = props => (
  <Filter {...props}>
    <DateInput
      source="date"
      label="resources.pbx/audio.table.filter.date"
      alwaysOn
    />
  </Filter>
);

moment.locale('ru');
const renderDate = record => {
  const m1 = moment(record.calldate)
  const m2 = moment(record.calldate).format('HH:mm')
  return m1.fromNow() + ' в ' + m2
}

const renderPlayer = record => {
  return <AudioPlayer src={`${PBX_URL}/?record=${record.record}`} />;
};

const renderClientName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id,
      resource: "clients"
    })}`;
    return (
      <Link
        to={`/pbx/audio${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const renderObject = location => record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const params = parse(location.search);
  const query = `?${stringify({
    ...params,
    object: record.id
  })}`;
  if (record.images) {
    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");
    return (
      <Link
        to={`/pbx/audio${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          <Avatar alt={street + " " + house} src={src} />
          <span
            style={{
              marginLeft: 3
            }}
          >
            {street} {house}
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/pbx/audio/?object=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span>
          {street} {house}
        </span>
      </Link>
    );
  }
};

const renderName = location => record => {
  if (record) {
    const params = parse(location.search);
    const query = `?${stringify({
      ...params,
      user: record.id
    })}`;
    return (
      <Link
        to={`/pbx/audio${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <FullNameField record={record} size={32} />
      </Link>
    );
  }
};

const PbxAudioPagination = props => (
  <Pagination rowsPerPageOptions={[1000]} {...props} />
);

class PbxAudioTable extends Component {
  render() {
    const props = this.props;

    return (
      <GroupDatagrid {...props} rowClick={null}>
        <FunctionField source="calldate" style={{ fontStyle: "italic" }} render={renderDate} />
        <ReferenceField
          source="object_id"
          reference="objects"
          linkType={false}
          allowEmpty
        >
          <FunctionField
            source="object"
            render={renderObject(props.location)}
          />
        </ReferenceField>
        <ReferenceField
          source="client.id"
          reference="clients"
          linkType={false}
          allowEmpty
        >
          <FunctionField
            source="name"
            render={renderClientName(props.location)}
          />
        </ReferenceField>
        <ReferenceField
          source="user_id"
          reference="users"
          linkType={false}
          allowEmpty
        >
          <FunctionField source="user" render={renderName(props.location)} />
        </ReferenceField>
        <FunctionField
          source="record"
          render={renderPlayer}
          style={{ width: 280 }}
        />
      </GroupDatagrid>
    );
  }
}

class PbxAudioList extends Component {
  handleClose = ev => {
    const params = parse(this.props.location.search);

    delete params.object;
    delete params.user;
    delete params.resource;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };
  render() {
    const props = this.props;
    return (
      <Fragment>
        <List
          {...props}
          pagination={<PbxAudioPagination />}
          perPage={1000}
          sort={{ field: "createdAt", order: "DESC" }}
          filters={<PbxAudioFilter />}
        >
          <PbxAudioTable location={props.location} />
        </List>
        <Route path="/pbx/audio">
          {route => {
            const values = parse(route.location.search);

            let id = null;
            let resource = null;

            if (values.user) {
              id = values.user;
            }

            if (values.resource) {
              resource = values.resource;
            }

            if (values.object) {
              const object = values.object;
              return (
                <ObjectModal
                  id={object}
                  open={!!object}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            return id ? (
              <UserQuickPreview
                id={id}
                open={!!id}
                resource={resource}
                handleClose={this.handleClose}
                permissions={this.props.permissions}
              />
            ) : null;

          }}
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(PbxAudioList);
