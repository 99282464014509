export function formatPrice (price, minimumFractionDigits, maximumFractionDigits) {
  if (!price) {
    price = 0
  }
  return price.toLocaleString('ru', {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: 'symbol',
    minimumFractionDigits: maximumFractionDigits || 0,
    maximumFractionDigits: maximumFractionDigits || 0
  })
}

export function formatDate (d) {
  if (d) {
    let date = new Date(d)

    let options = {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }

    return `${date.toLocaleDateString('ru', options)}`
  }
  return ''
}

export function getNumberWithLabel (n, form1, form2, form5) {
  return `${n} ${pluralForm(n, form1, form2, form5)}`
}

export function pluralForm (n, form1, form2, form5) {
  n = Math.abs(n) % 100
  let n1 = n % 10
  if (n > 10 && n < 20) return form5
  if (n1 > 1 && n1 < 5) return form2
  if (n1 === 1) return form1
  return form5
}

export function formatPhone (phone) {
  if (phone && phone.length === 10) {
    return phone.toString().replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7($1) $2 $3 $4')
  } else if (phone) {
    return phone.toString().replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1($2) $3 $4 $5')
  } else {
    return ''
  }
}