import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import 'moment/locale/ru';

import {
  List,
  Datagrid,
  Pagination,
  TextField,
  ReferenceField,
  FunctionField,
  TextInput,
  Filter,
  Link
} from "react-admin";

import Avatar from "@material-ui/core/Avatar";

import { parse, stringify } from "query-string";
import { push } from "react-router-redux";
import { Route } from "react-router";
import { get } from "lodash";

import ObjectModal from "../objects/ObjectModal";

moment.locale('ru');
const renderDate = date => record => {
  if (!record[date]) {
    return ''
  }
  const m1 = moment(record.updatedAt).utcOffset(8).format('YYYY-MM-DD HH:mm')
  const m2 = moment(record.updatedAt).utcOffset(8).format('HH:mm')
  return moment(m1).fromNow() + ' в ' + m2
}

const renderStatusAccount = record => {
  let statusAccountDisplayed = (
    (1 === record.statusAccount && 'Бан') ||
    (2 === record.statusAccount && 'Не подтвержден') ||
    (3 === record.statusAccount && 'Не зарегистрирован') ||
    (4 === record.statusAccount && 'Телефон не подтвержден') ||
    (10 === record.statusAccount && 'Изменение контента') ||
    (30 === record.statusAccount && 'Размещен 1 мес') ||
    ''
  )

  return `${statusAccountDisplayed}${(record.statusAccount && ' (') || ''}${record.statusAccount || ''}${(record.statusAccount && ')') || ''}`
}

const renderObject = location => record => {
  const street = get(record, "street.name");
  const house = get(record, "house");
  const params = parse(location.search);
  const query = `?${stringify({
    ...params,
    object: record.id
  })}`;
  if (record.images) {
    let images = get(record, "images");
    if (typeof images === "string") {
      images = JSON.parse(images);
    }

    const src = get(images, "main.thumb.src");
    return (
      <Link
        to={`/posters${query}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          <Avatar alt={street + " " + house} src={src} />
          <span
            style={{
              marginLeft: 3
            }}
          >
            {street} {house}
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/posters/?object=${record.id}`}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <span>
          {street} {house}
        </span>
      </Link>
    );
  }
};

const PostersTable = props => (
  <Datagrid {...props} rowClick="edit">
    <TextField source="phone" />
    <TextField source="login" />
    <TextField source="password" />
    <FunctionField source="statusAccount" render={renderStatusAccount} />
    <FunctionField source="postedAt" style={{ fontStyle: "italic" }} render={renderDate('postedAt')} />
    <ReferenceField
      source="object_id"
      reference="objects"
      label="resources.tasks.fields.object"
      linkType={false}
      allowEmpty
    >
      <FunctionField source="object_id" render={renderObject(props.location)} />
    </ReferenceField>
    <FunctionField source="updatedAt" style={{ fontStyle: "italic" }} render={renderDate('updatedAt')} />
  </Datagrid>
);

const PostersPagination = props => (
  <Pagination rowsPerPageOptions={[5, 10, 15, 20]} {...props} />
);

const PostersFilter = props => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <TextInput label="Title" source="title" defaultValue="Hello, World!" />
  </Filter>
);

class PostersList extends Component {

  handleClose = ev => {
    const params = parse(this.props.location.search);
    delete params.object;

    this.props.push({
      ...this.props.location,
      search: `?${stringify({
        ...params
      })}`
    });
  };

  render() {
    const props = this.props;
    return (
        <Fragment>
          <List
            {...props}
            filters={<PostersFilter />}
            pagination={<PostersPagination />}
            perPage={10}
            sort={{ field: "updatedAt", order: "DESC" }}
          >
            <PostersTable location={props.location} />
          </List>
          <Route path="/pbx/phones">
          {route => {
            const values = parse(route.location.search);

            if (values.object) {
              const object = values.object;
              return (
                <ObjectModal
                  id={object}
                  open={!!object}
                  handleClose={this.handleClose}
                  {...this.props}
                />
              );
            }

            return null;
          }}
        </Route>
        </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location
});

export default connect(
  mapStateToProps,
  { push }
)(PostersList);
