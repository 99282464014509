import React, { Component, Fragment } from "react";

import {
  Button,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  REDUX_FORM_NAME,
  UPDATE_MANY,
  showNotification,
  translate,
  setListSelectedIds,
  refreshView
} from "react-admin";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { compose } from "redux";
import dataProviderFactory from "../dataProvider";

import { parse } from "query-string";

export class TransferObjectsBotton extends Component {
  state = {
    open: false,
    saving: false,
    isDelete: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = () => {
    this.setState(state => ({ isDelete: !state.isDelete }));
  };

  handleSubmit = () => {
    const {
      source,
      resource,
      selectedIds,
      showNotification,
      setListSelectedIds,
      refreshView
    } = this.props;

    if (this.props[source] || this.state.isDelete) {
      this.setState({
        saving: true
      });
      dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
        dataProvider => {
          dataProvider(UPDATE_MANY, resource, {
            ids: selectedIds,
            data: {
              [source]: this.props[source],
              source: source,
              isDelete: this.state.isDelete
            }
          })
            .then(({ data }) => {
              let countAffected = 0;
              let countNotAaffected = 0;

              this.setState({
                saving: false,
                open: false
              });

              if (data) {
                data.forEach(item => {
                  if (item.id) {
                    countAffected++;
                  } else {
                    countNotAaffected++;
                  }
                });
              }

              if (!this.state.isDelete) {
                showNotification("Objects transferred", "info", {
                  messageArgs: {
                    countAffected,
                    countNotAaffected
                  }
                });
              } else {
                showNotification("Removed from contractors", "info", {
                  messageArgs: {
                    countAffected
                  }
                });
              }

              if (countAffected !== 0) {
                refreshView();
              }

              setListSelectedIds("objects", []);
            })
            .catch(e => {
              this.setState({
                saving: false,
                open: false
              });
              showNotification(e, "warning");
            });
        }
      );
    }
  };

  renderOptionText = record => {
    if (record.fio) {
      return record.fio;
    }

    return `${record.name !== null ? record.name : ""} ${
      record.last_name ? record.last_name : ""
    }`;
  };

  render() {
    const { source, label, reference, resource, translate, location } = this.props;
    let filter = null;
    if (reference === "clients") {
      const search = parse(location.search);
      if (search.filter) {
        filter = JSON.parse(search.filter)
      }
    }

    return (
      <Fragment>
        <Button label={label} onClick={this.handleClickOpen} />
        <Drawer
          anchor="right"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle id="form-dialog-title">
            {translate("Transfer or delete")}
          </DialogTitle>
          <DialogContent>
            <SimpleForm resource={resource} toolbar={null}>
              <ReferenceInput
                label={label}
                source={source}
                reference={reference}
                perPage={1000}
                filter={filter ? { client_id: filter._client_id } : {}}
                disabled={this.state.isDelete && reference === "users"}
              >
                <AutocompleteInput optionText={this.renderOptionText} />
              </ReferenceInput>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isDelete}
                    onChange={this.handleChange}
                  />
                }
                label={translate("Delete?")}
              />
            </FormControl>
            {this.state.isDelete ? (
              <SaveButton
                basePath=""
                onClick={this.handleSubmit}
                label="Yes"
                saving={this.state.saving}
                disabled={
                  !this.props[this.props.source] && reference !== "users"
                }
              />
            ) : (
              <SaveButton
                basePath=""
                label="Transfer to"
                onClick={this.handleSubmit}
                saving={this.state.saving}
              />
            )}
          </DialogActions>
        </Drawer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  [props.source]: formValueSelector(REDUX_FORM_NAME)(state, props.source),
  location: state.router.location
});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { showNotification, setListSelectedIds, refreshView }
  )
)(TransferObjectsBotton);
