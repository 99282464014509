import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  translate,
  CardActions,
  ListButton,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import PhoneInput from "../components/input/PhoneInput";
import DynamicGroupInput from "../components/input/DynamicGroupInput";

const validate = translate => values => {};

const styles = theme => ({
  card: {
    flex: "1 1 auto",
    overflow: "visible"
  },
  tabRoot: {
    minWidth: "auto"
  },
  tabSelected: {}
});

const ClientTitle = ({ record }) =>  record && record.fio? record.fio : null;

const ClientActions = ({ basePath }) => (
  <CardActions>
    <ListButton basePath={basePath} />
  </CardActions>
);

const renderOptionText = record => {
  if (record.fio) {
    return record.fio;
  }

  return `${record.name !== null ? record.name : ""} ${
    record.last_name ? record.last_name : ""
  }`;
};

const ClientEdit = ({ classes, ...props }) => {
  const { translate } = props;
  return (
    <Edit
      {...props}
      title={<ClientTitle />}
      classes={{ card: classes.card }}
      actions={<ClientActions />}
    >
      <SimpleForm
        validate={validate(translate)}
      >
        <TextInput source="fio" />
        <TextInput source="email" />
        <DynamicGroupInput source="general_phones">
          <PhoneInput
            source="phone_1"
            label="resources.clients.input.phoner_1"
            style={{ marginLeft: 8 }}
          />
          <PhoneInput
            source="phone_2"
            label="resources.clients.input.phone_2"
            style={{ marginLeft: 8 }}
          />
          <PhoneInput
            source="phone_3"
            label="resources.clients.input.phone_3"
            style={{ marginLeft: 8 }}
          />
        </DynamicGroupInput>
        <ReferenceInput
            label="resources.clients.input.manager"
            source="user_id"
            reference="users"
          >
            <AutocompleteInput optionText={renderOptionText} /> 
          </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  withStyles(styles),
  translate
)(ClientEdit);
