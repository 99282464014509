import {
  CONTENT_BAR_OPEN,
  CONTENT_BAR_CLOSE
} from "../actions/contentBarActions";

const defaultState = {};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case CONTENT_BAR_OPEN:
      return {
        ...previousState,
        [payload.name]: true,
        object: payload.object || null
      };
    case CONTENT_BAR_CLOSE:
      return {
        ...previousState,
        [payload.name]: false,
        object: null
      };
    default:
      return previousState;
  }
};
