import { START_BP, END_BP } from "../actions/bpActions";

const defaultState = {
};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case START_BP:
      return payload;
    case END_BP:
      return payload;
    default:
      return previousState;
  }
};
