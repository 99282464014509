export const NEW_MESSAGES_IN_ROOM = "NEW_MESSAGES_IN_ROOM";
export const CLEAR_NEW_MESSAGES_IN_ROOM = "CLEAR_NEW_MESSAGES_IN_ROOM";
export const SET_ROOMS = "SET_ROOMS";

export const setRooms = data => ({
  type: SET_ROOMS,
  payload: data
});

export const newMessagesInRoom = rooms => ({
  type: NEW_MESSAGES_IN_ROOM,
  payload: rooms
});

export const clearNewMessagesInRoom = object_id => ({
  type: CLEAR_NEW_MESSAGES_IN_ROOM,
  payload: object_id
});
