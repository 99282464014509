import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-images";
import { compose } from "redux";
import { translate } from "react-admin";
import GallerySlider from "./GallerySlider";
import GalleryVerticalSlider from "./GalleryVerticalSlider";
import GalleryGrid from "./GalleryGrid";

export class GalleryLightbox extends Component {
  static propTypes = {
    lightboxImages: PropTypes.array.isRequired,
    galleryImages: PropTypes.array.isRequired,
    ImageComponent: PropTypes.func,
    isSlider: PropTypes.bool,
    isSliderVertical: PropTypes.bool
  };

  static defaultProps = {
    isSlider: false,
    isSliderVertical: false
  };

  constructor() {
    super();
    this.state = {
      currentImage: 0
    };
  }

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  render() {
    const {
      lightboxImages,
      galleryImages,
      translate,
      ImageComponent,
      isSlider,
      isSliderVertical
    } = this.props;
    
    return (
      <Fragment>
        {isSlider && (
          <GallerySlider
            photos={galleryImages}
            onClick={this.openLightbox}
            {...this.props}
          />
        )}
        {isSliderVertical && (
          <GalleryVerticalSlider
            photos={galleryImages}
            onClick={this.openLightbox}
            {...this.props}
          />
        )}

        {!isSlider &&
          !isSliderVertical && (
            <GalleryGrid
              photos={galleryImages}
              onClick={this.openLightbox}
              {...this.props}
              ImageComponent={ImageComponent}
            />
          )}
        <Lightbox
          images={lightboxImages}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          imageCountSeparator={translate("ra.media.imageCountSeparator")}
        />
      </Fragment>
    );
  }
}

export default compose(translate)(GalleryLightbox);
