import React from "react";
import AvatarField from "./AvatarField";
import pure from "recompose/pure";

const FullNameField = ({ record = {}, size }) => {
  if (record.fio) {
    record.name = record.fio;
  }
  const n = record.name ? record.name[0] : "";
  const o = record.middle_name ? record.middle_name[0] : "";
  const f = record.last_name;

  let fullName = f ? (
    <span>
      {f} {n} {o}
    </span>
  ) : (
    <span>{record.name}</span>
  );
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      {record.photo && <AvatarField record={record} size={size} />}
      &nbsp;{fullName}
    </div>
  );
};

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
  source: "last_name",
  label: "resources.customers.fields.name"
};

export default PureFullNameField;
