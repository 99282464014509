import React, { Component, Fragment } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  Button,
  SaveButton,
  DeleteButton,
  translate,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";

import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import ContentFilter from "@material-ui/icons/FilterList";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { push } from "react-router-redux";

class SavedFilterButton extends Component {
  state = {
    open: false,
    filter_cat_id: null,
    filter: null,
    client_id: null,
    current_client_id: null
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      filter_cat_id: null,
      filter: null,
      client_id: null
    });
  };

  handleChangeCat = (ev, value) => {
    this.setState({
      filter_cat_id: value
    });
  };

  handleChangeFilter = (ev, value) => {
    this.setState({
      filter: value
    });
  };

  handleClick = () => {
    const filters = this.props.filters;
    const filter = [this.state.filter];

    if (filters && filters[filter] && filters[filter].filter) {
      this.props.push({
        ...this.props.location,
        search: filters[filter].filter
      });
      this.setState({
        open: false,
        filter_cat_id: null,
        filter: null,
        client_id: null
      });
    }
  };

  handleClickDelete = () => {}

  handleChangeClient = (ev, value) => {
    this.setState({
      client_id: null
    });

    setTimeout(() => {
      this.setState({
        client_id: value
      });
    }, 100);
  };

  renderOptionsClients = record => {
    if (record.fio) {
      return record.fio;
    }

    return `${record.name !== null ? record.name : ""} ${
      record.last_name ? record.last_name : ""
    }`;
  };

  render() {
    const { translate } = this.props;

    return (
      <Fragment>
        <Button
          label="resources.objects.action.saved_filter"
          onClick={this.handleClickOpen}
        >
          <ContentFilter />
        </Button>

        <Drawer
          open={this.state.open}
          onClose={this.handleClose}
          anchor="right"
        >
          <div>
            <Button label="ra.action.close" onClick={this.handleClose}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <DialogTitle id="form-dialog-title">
            {translate("Saved filters")}
          </DialogTitle>
          <DialogContent>
            <SimpleForm resource="filters" toolbar={null}>
              <ReferenceInput
                label="resources.objects.input.filter_cat"
                source="sfilter_cat_id"
                reference="filters/categories"
                perPage={25}
                onChange={this.handleChangeCat}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              {this.state.filter_cat_id === 2 && (
                <ReferenceInput
                  label="resources.objects.input.clients"
                  source="sclients"
                  reference="clients"
                  perPage={25}
                  sort={{ field: "fio", order: "ASC" }}
                  onChange={this.handleChangeClient}
                >
                  <AutocompleteInput optionText={this.renderOptionsClients} />
                </ReferenceInput>
              )}
              {this.state.filter_cat_id === 1 && (
                <ReferenceInput
                  label="resources.objects.input.filters"
                  source="sfilters"
                  reference="filters"
                  filter={{ filter_cat_id: 1 }}
                  perPage={25}
                  onChange={this.handleChangeFilter}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              )}
              {this.state.client_id && (
                <ReferenceInput
                  label="resources.objects.input.filters"
                  source="filters"
                  reference="filters"
                  filter={{ client_id: this.state.client_id }}
                  perPage={25}
                  onChange={this.handleChangeFilter}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              )}
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <DeleteButton
              label="Удалить"
              disabled={!this.state.filter}
              onClick={this.handleClickDelete}
            />
            <SaveButton
              label="Применить"
              disabled={!this.state.filter}
              onClick={this.handleClick}
            />
          </DialogActions>
        </Drawer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  filters: state.admin.resources.filters.data,
  location: state.router.location
});

export default compose(
  translate,
  connect(
    mapStateToProps,
    { push }
  )
)(SavedFilterButton);
